import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { updateFacility } from "./api";
import Grid from "@material-ui/core/Grid";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import TextFieldWithExternalLabel from "../../../components/Forms/FieldTypes/TextFieldWithExternalLabel";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem 0",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "2rem 1rem",
  },
}));

export default function DefineGrid(props: any) {
  const { handleSnackbar, setModalState, setState, state = {} } = props;
  const { grid = { x: 0, y: 0 } } = state;
  const classes = useStyles();
  const [componentState, setComponentState] = useState({
    x: grid.x,
    y: grid.y,
  });

  return (
    <Grid className={classes.container} container>
      {/* xAxis */}
      <Grid item xs={12} md={5}>
        <TextFieldWithExternalLabel
          label="Length of X-Axis(m)"
          onChange={(event: any) => {
            setComponentState((prevState: any) => ({
              ...prevState,
              x: event.target.value,
            }));
          }}
          type="number"
          value={componentState.x}
        />
      </Grid>

      {/* yAxis */}
      <Grid item xs={12} md={5}>
        <TextFieldWithExternalLabel
          label="Length of Y-Axis(m)"
          onChange={(event: any) => {
            setComponentState((prevState: any) => ({
              ...prevState,
              y: event.target.value,
            }));
          }}
          type="number"
          value={componentState.y}
        />
      </Grid>

      {/* Buttons */}
      <Grid className={classes.buttonContainer} item xs={12}>
        <Grid item xs={4}>
          <MaterialUiButton
            color="cancel"
            fullWidth
            label="CANCEL"
            onClick={() => {
              setModalState({ modalShow: false });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <MaterialUiButton
            color="primary"
            fullWidth
            label="SUBMIT"
            onClick={() => {
              updateFacility(props, { grid: { ...componentState } }).then(
                (res: any = {}) => {
                  if (res.success) {
                    setState((prevState: any) => ({
                      ...prevState,
                      grid: { ...componentState },
                    }));
                    setModalState({ modalShow:true, title:'Configure Zones' });
                    handleSnackbar(
                      <>
                        "Facility Map Grid Succesfully Defined!",
                      </>,
                      false
                    );
                  }
                }
              );
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
