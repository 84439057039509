import { Slider, Grid, Tooltip } from "@material-ui/core";

function valuetext(value: number) {
  return `${value}`;
}
export default function LimitSlider(props: any) {
  const { filters, setFilters } = props;
  return (
    <Grid item xs={12}>
      <label>
        {" "}
        Limit{" "}
        <Tooltip title="Set limit of how many asset markers are visible at once on the map. Set more specific filters to narrow results.">
          <i className="fas fa-info-circle fa-sm"></i>
        </Tooltip>{" "}
      </label>
      <Slider
        defaultValue={4}
        getAriaValueText={valuetext}
        aria-labelledby="limit-slider"
        valueLabelDisplay="auto"
        step={100}
        marks
        min={100}
        max={1000}
        value={filters.limit}
        onChange={(e, newValue) => {
          setFilters((f: any) => {
            return { ...f, limit: newValue };
          });
        }}
      />
    </Grid>
  );
}
