import React from "react";
import Select from "react-select";
import { Typography } from "@material-ui/core";
import { naturalSort } from "../../utils/naturalSort";

export default function ItemLevelDataElementSelect(props) {
  const {
    itemLevelDataElements,
    readOnly,
    setState,
    selectedItemLevelDataElements,
  } = props;
  const itemLevelDataElementOptions = () => {
    let options = [];
    itemLevelDataElements.forEach((element) => {
      const { dataType, id, label } = element;
      options.push({ dataType, id, label, value: id });
    });
    return options;
  };

  return (
    <>
      <Typography variant="h6">Item Level Data Elements</Typography>
      <Select
        closeMenuOnSelect={false}
        defaultValue={selectedItemLevelDataElements}
        isMulti
        isDisabled={readOnly}
        onChange={(value) => {
          setState((prevState) => ({
            ...prevState,
            selectedItemLevelDataElements: value,
          }));
        }}
        options={itemLevelDataElementOptions().sort((a, b) =>
          naturalSort(a.label, b.label)
        )}
      />
    </>
  );
}
