import UncontrolledTable from "../../../../components/Tables/UncontrolledTable/UncontrolledTable";
import ActionButtons from "../../../../components/Buttons/ActionButtons";

export default function ConfigureZoneTable(props: any) {
  const { setState, state, setDialog } = props;
  const tableColumns = [
    {
      Header: "EchoLX Identifier",
      accessor: "node",
      id: "node",
      Cell: (data: any) => (
        <div
          onClick={() => {
            setState((prevState: any) => ({
              ...prevState,
              selectedNode: data.row.original,
            }));

            setDialog((prevState: any) => ({
              dialogShow: true,
              dialogTitle: `${data.row.original.node} Information`,
              dialogType: "View",
            }));
          }}
          className="pointer"
        >
          {data.row.original.node || ""}
        </div>
      ),
    },
    // {
    //   Header: "Radio IDs",
    //   accessor: "children",
    //   id: "children",
    //   Cell: (data: any) => {
    //     const childArray = Object.keys(data.row.original.children).sort();
    //     const childLength = childArray.length;
    //     return (
    //       <div
    //         className="pointer"
    //         onClick={() => {
    //           setState((prevState: any) => ({
    //             ...prevState,
    //             selectedNode: data.row.original,
    //           }));

    //           setDialog((prevState: any) => ({
    //             dialogShow: true,
    //             dialogTitle: `${data.row.original.node} Information`,
    //             dialogType: "View",
    //           }));
    //         }}
    //       >
    //         {childArray
    //           ? childArray.map(
    //               (item: any, index: number) =>
    //                 ` ${item}
    //                 ${
    //                   childLength === 0 || index + 1 === childLength ? "" : ","
    //                 }`
    //             )
    //           : ""}
    //       </div>
    //     );
    //   },
    // },
    {
      Header: "Actions",
      disableSortBy: true,
      accessor: "actions",
      id: "actions",
      Cell: (data: any) => {
        const buttonIcons: any = [];
        ["Edit EchoLX", "Delete Node"].forEach((element) => {
          switch (element) {
            case "Edit EchoLX":
              buttonIcons.push({
                icon: "fas fa-edit",
                title: "Edit Radios",
                action: () => {
                  setState((prevState: any) => ({
                    ...prevState,
                    selectedNode: data.row.original,
                  }));

                  setDialog((prevState: any) => ({
                    dialogShow: true,
                    dialogTitle: `Edit ${data.row.original.node}`,
                    dialogType: "Edit",
                  }));
                },
              });
              break;
            default:
              break;
          }
        });
        return <ActionButtons content={buttonIcons} />;
      },
    },
  ];
  return (
    <>
      <UncontrolledTable
        stickyHeader
        totalCountLabel="EchoLX"
        data={state.nodes || []}
        defaultSort={[{ id: "node" }]}
        columns={tableColumns}
        // Look into this
        isSelect={false}
        // Look into this
        selectAllPageRows={() => {}}
        onSelect={() => {}}
      />
    </>
  );
}
