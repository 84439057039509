import escapeCharacters from "../../utils/escapedCharacters";
import moment from "moment";

export const searchHistories = async (
  props: any,
  assetId: string,
  filters: any,
  isExport: boolean
) => {
  const { apiUrl, token, organizationId } = props;
  let {
    binLocation,
    endDate,
    events,
    hideScans = true,
    limit,
    locals,
    locations,
    pca,
    proximity,
    sorted,
    start,
    startDate,
    users,
    zones,
  } = filters;

  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  startDate =
    moment(startDate).isValid() && startDate
      ? moment(startDate).startOf("day").utc().format()
      : null;
  endDate =
    moment(endDate).isValid() && endDate
      ? moment(endDate).endOf("day").utc().format()
      : null;

  let eventString = "";
  if (events && events.length) {
    let eventSet = events.map((e: any) => {
      return `event:${escapeCharacters(e.value)}`;
    });
    eventString = eventSet.join(" OR ");
  }

  let parsedSorted: any =
    sorted && sorted.length
      ? (() => {
          let obj: any = {
            id: "",
            order: sorted[0].desc ? "desc" : "asc",
          };
          switch (sorted[0].id) {
            case "timeOfLog":
              obj.id = "time_of_log";
              break;
            case "assetTag":
              obj.id = "asset_tag";
              break;
            case "event":
              obj.id = "event";
              break;
            case "state":
              obj.id = "state";
              break;
            case "user":
              obj.id = "last_name";
              break;
            case "facility":
              obj.id = "facility";
              break;
            case "binLocation":
              obj.id = "bin_location";
              break;
            case "latLong":
              if (sorted[0].desc) {
                obj.id = "latitude desc, longitude asc";
              } else {
                obj.id = "latitude asc, longitude desc";
              }
              obj.latLong = true;
              break;
            default:
              obj.id = "time_of_log";
              break;
          }
          return obj;
        })()
      : "";

  let sortedString =
    parsedSorted && parsedSorted.id
      ? (() => {
          if (parsedSorted.latLong) {
            return `${parsedSorted.id}, `;
          } else {
            return `${parsedSorted.id} ${parsedSorted.order}, `;
          }
        })()
      : "";

  const usersString = users?.length
    ? users
        .map((user: any) => {
          return `app_user_id:${user.value}`;
        })
        .join(" OR ")
    : "";

  let localsString = "";
  if (locals && locals.length) {
    let localsSet = locals.map((e: any) => {
      return `state:${escapeCharacters(e.value)}`;
    });
    localsString = localsSet.join(" OR ");
  }

  let locationsString = "";
  if (locations && locations.length) {
    let locationsSet = locations.map((e: any) => {
      return `facility_id:${e.value.facilityId}`;
    });
    locationsString = locationsSet.join(" OR ");
  }

  const binLocationsString = binLocation?.length
    ? binLocation
        .map((bin: any) => {
          return `bin_location:${escapeCharacters(bin.value)}`;
        })
        .join(" OR ")
    : "";

  let zonesString = "";
  if (zones && zones.length) {
    let zonesSet = zones.map((zone: any) => {
      const { internalZoneType = null, value = "" } = zone;

      // if {zoneId}.internalZoneId === 'processing' then we know the zone is a
      // pZone. If that value is === 'target' then we know the zone is a tZone.

      if (internalZoneType === "processing") {
        return `{!tuple}zone.p_zone_id:${value}`;
      } else if (internalZoneType === "target") {
        return `{!tuple}zone.t_zone_id:${value}`;
      } else {
        return `{!tuple}zone.zone_id:${value}`;
      }
    });
    zonesString = zonesSet.join(" OR ");
    zonesString = "(" + zonesString + ")";
  }

  const payload = {
    solrQuery: {
      q: `organization_id:${organizationId} AND asset_id:${assetId}`,
      fq: [
        binLocationsString ? `(${binLocationsString})` : ``,
        `${eventString ? `${eventString}` : ``}`,
        usersString,
        `${locationsString ? `${locationsString}` : ``}`,
        `${localsString ? `${localsString}` : ``}`,
        `${zonesString ? `${zonesString}` : ``}`,
        pca ? "flagged:true" : "",
        startDate || endDate
          ? `time_of_log:[${startDate || `*`} TO ${endDate || `*`}]`
          : ``,
        proximity ? "" : "-event_type:device_proximity",
        hideScans ? "-event_type:mobile_scan" : "",
      ],
      sort: `${sortedString}event desc`,
      start: isExport ? 0 : start ? start : 0,
    },
    limit: isExport ? 25000 : limit ? limit : 25000,
  };
  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};

export const getAssetProps = async (props: any, assetId: string) => {
  const { apiUrl, token, organizationId } = props;

  const payload = {
    solrQuery: {
      q: "*:*",
      fq: [
        organizationId ? `current_owner_id:${organizationId}` : ``,
        assetId ? `asset_id:${assetId}` : ``,
      ],
      start: 0,
    },
    limit: 1,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};

export const submitEditAsset = async (
  props: any,
  assetData: any,
  editAsset: any,
  selectedClassifications: any
) => {
  const reducedProps: any = Object.keys(editAsset).reduce((x, y) => {
    return {
      ...x,
      [y]: editAsset[y].value,
    };
  }, {});

  const body: any = {
    classificationSet: [],
    category: reducedProps.category || assetData.category,
    propertiesMap: {
      ...assetData.propertiesMap,
      ...reducedProps,
    },
  };

  // Handle Classifications
  Object.keys(selectedClassifications).forEach((item: any) => {
    body.classificationSet.push(selectedClassifications[item].value);
  });

  const results = await fetch(`${props.apiUrl}assets/${assetData.assetId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update data, please contact system administrator.",
      };
    });
  return results;
};

export const submitUpdateEvent = async (props: any, updateEvent: any) => {
  const { apiUrl, token, facilities, userId, assetData } = props;
  const {
    location,
    event,
    note,
    zone = null,
    binLocation = null,
  } = updateEvent;
  let latitude = null;
  let longitude = null;
  if (
    location &&
    facilities[location.value] &&
    facilities[location.value].location
  ) {
    latitude = facilities[location.value]?.location?.latitude || null;
    longitude = facilities[location.value]?.location?.longitude || null;
  }
  const payload = {
    latitude,
    longitude,
    facility: facilities[location?.value] || null,
    action: event,
    appUserId: userId,
    binLocation: binLocation || null,
    zone:
      zone?.zoneId || binLocation
        ? {
            zoneId: zone?.zoneId || null,
            binLocation: binLocation || null,
          }
        : null,
    propertiesMap: {
      note: note,
    },
  };
  const results = await fetch(`${apiUrl}assets/${assetData.assetId}/action`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update event, please contact system administrator.",
      };
    });

  return results;
};

export const saveFilterSettings = async (
  props: any,
  assetSnapshotSettings: any
) => {
  const { apiUrl, token, userId } = props;

  // Removing filters that do not need to be saved on the users asset snapshot settings
  [
    "binLocation",
    "endDate",
    "events",
    "locals",
    "locations",
    "start",
    "startDate",
    "users",
    "zones",
  ].forEach((item: any) => {
    if (assetSnapshotSettings && assetSnapshotSettings[item]) {
      delete assetSnapshotSettings[item];
    }
  });

  const payload = {
    propertiesMap: {
      assetSnapshotSettings: assetSnapshotSettings,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => {
      return results;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const getDevices = async (props: any) => {
  const { apiUrl, token, organizationId } = props;
  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: ["{!tuple}device.status:*"],
      sort: "",
      start: 0,
    },
    limit: 1000,
  };

  const devices = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch Device, please contact system administrator.",
      };
    });

  return devices;
};

export const saveUserFilterSettings = async (
  props: any,
  defaultColumnOrder: any
) => {
  const { apiUrl, userId, token } = props;
  const payload = {
    propertiesMap: {
      assetSnapshotSettings: {
        defaultColumnOrder,
      },
    },
  };

  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return true;
    })
    .catch((err) => {
      return false;
    });

  return results;
};

export const getAppUser = async (props: any) => {
  const results = await fetch(`${props.apiUrl}appUsers/${props.userId}`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }
      return res.appUser;
    });

  return results;
};

export const searchBins = async (
  props: any,
  input: any,
  limit: number | string = 50,
  start: number | string = 0
) => {
  const { apiUrl, token, organizationId } = props;
  let escapedInput = escapeCharacters(input);
  const payload = {
    solrQuery: {
      q: `organization_id:${organizationId}`,
      fq: [`bin_location:${escapedInput || ""}*`],
      sort: `time_of_log asc`,
      start: start,
    },
    limit: limit,
  };
  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};

export const getZones = async (props: any) => {
  const { apiUrl, token } = props;

  const zones = await fetch(`${apiUrl}zones`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      const allZones = response.zones;
      const availableZones: any = [];

      allZones.forEach((zone: any) => {
        availableZones.push({ label: zone.name, value: zone.zoneId });
      });
      return availableZones;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch Device, please contact system administrator.",
      };
    });

  return zones;
};

export const getTelemetry = async (
  props: any,
  deviceId: string,
  filters: any
) => {
  const { apiUrl, organizationId } = props;
  const csmToken = process.env.REACT_APP_CSM_TOKEN;
  let data = {};
  let {
    devices = [],
    start = 0,
    limit,
    startDate,
    endDate,
    types,
    radios,
    nodes,
  } = filters;
  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  startDate =
    moment(startDate).isValid() && startDate
      ? moment(startDate).startOf("day").utc().format()
      : null;

  endDate =
    moment(endDate).isValid() && endDate
      ? moment(endDate).endOf("day").utc().format()
      : null;

  let deviceString = "";
  if (devices && devices.length) {
    let deviceSet = devices.map((e: any) => {
      return `asset_id:${escapeCharacters(e.value)}`;
    });
    deviceString = deviceSet.join(" OR ");
  }

  let typesString = "";
  if (types && types.length) {
    let typesSet = types.map((e: any) => {
      return `report_type:${escapeCharacters(e.value)}`;
    });
    typesString = typesSet.join(" OR ");
  }

  let radiosString = "";
  if (radios && radios.length) {
    let radiosSet = radios.map((e: any) => {
      return `radio:${escapeCharacters(e.value)}`;
    });
    radiosString = radiosSet.join(" OR ");
  }

  let nodesString = "";
  if (nodes && nodes.length) {
    let nodesSet = nodes.map((e: any) => {
      return `node:${escapeCharacters(e.value)}`;
    });
    nodesString = nodesSet.join(" OR ");
  }

  const body = {
    csmToken,
    organizationId,
    solrQuery: {
      fq: [
        `asset_id:${deviceId}`,
        `${deviceString ? `${deviceString}` : ``}`,
        `${typesString ? `${typesString}` : ``}`,
        `${radiosString ? `${radiosString}` : ``}`,
        `${nodesString ? `${nodesString}` : ``}`,
        startDate || endDate
          ? `time_of_log:[${startDate || `*`} TO ${endDate || `*`}]`
          : ``,
      ],
      sort: `time_of_report desc`,
      start,
    },
    limit: limit ? limit : 25000,
  };

  const lastReportBody = {
    csmToken,
    solrQuery: {
      fq: [`asset_id:${deviceId}`, "report_type:R"],
      sort: `time_of_report desc`,
    },
    limit: 1,
  };

  const lastMovementBody = {
    csmToken,
    solrQuery: {
      fq: [`asset_id:${deviceId}`, "report_type:M"],
      sort: `time_of_report desc`,
    },
    limit: 1,
  };

  const telemetryData = await fetch(`${apiUrl}telemetry/csm/console/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch Device, please contact system administrator.",
      };
    });

  const lastReport = await fetch(`${apiUrl}telemetry/csm/console/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lastReportBody),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch Device, please contact system administrator.",
      };
    });

  const lastMovement = await fetch(`${apiUrl}telemetry/csm/console/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lastMovementBody),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch Device, please contact system administrator.",
      };
    });

  data = { ...telemetryData, lastReport, lastMovement };

  return data;
};

export const retrieveRadioIds = async (props: any) => {
  const { apiUrl } = props;
  const csmToken = process.env.REACT_APP_CSM_TOKEN;
  const body = {
    csmToken,
  };
  const results = await fetch(`${apiUrl}radios/csm/console`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      const nodes: any = {};
      const radios: any = {};
      response.radios.forEach((item: any) => {
        nodes[item.node] = item.node;
        radios[item.radio] = item.radio;
      });
      return { nodes, radios };
    });

  return results;
};

export const retrieveAssetChildren = async (props: any, assetId: string) => {
  const { organizationId, apiUrl, token, zones, facilities } = props;
  const payload = {
    solrQuery: {
      q: `organization_id:${organizationId}`,
      fq: [`parent_id:${assetId}`],
      sort: `time_of_log desc`,
      start: 0,
    },
    limit: 1000,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return (
        res.assets?.map((asset: any) => {
          const zone =
            asset.zone && asset.zone.zoneId && zones[asset.zone.zoneId]
              ? zones[asset.zone.zoneId].name
              : null;
          const binLocation =
            asset.zone && asset.zone.binLocation
              ? asset.zone.binLocation
              : null;
          return {
            assetTag: asset.tag,
            ancestorList: asset.ancestorList || [],
            ancestors: asset.ancestors || [],
            binLocation,
            facility: facilities[asset.facilityId]
              ? facilities[asset.facilityId].name
              : "",
            id: asset.assetId,
            lastEvent: asset.lastEvent,
            parentId: asset.parentId,
            quantityNeeded: asset.quantity
              ? asset.quantity.quantityNeeded
              : null,
            quantityPicked: asset.quantity
              ? asset.quantity.quantityPicked
              : null,
            zone,
          };
        }) || []
      );
    });
  return results;
};

export const resetDisplayColumnOptions = async (props: any, filters: any) => {
  const { apiUrl, token, userId } = props;
  const payload = {
    propertiesMap: {
      assetStatusTableSettings: {
        displayColumnOptions: null,
        defaultColumnOrder: [],
      },
    },
  };

  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};
