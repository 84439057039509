import { useState } from "react";
import moment from "moment-timezone";
import chroma from "chroma-js";
import { startCase } from "lodash";

import {
  Chip,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import CloseIcon from "@material-ui/icons/Close";

import Filters from "./Filters";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    "&:hover": {
      backgroundColor: "rgb(46, 105, 255, 0.1)",
    },
  },
}));
export default function EventActivityMenu(props: any) {
  const classes = useStyles();

  const {
    data,
    setData,
    selected,
    filters,
    setFilters,
    setSelected,
    timeZone,
  } = props;
  const [view, setView] = useState({ filterView: false });

  const handleSelect = (e: any, event: string) => {
    setSelected((s: any) => {
      return {
        ...s,
        selected: {
          ...s.selected,
          [event]: e.target.checked,
        },
      };
    });
  };

  return (
    <Grid container alignContent="flex-start">
      <Grid item xs={6}>
        <h5>
          Event Activity Tracker{" "}
          {!view.filterView ? (
            <>
              <Typography
                variant="subtitle2"
                variantMapping={{ subtitle2: "p" }}
              >
                {moment(filters.startDate).format("MM/DD/YYYY")} -{" "}
                {moment(filters.endDate).format("MM/DD/YYYY")} <br />
                {startCase(filters.dateSelection)} view
              </Typography>
            </>
          ) : null}
        </h5>
      </Grid>
      <Grid item xs={6}>
        <Typography style={{ textAlign: "right" }}>
          {" "}
          <IconButton
            id={"eventActivityFilterViewButton"}
            className={classes.iconButton}
            onClick={() => {
              setView((v) => {
                return {
                  filterView: !v.filterView,
                };
              });
            }}
          >
            {!view.filterView ? <SettingsIcon /> : <CloseIcon />}
          </IconButton>{" "}
        </Typography>
      </Grid>

      {view.filterView ? (
        <Grid item xs={12}>
          <Filters
            setView={setView}
            filters={filters}
            setFilters={setFilters}
            setData={setData}
            timeZone={timeZone}
            {...props}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} id={"eventActivityMenuView"}>
            <List style={{ height: "100%" }} id={"eventActivityEventList"}>
              {Object.keys(data).map((event, idx) => {
                const color = data[event].color
                  ? chroma(data[event].color)
                  : chroma("grey");
                return (
                  <ListItem
                    key={`${event} - ${idx}`}
                    dense
                    button
                    onClick={(e) => handleSelect(e, event)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        color="primary"
                        edge="start"
                        checked={selected[event] || false}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": `${event} select` }}
                      />
                    </ListItemIcon>
                    <ListItemText id={event} primary={`${event}`} />
                    <ListItemSecondaryAction>
                      <Chip
                        size="small"
                        style={{ backgroundColor: color.css() }}
                        label={
                          <span
                            style={{
                              color:
                                chroma.contrast(color, "black") < 7
                                  ? "white"
                                  : "black",
                            }}
                          >
                            {data[event].count || 0}
                          </span>
                        }
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </>
      )}
    </Grid>
  );
}
