import { useRef, useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { isEqual, isEmpty, startCase } from "lodash";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { naturalSort } from "../../../utils/naturalSort";
import { submitEditAsset, submitUpdateEvent } from "../api";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton"
import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";
import Paper from "@material-ui/core/Paper";
import SimpleSelect from "../../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";

const currentTime = (timeZone: any) => {
  return moment()
    .tz(timeZone && timeZone.value ? timeZone.value : moment.tz.guess())
    .format("YYYY-MM-DD");
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  formArea: {
    color: theme.palette.text.secondary,
    maxHeight: "43vh",
    overflow: "auto",
    padding: theme.spacing(2),
    textAlign: "left",
  },
  submit: {
    marginTop: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "90%",
  },
  input: {
    width: "90%",
  },
}));

function EditAsset(props: any) {
  const {
    apiUrl,
    assetData,
    classifications,
    facilities,
    organization,
    setModal,
    setState,
    state,
    token,
    userId,
  } = props;

  // remember, the four data types are Alphanumeric, Number, Date, Currency
  const classes = useStyles();

  const editRef = useRef<any>();
  const classificationsRef = useRef<any>({});

  const requiredFields = useRef<any>();

  const [isValid, setIsValid] = useState<any>(true);
  const { assetCategoriesList = [] } = organization;

  const [editAsset, setEditAsset] = useState<any>(() => {
    let obj: any = {};

    // populate all custom asset fields in edit window
    if (organization?.customAssetFieldsList?.length > 0) {
      organization.customAssetFieldsList.forEach((field: any) => {
        Object.assign(obj, {
          ...obj,
          [field.label]: {
            label: startCase(field.label),
            value: "",
          },
        });
      });
    }

    // populate all fields found in asset's propertiesMap, will overwrite custom asset field values above
    if (assetData?.propertiesMap) {
      Object.keys(assetData.propertiesMap)
        .filter(
          (key: any) =>
            key !== "classificationSet" &&
            key !== "deltaTimeHrs" &&
            key !== "distance" &&
            key !== "formData" &&
            key !== "formId" &&
            key !== "kmPerHour" &&
            key !== "note" &&
            key !== "status"
        )
        .sort((a, b) => {
          return a.localeCompare(b);
        })
        .map((k, idx) => {
          return {
            label: k,
            value: assetData.propertiesMap[k],
          };
        })
        .forEach((el) => {
          Object.assign(obj, {
            ...obj,
            [el.label]: {
              label: startCase(el.label),
              value: el.value,
            },
          });
        });
    }

    editRef.current = obj;
    return obj;
  });

  // set required fields depending on selected category, or
  if (assetCategoriesList && assetData.propertiesMap?.category) {
    const filteredCategory = assetCategoriesList.filter(
      (el: any) => el.id === editAsset.category.value
    )[0];

    requiredFields.current = filteredCategory
      ? filteredCategory.requiredFields?.reduce((x: any, y: any) => {
          return {
            ...x,
            [y.id]: y,
          };
        }, {})
      : {};
  }

  const [selectedClassifications, setSelectedClassifications] = useState<any>(
    () => {
      const { classificationItemMap = {} } = assetData;
      // Setting up classifications
      const selectedClassifications: any = {};

      Object.keys(classificationItemMap).forEach((item: any) => {
        const classificationItem = classificationItemMap[item];

        selectedClassifications[classificationItem.parentId] = {
          label: classificationItem.name,
          parentName: classificationItem.parentName,
          value: classificationItem.classificationId,
        };
      });

      classificationsRef.current = selectedClassifications;

      return selectedClassifications;
    }
  );

  // changesMade and changesMade side effect are used to enable or disable the "submit" button for editing the asset.
  // if no changes were made, i.e., the ref and the current state are equal, then the submit button will not be enabled.
  const [changesMade, setChangesMade] = useState(false);

  useEffect(() => {
    if (
      !isEqual(editAsset, editRef.current) ||
      !isEqual(selectedClassifications, classificationsRef.current)
    ) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
  }, [editAsset, selectedClassifications]);

  return (
    <Box className={classes.root}>
      <h4 style={{ textAlign: "left" }}>Edit Asset</h4>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.formArea} elevation={0}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (isValid) {
                  const diffNotes = Object.keys(editAsset)
                    .map((key) => {
                      if (
                        editAsset[key].value &&
                        editAsset[key].value !== assetData.propertiesMap[key]
                      ) {
                        return {
                          key: key,
                          current: assetData.propertiesMap[key]
                            ? assetData.propertiesMap[key]
                            : "",
                          incoming: editAsset[key].value,
                        };
                      }
                      return null;
                    })
                    .filter((el) => el !== null)
                    .map((el) => {
                      return `* ${el?.key ? startCase(el?.key) : "DNP"}: ${
                        el?.current
                      } --> ${el?.incoming}`;
                    })
                    .join("\n\r");

                  // Creating diff notes for classifications
                  const classificationDiffNotes = Object.keys(
                    selectedClassifications
                  )
                    .map((item: any) => {
                      const oldRef: any = { ...classificationsRef };
                      const childClassification = selectedClassifications[item];
                      if (
                        oldRef.current[item] &&
                        oldRef.current[item].value !==
                          selectedClassifications[item].value
                      ) {
                        return {
                          key: childClassification.parentName,
                          current: oldRef.current[item].label,
                          incoming: childClassification.label,
                        };
                      } else if (!oldRef.current[item]) {
                        return {
                          key: "Added New Classification",
                          current: childClassification.parentName,
                          incoming: childClassification.label,
                        };
                      }
                      return null;
                    })
                    .filter((el) => el !== null)
                    .map((el) => {
                      return `* ${el?.key ? startCase(el?.key) : "DNP"}: ${
                        el?.current
                      } --> ${el?.incoming}`;
                    })
                    .join("\n\r");

                  submitEditAsset(
                    { apiUrl, token },
                    assetData,
                    editAsset,
                    selectedClassifications
                  ).then((editResponse) => {
                    if (editResponse.error) {
                      setModal({
                        modalShow: true,
                        text: `Uh-oh! Something went wrong while editing  asset... ${editResponse.error}`,
                        isError: true,
                      });
                    } else {
                      const editTimeStamp = currentTime(state?.filters?.tz);

                      submitUpdateEvent(
                        { apiUrl, token, facilities, userId, assetData },
                        {
                          location: null,
                          event: "Asset Edited",
                          note: `Asset Properties Edited (${editTimeStamp}):\n\r ${diffNotes} \n\r ${classificationDiffNotes}`,
                        }
                      ).then((updateResponse) => {
                        if (updateResponse.error) {
                          setModal({
                            modalShow: true,
                            text: `Your asset edit went through, but an event was not created... ${updateResponse.error}`,
                            isError: true,
                          });
                          setState({
                            ...state,
                            assetData: {
                              ...state.assetData,
                              ...editResponse.asset,
                            },
                          });
                        } else {
                          setModal({
                            modalShow: true,
                            text: `Asset update success!`,
                            isError: false,
                          });

                          setState({
                            ...state,
                            assetData: {
                              ...state.assetData,
                              ...editResponse.asset,
                              lastEvent: updateResponse.assetHistory.event,
                              propertiesMap: {
                                ...(state.assetData.propertiesMap || {}),
                                ...editResponse.asset.propertiesMap,
                                note: updateResponse.assetHistory.propertiesMap
                                  .note,
                              },
                            },
                            histories: {
                              ...state.histories,
                              assetHistories: [
                                updateResponse.assetHistory,
                              ].concat(state.histories.assetHistories),
                              count: state.histories.count + 1,
                            },
                          });
                        }
                      });

                      editRef.current = editAsset;
                      classificationsRef.current = selectedClassifications;
                      setChangesMade(false);
                    }
                  });
                } else {
                  setModal({
                    modalShow: true,
                    text: `Please make sure all fields are correctly formatted.`,
                    isError: true,
                  });
                }
              }}
            >
              <Grid container spacing={3}>
                {editAsset && !isEmpty(editAsset) ? (
                  Object.keys(editAsset)
                    .filter((k) => k !== "changesMade")
                    .sort((a, b) => {
                      if (a === "description") {
                        return -2;
                      }
                      if (a === "category") {
                        return -1;
                      }
                      return editAsset[a].label.localeCompare(
                        editAsset[b].label
                      );
                    })
                    .map((key: string, idx: number) => {
                      // binLocation was previously stored in propertiesMap.
                      if (key === "binLocation") {
                        return null;
                      } else {
                        return (
                          <Grid item xs={12} sm={4} key={`${idx} - ${key}`}>
                            {requiredFields?.current &&
                            requiredFields.current[key]?.dataType &&
                            requiredFields.current[key]?.dataType === "Date" ? (
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                  required={
                                    requiredFields?.current[key]?.required ||
                                    false
                                  }
                                  className={classes.input}
                                  allowKeyboardControl={false}
                                  disableToolbar
                                  format="MM/DD/yyyy"
                                  id="date-picker-inline"
                                  inputVariant="standard"
                                  error={!isValid}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  label={editAsset[key].label}
                                  onChange={(event) => {
                                    const value = event
                                      ? event.format("MM/DD/yyyy")
                                      : "";
                                    setIsValid(event?.isValid());

                                    setEditAsset({
                                      ...editAsset,
                                      [key]: {
                                        ...editAsset[key],
                                        value: value,
                                      },
                                    });
                                  }}
                                  value={
                                    editAsset[key].value === ""
                                      ? null
                                      : editAsset[key].value
                                  }
                                  variant="inline"
                                  margin="normal"
                                />
                              </MuiPickersUtilsProvider>
                            ) : requiredFields?.current &&
                              requiredFields.current[key] &&
                              requiredFields?.current[key]?.dataType &&
                              requiredFields.current[key].dataType ===
                                "Currency" ? (
                              <CurrencyTextField
                                required={
                                  requiredFields.current[key]?.required || false
                                }
                                currencySymbol=""
                                label={editAsset[key].label}
                                onChange={(e: any, value: any) => {
                                  setEditAsset({
                                    ...editAsset,
                                    [key]: {
                                      ...editAsset[key],
                                      value: value,
                                    },
                                  });
                                }}
                                outputFormat="string"
                                value={editAsset[key].value}
                                variant={"standard"}
                                margin="normal"
                                className={classes.input}
                              />
                            ) : key === "category" ? (
                              <FormControl className={classes.formControl}>
                                <SimpleSelect
                                  required={
                                    requiredFields.current[key]?.required ||
                                    false
                                  }
                                  label={editAsset[key].label}
                                  value={editAsset[key].value}
                                  variant={"standard"}
                                  margin="dense"
                                  classes={classes}
                                  onChange={(e: any) => {
                                    setEditAsset({
                                      ...editAsset,
                                      [key]: {
                                        ...editAsset[key],
                                        value: e.target.value,
                                      },
                                    });
                                  }}
                                  options={assetCategoriesList
                                    .map((cat: any) => {
                                      return {
                                        id: cat.id,
                                        value: cat.id,
                                        label: cat.label,
                                      };
                                    })
                                    .sort((a: any, b: any) =>
                                      naturalSort(a.label, b.label)
                                    )}
                                />
                              </FormControl>
                            ) : (
                              <SimpleTextField
                                autoComplete={key}
                                autoFocus={idx === 0}
                                className={classes.input}
                                id={key}
                                key={`${idx} - ${key}`}
                                label={editAsset[key].label}
                                margin="normal"
                                multiline={true}
                                name={key}
                                onChange={(e: any) => {
                                  setEditAsset({
                                    ...editAsset,
                                    [key]: {
                                      ...editAsset[key],
                                      value: e.target.value,
                                    },
                                  });
                                }}
                                required={
                                  (requiredFields.current &&
                                    requiredFields.current[key]?.required) ||
                                  false
                                }
                                type={
                                  requiredFields.current &&
                                  requiredFields.current[key]?.dataType ===
                                    "number"
                                    ? "number"
                                    : "text"
                                }
                                value={editAsset[key].value}
                                variant={"standard"}
                              />
                            )}
                          </Grid>
                        );
                      }
                    })
                ) : (
                  <p>Asset has no custom fields to edit!</p>
                )}

                {/* Classifications */}

                {Object.keys(classifications.active).map(
                  (parentClassification: any, index: any) => {
                    const parentObject: any =
                      classifications.active[parentClassification];
                    return (
                      <Grid
                        item
                        key={`${parentClassification}-${index}`}
                        sm={4}
                        xs={12}
                      >
                        <FormControl className={classes.formControl}>
                          <SimpleSelect
                            classes={classes}
                            label={parentClassification}
                            margin="dense"
                            onChange={(e: any) => {
                              setSelectedClassifications((prevState: any) => {
                                const newChildLabel = Object.keys(
                                  classifications.active[parentClassification]
                                    .children
                                ).find((element) => {
                                  return (
                                    classifications.active[parentClassification]
                                      .children[element].classificationId ===
                                    e.target.value
                                  );
                                });

                                return {
                                  ...prevState,
                                  [parentObject.classificationId]: {
                                    label: newChildLabel,
                                    parentName: parentObject.name,
                                    value: e.target.value,
                                  },
                                };
                              });
                            }}
                            options={Object.keys(parentObject.children)
                              .map((item: any) => {
                                const childItem = parentObject.children[item];
                                return {
                                  name: childItem.name,
                                  value: childItem.classificationId,
                                  label: childItem.name,
                                };
                              })
                              .sort((a: any, b: any) =>
                                naturalSort(a.label, b.label)
                              )}
                            value={
                              selectedClassifications[
                                parentObject.classificationId
                              ]
                                ? selectedClassifications[
                                    parentObject.classificationId
                                  ].value
                                : null
                            }
                            variant={"standard"}
                          />
                        </FormControl>
                      </Grid>
                    );
                  }
                )}
              </Grid>
              {editAsset && !isEmpty(editAsset) ? (
                <MaterialUiButton
                  className={classes.submit}
                  disabled={!changesMade || !isValid}
                  label="Submit"
                  type="submit"
                  variant="contained"
                />
              ) : null}
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EditAsset;
