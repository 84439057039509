import { useEffect, useState } from "react";

import Facilities from "./Facilities/Facilities";
import Zones from "./Zones/Zones";
import FacilitiesMap from "./FacilitiesOverviewMap/FacilitiesMap";
import ConfigureZone from "./ConfigureZone/ConfigureZone";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export default function Index(props: any) {
  const { userRoles = {}, apiUrl, token, history, location } = props;

  const [view, setView] = useState<any>({
    facility: {},
    viewConfigureZone: false,
    viewFacilitiesMap: false,
    viewZone: false,
    zoneInfo: {},
  });

  const [alertModal, setAlertModal] = useState({
    alertModalContent: "",
    alertModalOpen: false,
    isError: false,
  });

  const { alertModalContent, alertModalOpen, isError } = alertModal;

  const permissions = {
    createLocation: userRoles["Create Locations"],
    deleteLocation: userRoles["Delete Locations"],
    editLocation: userRoles["Edit Locations"],
  };

  const closeModal = () => {
    setAlertModal({
      ...alertModal,
      alertModalOpen: false,
    });
  };

  function handleAlertModal(content: any, isOpen: boolean, isError: boolean) {
    setAlertModal({
      alertModalContent: content,
      alertModalOpen: isOpen,
      isError: isError,
    });
    setTimeout(() => {
      setAlertModal((prevState) => ({ ...prevState, alertModalOpen: false }));
    }, 3000);
  }

  useEffect(() => {
    // This is looking for a redirect from the FacilityOverview component. 
    // We could just say "If location.state is present, then setView:{...location.state} or something."
    if (location.state && location.state?.viewZones) {
      setView((prevState: any) => ({
        ...prevState,
        viewZones: location.state.viewZones,
        facility: location.state.facility,
      }));
    }
  },[history, location.state]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertModalOpen}
        onClose={closeModal}
      >
        <MuiAlert onClose={closeModal} severity={isError ? "error" : "success"}>
          {alertModalContent}
        </MuiAlert>
      </Snackbar>
      {!view.viewZones && !view.viewFacilitiesMap && !view.viewConfigureZone ? (
        <Facilities
          history={history}
          location={location}
          apiUrl={apiUrl}
          token={token}
          setView={setView}
          view={view}
          permissions={permissions}
        />
      ) : view.viewConfigureZone ? (
        <ConfigureZone
          apiUrl={apiUrl}
          token={token}
          handleAlertModal={handleAlertModal}
          setView={setView}
          view={view}
        />
      ) : view.viewZones ? (
        <Zones
          apiUrl={apiUrl}
          token={token}
          setView={setView}
          view={view}
          permissions={permissions}
        />
      ) : (
        <FacilitiesMap apiUrl={apiUrl} token={token} setView={setView} />
      )}
    </>
  );
}
