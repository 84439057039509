export const checkFacility = async (props) => {
  const { apiUrl, facilityId, token } = props;
  const results = await fetch(`${apiUrl}facilities/${facilityId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((results) => results.json())
    .then(async (results = {}) => {

      // We need to grab all of the fixedNodes, so the first step is to grab the zones associated
      // to the facility 
      const fixedNodes = await retrieveFixedNodesByFacilityId({ ...props, facilityId });
      
      // We than find the fixedNodes
      // const zoneFixedNodes = await Promise.all(
      //   fixedNodes.map(async (zone) => {
      //     const response = await retrieveFixedNodesByFacilityId({
      //       ...props,
      //       zoneId: zone.zoneId,
      //     });
      //     return await response;
      //   })
      // );

      return {...results, fixedNodes};
    });

  return results;
};


export const deleteFacilityImage = async (props) => {
  const { apiUrl, facilityId, facilityImageId, token } = props;

  const results = await fetch(`${apiUrl}facilities/${facilityId}/images/${facilityImageId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    }
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
  return results;
}

export const getRadios = async (props, zoneIds) => {
  const { apiUrl, token } = props;
  const zones = []
  const body = {
    solrQuery: {
      fq: [`tl_zone_id:${zoneIds}`],
    },
  };

  zoneIds.forEach((id)=>zones.push(`tl_zone_id:${id}`))

  const results = await fetch(`${apiUrl}radios/search/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
  return results;
};

export const getZoneHashMap = async (props, zoneIds) => {
  const { apiUrl, token } = props;
  const zoneHashMap = {}
 

  const results = await fetch(`${apiUrl}zones/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    }
  })
    .then((response) => response.json())
    .then((response = {}) => {
      if(response.success) {

        // Create a hashmap of the organizations zones
        response.zones.forEach((zone)=>{
          zoneHashMap[zone.zoneId] = zone
        })
      }
      return zoneHashMap;
    })
    .catch((err) => console.log(err));
  return results;
};



export const retrieveFixedNodesByFacilityId = async (props) => {
  const { apiUrl, facilityId, token } = props;
  const results = await fetch(`${apiUrl}fixedNodes/facilities/${facilityId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((results) => results.json())
    .then((results = {}) => {
      return results?.success ? results.fixedNodes : [];
    });

  return results;
};

export const updateFacility = async (props, content) => {
  const { apiUrl, facilityId, token } = props;
  const body = {
    propertiesMap: {
      facilityImage: { ...content },
    },
  };

  const results = await fetch(`${apiUrl}facilities/${facilityId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((results) => results.json())
    .then((results) => results);

  return results;
};

export const uploadPDFImage = async (props) => {
  const { apiUrl, facilityId, file, handleSnackbar, setState, token } = props;
  const { name } = file[0];
  const body = new FormData();
  const reader = new FileReader();
  let image = null;

  reader.onload = async () => {
    image = reader.result;

    // Appending the file to the form
    body.append("pngimagedata", new Blob([image]));
    body.append("jsondata", JSON.stringify({ name }));

    await fetch(`${apiUrl}facilities/${facilityId}/images`, {
      method: "POST",
      headers: {
        "auth-token": token,
      },
      body,
    })
      .then((res) => res.json())
      .then((res = {}) => {
        const { facility = {} } = res;
        const { images = [] } = facility;
        const image = images[0] || {};
        const { imageId, url = "" } = image;
        handleSnackbar(
          <>
            "Facility Map Successfully Uploaded! <br /> Drop Marker on Map to
            Define (0,0) Point.",
          </>,
          false
        );
        setState((prevState) => ({
          ...prevState,
          facilityImage: url,
          facilityImageId: imageId,
          loading: false,
        }));
        return res;
      });
  };

  reader.readAsArrayBuffer(file[0]);
};


