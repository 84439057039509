import { makeStyles } from "@material-ui/core";
import Map from "./map";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    height: "75vh",
    display: "flex",
    justifyContent: "center",
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "rgb(46, 105, 255, 0.1)",
    },
  },
}));
export default function FacilitiesMap(props: any) {
  const { setView, apiUrl, token } = props;
  const classes = useStyles();

  return (
    <div className="container-fluid mt-3">
      <div className="row mt-4 mb-3">
        <div className="col-12 col-md-6 text-md-left text-center">
          <h3>Facilities Map</h3>
        </div>
        <div className="col-12 col-md-6 text-md-right text-center">
          <span
            className="exportIcon"
            style={{ cursor: "pointer" }}
            onClick={() => setView({ viewFacilityMap: false })}
          >
            <i className="fas fa-arrow-left mr-2"> </i>
            Back to Facilities
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className={classes.mapContainer} id={"facilitiesMap"}>
            <Map
              token={token}
              apiUrl={apiUrl}
              height={"100%"}
              width={"90%"}
              defaultZoomLevel={4}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
