import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  pcaWarning: {
    color: "red",
    "&:hover": {
      color: "red !important",
    },
  },
}));

export default function PCAWarningIcon(props) {
  const { onClick, data } = props;
  const classes = useStyles();
  return (
    <div
      onClick={() => onClick(data.row.original)}
      className="default-cursor text-dark"
    >
      <i className={`fas fa-exclamation-circle ${classes.pcaWarning}`} />
    </div>
  );
}
