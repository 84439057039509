export const validatePasswordStrength = (password: string) => {
  // The password is at least 8 characters long (?=.{8,}).

  // The password has at least one uppercase letter (?=.*[A-Z]).

  // The password has at least one lowercase letter (?=.*[a-z]).

  // The password has at least one digit (?=.*[0-9]).

  // The password has at least one special character ([^A-Za-z0-9]).

  const strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );
  return strongPassword.test(password);
};
