import { Component } from "react";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  ${"" /* justify-content: center; */}
`;

const Button = styled.button`
  margin-right: 10px;
  background: none;
  border: none;
`;

export default class ActionButtons extends Component {
  render() {
    const { content } = this.props;
    const iconContent = content.map((element, index) => {
      const { icon, action, title, disabled = false } = element;

      return (
        <Button key={index} onClick={action} disabled={disabled}>
          <i
            title={title}
            className={icon}
            style={disabled ? { pointerEvents: "none" } : {}}
          />
        </Button>
      );
    });
    return <ButtonContainer>{iconContent}</ButtonContainer>;
  }
}
