import { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { naturalSort } from "../../../utils/naturalSort";
import { submitUpdateEvent } from "../api";
import Grid from "@material-ui/core/Grid";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import Paper from "@material-ui/core/Paper";
import ReactSelect from "../../../components/Forms/FieldTypes/ReactSelect";
import ReactSelectCheckboxes from "../../../components/Selects/ReactSelectCheckboxes";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";

const useStyles = makeStyles((theme) => ({
  formArea: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
    textAlign: "left",
  },
  input: {
    width: "100%",
  },
  root: {
    flexGrow: 1,
    height: "100%",
  },
  select: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  submit: {
    marginTop: theme.spacing(4),
  },
}));
function Update(props: any) {
  const {
    apiUrl,
    assetsArray,
    eventTypes,
    facilities,
    setLoading,
    setModal,
    setState,
    state,
    token,
    userId,
  } = props;
  const classes = useStyles();
  const itemOptions = [...assetsArray]
    .map((ass: any) => {
      return {
        label: ass.tag,
        value: ass.assetId,
      };
    })
    .sort((a: any, b: any) => naturalSort(a.label, b.label));
  const [updateEvent, setUpdateEvent] = useState<any>({
    assets: [],
    event: "",
    location: "",
    note: "",
    zone: "",
  });

  // We add the Select all option to the top of the options
  itemOptions.unshift({ value: "<SELECT_ALL>", label: "ALL" });

  const [zones, setZones] = useState<any>([]);

  const setFacilityZones = async (facilityId: string | null) => {
    if (!facilityId) {
      setZones([]);
    } else {
      const results = await fetch(`${apiUrl}facilities/${facilityId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }).then((res) => res.json());

      if (results.success && results.facility?.zones?.length > 0) {
        setZones(results.facility.zones);
      } else {
        setZones([]);
      }
    }
  };

  const [isValid, setIsValid] = useState<any>(false);

  const handleItemSelectChange = (event: any, actionMeta: any) => {
    const { option = {}, action = "" } = actionMeta;
    const { value = "" } = option;

    if (
      updateEvent.assets?.some((item: any) => item.value === "<SELECT_ALL>") &&
      action === "deselect-option"
    ) {
      // If select all is enabled and user then de-selects an item, we remove select all from the selected items
      setUpdateEvent((prevState: any) => ({
        ...prevState,
        assets: [
          ...event?.filter((item: any) => item.value !== "<SELECT_ALL>"),
        ],
      }));
    } else if (
      value === "<SELECT_ALL>" &&
      updateEvent.assets?.some((item: any) => item.value === "<SELECT_ALL>")
    ) {
      // If user has select all enabled, and then clicks it again. We deselect all assets
      setUpdateEvent((prevState: any) => ({ ...prevState, assets: [] }));
    } else if (value === "<SELECT_ALL>") {
      // If user clicks select all, we add all assets
      setUpdateEvent((prevState: any) => ({
        ...prevState,
        assets: itemOptions,
      }));
    } else {
      setUpdateEvent((prevState: any) => ({ ...prevState, assets: event }));
    }
  };

  useEffect(() => {
    if (
      updateEvent?.location?.value &&
      updateEvent?.event?.value &&
      updateEvent?.assets?.length > 0
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [updateEvent]);

  return (
    <Box className={classes.root}>
      <h4 style={{ textAlign: "left" }}>Update Event Log</h4>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.formArea} elevation={0}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                // Set the loading spinner on the BatchHistory page
                setLoading({ loading: true });

                submitUpdateEvent(
                  { apiUrl, token, facilities, userId },
                  updateEvent
                ).then((res) => {
                  // Turn off the loading spinner
                  setLoading({ loading: false });

                  if (res.error) {
                    setModal({
                      modalShow: true,
                      text: `Uh-oh! Something went wrong while updating event log... ${res.error}`,
                      isError: true,
                    });
                  } else {
                    setModal({
                      modalShow: true,
                      text: `Event update success!`,
                      isError: false,
                    });

                    setTimeout(() => {
                      setModal({
                        modalShow: false,
                        text: "",
                        isError: false,
                      });
                    }, 1500);

                    setState({
                      ...state,
                      histories: {
                        ...state.histories,
                        assetHistories: res.assetHistories.concat(
                          state?.histories?.assetHistories || []
                        ),
                        count: state.histories.count + 1,
                      },
                    });
                  }
                });
              }}
            >
              <Grid container spacing={3}>
                {/* Item Select */}
                <Grid item xs={12} sm={4}>
                  <span>Item</span>
                  <ReactSelectCheckboxes
                    isClearable
                    isSearchable
                    label={"Item"}
                    onChange={handleItemSelectChange}
                    options={itemOptions}
                    required
                    value={updateEvent.assets}
                  />
                </Grid>

                {/* Facility Select */}
                <Grid item sm={4} xs={12}>
                  <span>Facility Name</span>

                  <ReactSelect
                    isClearable
                    isSearchable
                    label={"Facility"}
                    onChange={(e: any) => {
                      setUpdateEvent({
                        ...updateEvent,
                        location: e,
                        zone: null,
                      });
                      setFacilityZones(e?.value || null);
                    }}
                    options={Object.keys(facilities)
                      .map((key: any) => {
                        return {
                          value: key,
                          label: facilities[key].name,
                        };
                      })
                      .sort((a: any, b: any) => naturalSort(a.label, b.label))}
                    required
                    value={updateEvent.location}
                  />
                </Grid>

                {/* Zones */}
                {zones?.length > 0 ? (
                  <Grid item xs={12} sm={4}>
                    <span>Zone</span>
                    <ReactSelect
                      classes={classes}
                      label={"Zone"}
                      margin="dense"
                      onChange={(e: any) => {
                        setUpdateEvent({
                          ...updateEvent,
                          zone: e.value,
                        });
                      }}
                      options={zones
                        .map((zone: any) => {
                          return {
                            value: zone,
                            label: zone.name,
                          };
                        })
                        .sort((a: any, b: any) =>
                          naturalSort(a.label, b.label)
                        )}
                      value={updateEvent.zone?.value}
                      variant={"standard"}
                    />
                  </Grid>
                ) : null}

                {/* Event Select */}
                <Grid item xs={12} sm={4}>
                  <span>Event</span>
                  <ReactSelect
                    isClearable
                    isSearchable
                    label={"Item"}
                    onChange={(e: any) => {
                      setUpdateEvent({
                        ...updateEvent,
                        event: e,
                      });
                    }}
                    options={eventTypes
                      .filter(
                        (e: any) =>
                          e !== "origin" &&
                          e !== "scan" &&
                          e !== "leaveGeoFence" &&
                          e !== "enterGeoFence"
                      )
                      .map((event: any) => {
                        return {
                          value: event,
                          label: event,
                        };
                      })
                      .sort((a: any, b: any) => naturalSort(a.label, b.label))}
                    required
                    value={updateEvent.event}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {/* Bin Location */}
                <Grid item xs={12} sm={6}>
                  <SimpleTextField
                    className={classes.input}
                    label="Bin Location"
                    margin="normal"
                    multiline={true}
                    name="binLocation"
                    onChange={(e: any) => {
                      setUpdateEvent({
                        ...updateEvent,
                        binLocation: e.target.value,
                      });
                    }}
                    rows="1"
                    type="text"
                    value={updateEvent.binLocation}
                    variant="standard"
                  />
                </Grid>

                {/* Notes */}
                <Grid item xs={12} sm={6}>
                  <SimpleTextField
                    autoComplete="note"
                    className={classes.input}
                    label="Notes"
                    margin="normal"
                    multiline={true}
                    name="note"
                    onChange={(e: any) => {
                      setUpdateEvent({
                        ...updateEvent,
                        note: e.target.value,
                      });
                    }}
                    rows="1"
                    type="text"
                    value={updateEvent.note}
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <MaterialUiButton
                className={classes.submit}
                disabled={!isValid}
                label="Submit"
                type="submit"
                variant="contained"
              />
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Update;
