import escapeCharacters from "../../../../utils/escapedCharacters";
import request from "superagent";

export const getAssetByTags = async (props, tag, start = 0) => {
  const { apiUrl, token, organizationId } = props;

  const assetTag = tag ? `tag:${escapeCharacters(tag)}*` : "";
  const body = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: assetTag,
      sort: `tag asc`,
      start: start,
    },
    limit: 300,
  };
  const results = await request
    .post(`${apiUrl}assets/search`)
    .set("auth-token", token)
    .send(body)
    .then((response) => {
      return response.body;
    });

  return results;
};

// export const getAssetByCategories = async (props, category) => {
//   const { apiUrl, token, organizationId } = props;
//   const body = {
//     solrQuery: {
//       q: `current_owner_id:${organizationId}`,
//       fq: `category:${escapeCharacters(category)}*`,
//     },
//     limit: 300
//   };
//   const results = await request
//     .post(`${apiUrl}assets/search`)
//     .set("auth-token", token)
//     .send(body)
//     .then((response) => {
//       return response.body;
//     });
//   return results;
// };
