import { useState, useEffect } from "react";
import {
  RetrieveBatch,
  RetrieveBatchAssets,
  RetrieveProduct,
  RetrieveQrBackgrounds,
} from "../../utils/apiCalls";
import { updateSessionImage } from "./api";
import BuildCQR from "./BuildCQR";
import DeliverTracking from "./DeliverTracking";
import Grid from "@material-ui/core/Grid";
import Loading from "../../components/Loading/Loading";
import NotificationModal from "../../components/Modals/NotificationModal";

import "./styles.css";
export default function GenerateCQR(props) {
  const [state, setState] = useState({
    aboveData: { label: null, value: null },
    aboveImages: [],
    assetId: "",
    assetType: "",
    batch: {},
    batchAsset: false,
    belowData: { label: null, value: null },
    belowImages: [],
    confirmationModalShow: false,
    confirmationText: "",
    cqrLayoutName: { value: "", error: false },
    cqrTemplates: [],
    dataElementAbove: { label: null, value: null },
    dataElementBelow: { label: null, value: null },
    eccLevel: "L",
    font: "",
    fontSize: "10",
    format: "png",
    isLoading: false,
    magnification: 5,
    modalShow: true,
    page: "",
    product: "",
    productName: "",
    selectCQRLayout: { label: "Select", value: null },
    selectedAssets: {},
    selectedAssetType: "Pallet",
    seperatedAssetTypes: {},
    templateSize: { label: "Medium", value: 350 },
    textAlignment: [],
    textFormats: "",
    version: 10,
  });
  const [qrBackgroundSession, setQrBackgroundSession] = useState({
    qrBackgroundSessionId: "",
    image: "",
  });

  useEffect(() => {
    // batchAsset lets us know whether its coming from the batches page
    const {
      assetType,
      batchId,
      productId,
      batchAsset = false,
      selectedAssets = {},
    } = props.location.state;

    if (batchId) {
      RetrieveBatchAssets({ ...props, batchId }, (assets) => {
        const seperatedAssetTypes = {};
        const assetId = assets[0] !== undefined ? assets[0].assetId : {};

        assets.forEach((element) => {
          if (seperatedAssetTypes[element.assetType] === undefined) {
            seperatedAssetTypes[element.assetType] = [element];
          } else {
            seperatedAssetTypes[element.assetType].push(element);
          }
        });

        setState((prevState) => ({
          ...prevState,
          assetId,
          seperatedAssetTypes,
          asset: assets[0] || {},
        }));
      });
      RetrieveBatch({ ...props, batchId }, (batch) => {
        setState((prevState) => ({ ...prevState, batch }));
      });
    }

    if (productId) {
      RetrieveProduct({ ...props, productId }, (product) => {
        setState((prevState) => ({
          ...prevState,
          product,
          productName: product ? product.name : "No Associated Product",
        }));
      });
    }

    if (!batchAsset) {
      const assetId = Object.keys(selectedAssets)[0];
      setState((prevState) => ({ ...prevState, assetId }));
    }

    RetrieveQrBackgrounds({ ...props }, (templates) => {
      setState((prevState) => ({
        ...prevState,
        assetType,
        batchAsset,
        cqrTemplates: templates,
        selectedAssets,
      }));
    });
  }, [props]);

  function changePage() {
    setState((prevState) => ({ ...prevState, page: "deliver" }));
  }

  function handleConfirmationModal(confirmationText) {
    setState((prevState) => ({
      ...prevState,
      confirmationModalShow: true,
      confirmationText,
    }));
    setTimeout(function () {
      setState((prevState) => ({
        ...prevState,
        confirmationModalShow: false,
        deleteErorr: false,
      }));
    }, 750);
  }

  // This useEffect is used to update the qrBackgroundSessionImage
  useEffect(() => {
    if (state.assetId && state.assetId.length > 0) {
      updateSessionImage(
        { ...state, ...props },
        qrBackgroundSession.qrBackgroundSessionId
      ).then((response) => {
        setQrBackgroundSession((prevState) => ({
          ...prevState,
          image: response,
        }));
      });
    }
  }, [props, qrBackgroundSession.qrBackgroundSessionId, state]);

  const {
    batch = {},
    confirmationModalShow,
    confirmationText,
    isLoading,
    page,
    seperatedAssetTypes = [],
  } = state;
  const { name = "", tag = "" } = batch;
  const { assetType, batchId } = props.location.state;
  const selectedAssetCount = seperatedAssetTypes[assetType]
    ? seperatedAssetTypes[assetType].length
    : [];
  let modalClose = () =>
    setState((prevState) => ({ ...prevState, confirmationModalShow: false }));
  let toggleLoading = (value) => {
    setState((prevState) => ({ ...prevState, isLoading: value }));
  };

  return (
    <Grid container>
      {isLoading ? <Loading /> : null}
      <NotificationModal
        confirmationModalShow={confirmationModalShow}
        modalClose={modalClose}
        confirmationText={confirmationText}
      />
      <Grid item xs={12} className="text-center mt-4  mb-4">
        <span className="h1">Generate CQRs</span>
      </Grid>
      {page === "deliver" ? (
        <DeliverTracking
          {...state}
          {...props}
          batchId={batchId}
          batchName={tag.length > 0 ? tag : name}
          consoleRole={props.appUserType}
          state={state}
          goBack={() => {
            setState((prevState) => ({ ...prevState, page: "build" }));
          }}
          isLoading={toggleLoading}
          onSuccess={handleConfirmationModal}
          selectedAssetCount={selectedAssetCount}
          qrBackgroundSession={qrBackgroundSession}
        />
      ) : (
        <BuildCQR
          {...props}
          {...state}
          assetCount={selectedAssetCount}
          batchName={tag.length > 0 ? tag : name}
          changePage={changePage}
          consoleRole={props.appUserType}
          qrBackgroundSession={qrBackgroundSession}
          retrieveQrBackgrounds={() => {
            RetrieveQrBackgrounds({ ...props }, (templates) => {
              setState((prevState) => ({
                ...prevState,
                cqrTemplates: templates,
              }));
            });
          }}
          setQrBackgroundSession={setQrBackgroundSession}
          setState={setState}
          state={state}
        />
      )}
    </Grid>
  );
}
