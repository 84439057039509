import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class NotificationModal extends Component {
  render() {
    const {
      color = "bg-success",
      confirmationModalShow,
      confirmationText,
      icon = "fas fa-check-circle mr-2",
      modalClose,
    } = this.props;
    return (
      <Modal
        show={confirmationModalShow}
        onHide={modalClose}
        style={{ zIndex: 10000 }}
      >
        <Modal.Body
          className={`${color} text-white font-weight-bold text-center rounded shadow`}
        >
          <i className={`${icon} mr-2`}></i>
          {confirmationText}
        </Modal.Body>
      </Modal>
    );
  }
}
