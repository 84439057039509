const fullscreenControlStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  padding: "10px",
} as React.CSSProperties;

const navStyle = {
  position: "absolute",
  top: 36,
  left: 0,
  padding: "10px",
} as React.CSSProperties;

const scaleControlStyle = {
  position: "absolute",
  bottom: 36,
  left: 0,
  padding: "10px",
} as React.CSSProperties;

const darkModeStyle = {
  position: "absolute",
  top: 10,
  right: 10,
  padding: "10px",
} as React.CSSProperties;

export { fullscreenControlStyle, navStyle, scaleControlStyle, darkModeStyle };
