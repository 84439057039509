import { isEmpty } from "lodash";
import escapeCharacters from "../../utils/escapedCharacters";
import moment from "moment";
import request from "superagent";

const getBatchData = async (props: any, batchId: string) => {
  const { apiUrl, token } = props;

  const results = await fetch(`${apiUrl}batches/${batchId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

const getZonesData = async (props: any) => {
  const { apiUrl, token } = props;

  const results = await fetch(`${apiUrl}zones`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

const getBatchAssets = async (props: any, batchId: string) => {
  const { apiUrl, token } = props;
  const results = await fetch(`${apiUrl}batches/${batchId}/assets`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

const getProductData = async (props: any, batch: any) => {
  const { apiUrl, token } = props;
  const { topologiesList = [], topologyMap = {} } = batch;

  const results = await fetch(`${apiUrl}products`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then(async (p: any) => {
      const productsFromResponse = p.products;
      const productMap: any = {};
      productsFromResponse.forEach((element: any) => {
        const { productId } = element;
        productMap[productId] = element;
      });

      // get product ID for all products in a batch...
      const productArr: Array<any> = [];
      const recurseTopologies = (top: any) => {
        const obj: any = {};
        if (Array.isArray(top) && top.length) {
          top.map((el) => {
            if (
              el.productId &&
              productMap[el.productId] &&
              !obj[el.productId]
            ) {
              obj[el.productId] = true;
              productArr.push(productMap[el.productId]);
            }
            if (el.child) {
              recurseTopologies(el.child);
            }
            return null;
          });
        } else {
          if (
            top.productId &&
            productMap[top.productId] &&
            !obj[top.productId]
          ) {
            obj[top.productId] = true;
            productArr.push(productMap[top.productId]);
          }
          if (top.child) {
            recurseTopologies(top.child);
          }
        }
      };

      if (
        (!topologiesList || topologiesList.length === 0) &&
        !isEmpty(topologyMap)
      ) {
        recurseTopologies(topologyMap);
      } else {
        recurseTopologies(topologiesList);
      }

      const returnValue = Array.from(
        new Set(productArr.map((r: any) => r.productId))
      ).map((id) => {
        return {
          ...productArr.find((res: any) => res.productId === id),
        };
      });

      return returnValue;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });

  return results;
};

const searchBins = async (
  props: any,
  input: any,
  limit: number | string = 50,
  start: number | string = 0
) => {
  const { apiUrl, token, organizationId } = props;
  let escapedInput = escapeCharacters(input);
  const payload = {
    solrQuery: {
      q: `organization_id:${organizationId}`,
      fq: [`bin_location:${escapedInput || ""}*`],
      sort: `time_of_log asc`,
      start: start,
    },
    limit: limit,
  };
  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};

const searchHistories = async (
  props: any,
  batchId: string,
  filters: any,
  isExport: boolean
) => {
  const { apiUrl, token, organizationId } = props;
  let {
    assets,
    binLocations,
    endDate,
    events,
    limit,
    locals,
    locations,
    pca,
    sorted,
    start,
    startDate,
    type,
    users,
    zones,
  } = filters;
  let eventString = "";
  if (events && events.length) {
    let eventSet = events.map((e: any) => {
      return `event:${escapeCharacters(e.value)}`;
    });
    eventString = eventSet.join(" OR ");
  }

  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  startDate =
    moment(startDate).isValid() && startDate
      ? moment(startDate).startOf("day").utc().format()
      : null;
  endDate =
    moment(endDate).isValid() && endDate
      ? moment(endDate).endOf("day").utc().format()
      : null;

  let parsedSorted: any =
    sorted && sorted.length
      ? (() => {
          let obj: any = {
            id: "",
            order: sorted[0].desc ? "desc" : "asc",
          };
          switch (sorted[0].id) {
            case "timeOfLog":
              obj.id = "time_of_log";
              break;
            case "assetTag":
              obj.id = "asset_tag";
              break;
            case "event":
              obj.id = "event";
              break;
            case "user":
              obj.id = "last_name";
              break;
            case "state":
              obj.id = "state";
              break;
            case "city":
              obj.id = "city";
              break;
            case "cityState":
              if (sorted[0].desc) {
                obj.id = "city desc, state desc";
              } else {
                obj.id = "city asc, state asc";
              }
              obj.cityState = true;
              break;
            case "binLocation":
              obj.id = "bin_location";
              break;
            case "facility":
              obj.id = "facility";
              break;
            case "latLong":
              if (sorted[0].desc) {
                obj.id = "latitude desc, longitude asc";
              } else {
                obj.id = "latitude asc, longitude desc";
              }
              obj.latLong = true;
              break;
            default:
              obj.id = "time_of_log";
              break;
          }
          return obj;
        })()
      : "";

  let sortedString =
    parsedSorted && parsedSorted.id
      ? (() => {
          if (parsedSorted.latLong || parsedSorted.cityState) {
            return `${parsedSorted.id}, `;
          } else {
            return `${parsedSorted.id} ${parsedSorted.order}, `;
          }
        })()
      : "";

  const usersString = users?.length
    ? users
        .map((user: any) => {
          return `app_user_id:${user.value}`;
        })
        .join(" OR ")
    : "";

  let locationsString = "";
  if (locations && locations.length) {
    let locationsSet = locations.map((e: any) => {
      return `facility_id:${e.value.facilityId}`;
    });
    locationsString = locationsSet.join(" OR ");
  }

  let typeString = "";
  if (type && type.length) {
    let typeSet = type.map((e: any) => {
      return `asset_type:${escapeCharacters(e.value)}`;
    });
    typeString = typeSet.join(" OR ");
  }

  let assetIdString = "";
  if (assets && assets.length) {
    let assetIdSet = assets.map((e: any) => {
      return `asset_id:${escapeCharacters(e.value)}`;
    });
    assetIdString = assetIdSet.join(" OR ");
  }

  let localsString = "";
  if (locals && locals.length) {
    let localsSet = locals.map((e: any) => {
      return `state:${escapeCharacters(e.value)}`;
    });
    localsString = localsSet.join(" OR ");
  }

  const binLocationsString = binLocations?.length
    ? binLocations
        .map((bin: any) => {
          return `bin_location:${escapeCharacters(bin.value)}`;
        })
        .join(" OR ")
    : "";

  let zonesString = "";
  if (zones && zones.length) {
    let zonesSet = zones.map((zone: any) => {
      const { internalZoneType = null, value = "" } = zone;

      // if {zoneId}.internalZoneId === 'processing' then we know the zone is a
      // pZone. If that value is === 'target' then we know the zone is a tZone.

      if (internalZoneType === "processing") {
        return `{!tuple}zone.p_zone_id:${value}`;
      } else if (internalZoneType === "target") {
        return `{!tuple}zone.t_zone_id:${value}`;
      } else {
        return `{!tuple}zone.zone_id:${value}`;
      }
    });
    zonesString = zonesSet.join(" OR ");
    zonesString = "(" + zonesString + ")";
  }

  const payload = {
    solrQuery: {
      q: `batch_id:${batchId} AND organization_id:${organizationId}`,
      fq: [
        `${assetIdString ? `${assetIdString}` : ``}`,
        binLocationsString ? `(${binLocationsString})` : ``,
        `${typeString ? `${typeString}` : ``}`,
        eventString ? `${eventString}` : ``,
        startDate || endDate
          ? `time_of_log:[${startDate || `*`} TO ${endDate || `*`}]`
          : ``,
        `${localsString ? `${localsString}` : ``}`,
        `${locationsString ? `${locationsString}` : ``}`,
        usersString,
        `${zonesString ? `${zonesString}` : ``}`,
        pca ? "flagged:true" : "",
      ],
      sort: `${sortedString}asset_type desc`,
      start: isExport ? 0 : start ? start : 0,
    },
    limit: isExport ? 25000 : limit ? limit : 25000,
  };

  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};

const submitUpdateEvent = async (props: any, updateEvent: any) => {
  const { apiUrl, token, facilities, userId } = props;
  const {
    assets,
    binLocation = null,
    event,
    location,
    note,
    zone = null,
  } = updateEvent;

  const payload: any = {
    action: event.value,
    appUserId: userId,
    assetIds: [],
    binLocation: binLocation || null,
    facility: facilities[location?.value] || null,
    latitude: null,
    longitude: null,
    propertiesMap: {
      note: note,
    },
    zone:
      zone?.zoneId || binLocation
        ? {
            zoneId: zone?.zoneId || null,
            binLocation: binLocation || null,
          }
        : null,
  };

  // Populating the payload.assetIds
  assets
    // Remove the select all function from the values
    .filter((asset: any) => asset.value !== "<SELECT_ALL>")
    // push the assetIds to the payload.assetsIds and to assetHistories
    .forEach((asset: any) => {
      payload.assetIds.push(asset.value);
    });

  if (
    location &&
    facilities[location.value] &&
    facilities[location.value].location
  ) {
    payload.latitude = facilities[location.value]?.location?.latitude || null;
    payload.longitude = facilities[location.value]?.location?.longitude || null;
  }
  const results = await fetch(`${apiUrl}assets/assetsAction`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update event, please contact system administrator.",
      };
    });

  return results;
};

const saveFilterSettings = async (
  props: any,
  batchHistoryTableSettings: any
) => {
  const { apiUrl, token, userId } = props;

  // Removing filters that do not need to be saved on the users batch history settings
  [
    "assets",
    "binLocations",
    "endDate",
    "events",
    "locals",
    "locations",
    "pca",
    "start",
    "startDate",
    "type",
    "users",
    "zones",
  ].forEach((item: any) => {
    if (batchHistoryTableSettings[item]) {
      delete batchHistoryTableSettings[item];
    }
  });

  const payload = {
    propertiesMap: {
      batchHistoryTableSettings: batchHistoryTableSettings,
    },
  };

  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });
  return results;
};

async function assignSelectedAssetsToDevice(
  props: any,
  selectedAssetsHash: any
) {
  const { apiUrl, token } = props;
  const assetsThatDidNotAssociateWithADevice: string[] = [];

  Object.keys(selectedAssetsHash).forEach(async (item) => {
    const { deviceId, deviceLabel, label, value } = selectedAssetsHash[item];
    const body = {
      parentId: deviceId,
    };
    await request
      .put(`${apiUrl}assets/${value}`)
      .set("auth-token", token)
      .send(body)
      .then(() => {
        request
          .put(`${apiUrl}assets/${deviceId}`)
          .set("auth-token", token)
          .send({
            device: {
              status: "assigned",
            },
          })
          .then(() => {
            submitUpdateEvent(
              { ...props },
              {
                asset: {
                  label,
                  value,
                },
                event: {
                  label: `Device ${deviceLabel} Associated`,
                  value: `Device ${deviceLabel} Associated`,
                },
              }
            ).then((res) => {
              if (res.error) {
                props.setModal({
                  modalShow: true,
                  text: "There was a problem updating your assets",
                  isError: true,
                });
              }
              props.setState({
                ...props.state,
                histories: {
                  ...props.state.histories,
                  assetHistories: [res.assetHistory].concat(
                    props.state.histories.assetHistories
                  ),
                },
              });
            });
            return;
          });
      })
      .catch(() => {
        assetsThatDidNotAssociateWithADevice.push(item);
      });
  });

  return assetsThatDidNotAssociateWithADevice;
}

const saveUserFilterSettings = async (props: any, defaultColumnOrder: any) => {
  const { apiUrl, userId, token } = props;
  const payload = {
    propertiesMap: {
      batchHistoryTableSettings: {
        defaultColumnOrder,
      },
    },
  };

  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return true;
    })
    .catch((err) => {
      return false;
    });

  return results;
};

const getAppUser = async (props: any) => {
  const results = await fetch(`${props.apiUrl}appUsers/${props.userId}`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }
      return res.appUser;
    });

  return results;
};

export {
  assignSelectedAssetsToDevice,
  getAppUser,
  getBatchAssets,
  getBatchData,
  getProductData,
  getZonesData,
  saveFilterSettings,
  saveUserFilterSettings,
  searchBins,
  searchHistories,
  submitUpdateEvent,
};
