import chroma from "chroma-js";

export const eventSelectStyles = (customColors: boolean) => {
  return {
    menuPortal: (styles: any) => ({
      ...styles,
      zIndex: 99999,
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      const color = data.color && customColors ? chroma(data.color) : "";
      if (!color) {
        return {
          ...styles,
        };
      }
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "black") < 7
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
    multiValue: (styles: any, { data }: any) => {
      const color = data.color && customColors ? chroma(data.color) : "";
      if (!color) {
        return {
          ...styles,
        };
      }
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: "#b71d1a",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#b71d1a",
        color: "white",
      },
    }),
  };
};
