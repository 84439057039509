import request from "superagent"

export const retrieveAssetCategories = async (props:any) => {
  const { apiUrl, token } = props
  const results = await request
  .get(`${apiUrl}assets/groupCount/category`)
  .set("auth-token", token)
  .then((response:any)=>{
    return response.body
  })

  return results
}