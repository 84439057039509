export function quickLinksHelper(event: any) {
  switch (event) {
    case "Add Asset":
      return {
        pathname: "/",
        state: {
          modalOpen: true,
        },
      };
    case "Add User":
      return {
        pathname: "/users",
        state: {
          isCreate: true,
          modal: "Edit or Create User",
          modalShow: true,
          modalTitle: "Add User",
        },
      };
    case "Create Facility":
      return {
        pathname: "/facilities",
        state: {
          modal: "Create Facility",
          modalShow: true,
        },
      };
    case "Create Product":
      return {
        pathname: "/products",
        state: {
          isCreate: true,
          modal: "Edit Product",
          modalShow: true,
          modalTitle: "Create Product",
        },
      };
    case "Create Organization":
      return {
        pathname: "/organizations",
        state: {
          isCreate: true,
          modal: "Create Organization",
          modalShow: true,
        },
      };
    case "Import Assets":
      return {
        pathname: "/assetHistory",
        state: {
          modal: "Import Assets",
        },
      };
    case "Import Batches":
      return {
        pathname: "/batches",
        state: {
          modal: "Import Batches",
          modalShow: true,
          modalTitle: "Import Batches",
        },
      };
    case "Import Facilities":
      return {
        pathname: "/facilities",
        state: {
          modal: "Import Facilities",
          modalShow: true,
        },
      };
    case "Import Users":
      return {
        pathname: "/users",
        state: {
          modal: "Import Users",
          modalShow: true,
          modalTitle: "Import Users",
        },
      };
    default:
      break;
  }
}

export function seperateActionsByUserRole(usersPropertiesMap: any) {
  const { propertiesMap = {} } = usersPropertiesMap;
  const { consoleRole = "", consoleSettings = {} } = propertiesMap;
  let { quickLinks = {} } = consoleSettings;

  let newLinks: any = {};

  Object.keys(quickLinks).forEach((item: any) => {
    const { actions = {}, id = "", label = "" } = quickLinks[item];
    let newActions = actions;

    if (consoleRole === "Asset" && consoleRole !== "Asset/Product") {
      delete newActions["Create Product"];
      delete newActions["Import Batches"];
    } else if (consoleRole === "Product" && consoleRole !== "Asset/Product") {
      delete newActions["Add Asset"];
      delete newActions["Import Assets"];
    }

    newLinks[id] = { actions: newActions, id, label };
  });

  return newLinks;
}