import { Form } from "informed";
import { downloadCQR } from "./api";

export default function DeliverTracking(props) {
  const {
    assetType,
    batchAsset,
    batchName,
    goBack,
    isLoading,
    productName,
    qrBackgroundSession,
    selectedAssets,
    selectedAssetCount,
    selectCQRLayout,
    state,
  } = props;
  const { eccLevel, format, magnification, version } = state;
  const assetId = batchAsset
    ? props.assetId
    : selectedAssets[Object.keys(selectedAssets)[0]]
    ? selectedAssets[Object.keys(selectedAssets)[0]].assetId
    : "";
  return (
    // Need to convert over to MaterialUI
    <div className="container">
      <Form
        id="delivery"
        onSubmit={(value) => {
          isLoading(true);
          downloadCQR(value, {
            ...props,
            isLoading: isLoading,
            onSuccess: props.onSuccess,
          });
        }}
      >
        <div className="row py-5">
          <div className="col-md-12">
            <div
              className="border px-3 py-4 h-100 shadow-sm rounded"
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <div className="h3 text-center">Information</div>
              <div className="text-center mb-2">
                Please Review All Information and View the CQR Preview
              </div>
              {batchAsset ? (
                <div className="d-flex justify-content-center">
                  <div className="w-25">
                    <div className="my-2">
                      <span className="font-weight-bold mr-1">Batch ID:</span>
                      {batchName}
                    </div>
                    <div className="my-2">
                      <span className="font-weight-bold mr-1">
                        Product Name:
                      </span>
                      {productName}
                    </div>
                    <div className="my-2">
                      <span className="font-weight-bold mr-1">
                        Unit of Measure:
                      </span>
                      {assetType}
                    </div>
                    <div className="my-2">
                      <span className="font-weight-bold mr-1">Count:</span>
                      {selectedAssetCount}
                    </div>
                  </div>
                  <div className="w-25">
                    <div className="my-2">
                      <span className="font-weight-bold mr-1">ECC Level:</span>
                      Level <span className="text-uppercase">{eccLevel}</span>
                    </div>
                    <div className="my-2">
                      <span className="font-weight-bold mr-1">
                        Image Format:
                      </span>
                      <span className="text-uppercase">{format}</span>
                    </div>
                    <div className="my-2">
                      <span className="font-weight-bold mr-1">QR Version:</span>
                      {version}
                    </div>
                  </div>
                  <div className="w-25">
                    <div className="my-2">
                      <span className="font-weight-bold mr-1">QR Size:</span>
                      {magnification} pixel/cell
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="w-25">
                    <div className="my-2">
                      <span className="font-weight-bold mr-1">Count:</span>
                      {Object.keys(selectedAssets).length}
                    </div>
                    <div className="d-flex my-2">
                      <span className="font-weight-bold mr-1">Asset Tag:</span>
                      <div>
                        {Object.keys(selectedAssets).map((asset, index) => {
                          const { tag, assetTag } = selectedAssets[asset];
                          return (
                            <div key={`${asset}-${index}`}>
                              {tag || assetTag || ""}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="w-25">
                    <div className="my-2">
                      <span className="font-weight-bold mr-1">ECC Level:</span>
                      Level <span className="text-uppercase">{eccLevel}</span>
                    </div>
                    <div className="my-2">
                      <span className="font-weight-bold mr-1">
                        Image Format:
                      </span>
                      <span className="text-uppercase">{format}</span>
                    </div>
                    <div className="my-2">
                      <span className="font-weight-bold mr-1">QR Version:</span>
                      {version}
                    </div>
                  </div>
                  <div className="w-25">
                    <div className="my-2">
                      <span className="font-weight-bold mr-1">QR Size:</span>
                      {magnification} pixel/cell
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 my-3">
            <div
              className="border px-3 py-4 h-100 shadow-sm rounded text-center"
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <div className="h3">CQR Preview</div>
              {qrBackgroundSession.qrBackgroundSessionId.length > 0 ? (
                <img
                  style={{ border: "#E6E7E8 2px solid" }}
                  className="mw-100"
                  src={qrBackgroundSession.image}
                  alt="cqr"
                />
              ) : selectCQRLayout.value ? (
                <img
                  style={{ border: "#E6E7E8 2px solid" }}
                  className="mw-100"
                  src={`${props.apiUrl}assets/${assetId}/qrCode?urlBase=https://qr.locatorx.com&target=lxproductscan&eccLevel=${eccLevel}&magnification=${magnification}&version=${version}&qrBackground=${selectCQRLayout.label}`}
                  alt="cqr"
                />
              ) : (
                <img
                  style={{ border: "#E6E7E8 2px solid" }}
                  className="mw-100"
                  src={`${props.apiUrl}assets/${assetId}/qrCode?urlBase=https://qr.locatorx.com&target=lxproductscan&eccLevel=${eccLevel}&magnification=${magnification}&version=${version}&showTag=true`}
                  alt="cqr"
                />
              )}
            </div>
          </div>
          <div className="col-md-12 my-5">
            <div
              className="border px-3 py-4 h-100 shadow-sm rounded"
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <div className="h3 text-center">Download CQRs</div>
              <div className="h6 text-center">
                You can download a ZIP file of images in the format you selected
                by clicking the Download button
              </div>
              <div className="text-center">
                <button className="btn btn-primary" type="submit">
                  Download
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12 text-center d-flex my-3">
            <button
              className="cqrback border-0"
              onClick={() => {
                goBack();
              }}
            >
              <i className="fas fa-arrow-circle-left fa-2x"></i>
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}
