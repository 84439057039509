import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";

const StyledRadio = withStyles({
  root: {},
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function RadioList(props) {
  const { label, name, options = [], onChange, row = false, value, toolTip } = props;
  return (
    <FormControl component="fieldset">
      {label ? (
        <Typography variant="h6">
          {label}
          {toolTip ? (
            <Tooltip title={toolTip}>
              <i className="fas fa-info-circle"></i>
            </Tooltip>
          ) : null}
        </Typography>
      ) : null}
      <RadioGroup
        aria-label={name}
        name={name}
        onChange={(event) => {
          onChange(event);
        }}
        row={row}
        value={value}
      >
        {options.map((element, index) => {
          const { label, labelPlacement, value } = element;
          return (
            <FormControlLabel
              control={<StyledRadio />}
              key={value}
              label={label}
              labelPlacement={labelPlacement}
              value={value}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
