import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  fullWidth?: boolean;
  headerStyle?: any;
  useBox?: boolean;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, useBox = false, index, value, ...other } = props;
  const item = useBox ? children : <Box p={3}>{children}</Box>;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && item}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    backgroundColor: "#6D6E70",
  },
  noAppBarBackground: {
    backgroundColor: "#FAFAFA",
  },
  indicator: {
    backgroundColor: "#A0C46D",
  },
  noIndicator: {
    backgroundColor: "transparent",
  },
  selected: {
    // border: "2px solid #3D5BA9",
    "&:focus": {
      // outline is same syntax as border, so you could do "2px solid #3D5BA9" etc.
      outline: "none",
    },
  },
}));
//

export default function HorizontalTabs(props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const {
    customControl,
    customValue,
    fullWidth = true,
    headerStyle,
    useBox,
    useIndicator = true,
  } = props;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const headerContent = (
    <Tabs
      className={headerStyle ? headerStyle : ""}
      value={customValue ? customValue : value}
      onChange={customControl ? customControl : handleChange}
      classes={{
        indicator: useIndicator ? classes.indicator : classes.noIndicator,
      }}
    >
      {props.children
        .filter((element: any) => element !== null)
        .map((child: any, idx: number) => (
          <Tab
            {...a11yProps(idx)}
            classes={{ selected: classes.selected }}
            icon={child.props.icon ? child.props.icon : null}
            key={idx}
            label={child.props.label}
            onClick={child.props.onClick}
          />
        ))}
    </Tabs>
  );

  const header = fullWidth ? (
    <AppBar
      position="static"
      className={!props.noAppBarColor ? classes.appBar : ""}
      color={props.noAppBarColor ? "transparent" : undefined}
      elevation={props.noElevation ? 0 : 1}
    >
      {headerContent}
    </AppBar>
  ) : (
    headerContent
  );

  return (
    <div className={classes.root}>
      {header}
      {props.children
        .filter((element: any) => element !== null)
        .map((child: any, idx: number) => (
          <TabPanel
            index={idx}
            key={idx}
            useBox={useBox}
            value={customValue ? customValue : value}
          >
            {child}
          </TabPanel>
        ))}
    </div>
  );
}
