import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: (props: any) => ({
      width: props.sideNavWidth,
      flexShrink: 0,
      // alignItems: "center",
      // justifyContent: "center",
    }),
    drawerPaper: (props: any) => ({
      width: props.sideNavWidth,
      backgroundColor: "#414348",
    }),
    listItem: {
      justifyContent: "center",
      "& *": {
        color: "#fefefe",
      },
      "&:focus-visible": {
        backgroundColor: "#A0C46D",
      },
      "&:hover": {
        "& *": {
          color: "white!important",
        },
        backgroundColor: "#3d5ba9",
      },
    },
    listItemActive: {
      backgroundColor: "#3d5ba9",
      "& *": {
        color: "white",
      },
    },
    nestedListItem: {
      paddingLeft: theme.spacing(4),

      "& *": {
        color: "white",
      },

      "&:hover": {
        backgroundColor: "#A0C46D",

        color: "#414348",
      },
    },
    nestedListItemActive: {
      paddingLeft: theme.spacing(4),
      backgroundColor: "#A0C46D",

      "& *": {
        color: "#414348",
      },
      "&:hover": {
        backgroundColor: "#A0C46D",
        color: "#414348",
      },
    },
    menuIcon: (props: any) => ({
      height: props.appBarHeight,
      justifyContent: "center",
    }),
    listItemIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    listItemText: {
      fontSize: "0.7em",
      textAlign: "center",
    },
    menuPaper: {
      backgroundColor: "#414348",
    },
    menuItem: {
      backgroundColor: "#414348",
      color: "white",
      "&:hover": {
        color: "white",
        backgroundColor: "#3d5ba9",
      },
    },
    menuList: {
      justifyContent: "center",
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
    bottomPush: {
      height: "100px",
    },
  })
);
