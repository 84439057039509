import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { showAlert } from "../../../globalStore/slices/alert/alertSlice";
import { updateFacility } from "../../../globalStore/slices/organization/organizationSlice";

import { Button } from "@material-ui/core";

import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import FacilityArea from "../Facilities/FacilityArea/FacilityArea";

import "mapbox-gl/dist/mapbox-gl.css";
import ReactMapGL, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  // LinearInterpolator,
  Layer,
  Source,
} from "react-map-gl";

import { radiusCircle } from "./utils";
import AreaText from "./area-text";

// import { easeCubic } from "d3-ease";

export default function Map(props: any) {
  const mapToken =
    "pk.eyJ1IjoiZGFuaWVsZG91YW5na2Vzb25lIiwiYSI6ImNqeGYxMTF5YTA5NjQzeW1mMmM4eXIxdWIifQ.jqF1at0nWobKgC3t1OY4kw";

  const { defaultZoomLevel = null, height, width, apiUrl, token } = props;

  const dispatchGlobal = useDispatch();

  const facilitiesSelector = useSelector(
    (state: any) => state.organization.facilities,
    isEqual
  );

  const facilities = useMemo(
    () =>
      Object.keys(facilitiesSelector)
        .map((k) => facilitiesSelector[k])
        .map((facility: any, idx: number) => {
          const validateLat =
            /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
          const validateLong =
            /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
          if (
            facility &&
            (facility.location?.latitude !== 0 ||
              facility.location?.longitude !== 0) &&
            validateLat.test(facility.location?.latitude) &&
            validateLong.test(facility.location?.longitude)
          ) {
            return facility;
          }
          return null;
        })
        .filter((facility: any) => facility !== null) || [],
    [facilitiesSelector]
  );

  const [hotSpot, setHotSpot] = useState<any>(null);

  const [viewport, setViewport] = useState<any>({
    zoom: defaultZoomLevel || 4,
    latitude: facilities[0]?.location?.latitude || 0,
    longitude: facilities[0]?.location?.longitude || 0,
    bearing: 0,
    pitch: 0,
  });

  const [satView, setSatView] = useState<boolean>(false);

  const [editArea, setEditArea] = useState<any>({
    facility: null,
    showModal: false,
  });

  const [fullScreen, setFullScreen] = useState<boolean>(false);

  // useEffect(() => {
  //   if (facilities) {
  //     setViewport({
  //       zoom: defaultZoomLevel || 4,
  // latitude: facilities[0].location.latitude || 0,
  // longitude: facilities[0].location.longitude || 0,
  //       bearing: 0,
  //       pitch: 0,
  //       transitionDuration: 1800,
  //       transitionInterpolator: new LinearInterpolator(),
  //       transitionEasing: easeCubic,
  //     });
  //   }
  // }, [facilities, defaultZoomLevel]);

  const _closePopup = () => {
    setHotSpot(null);
  };

  const renderMarkers = facilities.map((facility: any, idx: number) => {
    return (
      <Marker
        key={`${facility.facilityId} - ${idx}`}
        latitude={facility.location.latitude}
        longitude={facility.location.longitude}
        offsetLeft={-20}
        offsetTop={-10}
      >
        <i
          className="fas fa-map-marker"
          // marker colors are rendered by checking the eventTypes object for the event action color
          style={{
            cursor: "pointer",
            color: satView ? "#FFC854" : "#32355C",
          }}
          onMouseEnter={() => setHotSpot(facility)}
          onClick={() => setHotSpot(facility)}
        ></i>
      </Marker>
    );
  });

  const renderPopups =
    hotSpot !== null ? (
      <Popup
        latitude={parseFloat(hotSpot.location.latitude)}
        longitude={parseFloat(hotSpot.location.longitude)}
        onClose={_closePopup}
        className={"popup"}
        closeOnClick={false}
      >
        {hotSpot.name ? (
          <h5 style={{ marginTop: "5px" }}>{hotSpot.name}</h5>
        ) : (
          ""
        )}
        {hotSpot.facilityType ? <h5 style={{}}>{hotSpot.facilityType}</h5> : ""}{" "}
        {hotSpot.location ? (
          <>
            <span>
              {hotSpot.location.address1 &&
              hotSpot.location.city &&
              hotSpot.location.state
                ? hotSpot.location.address1 +
                  ", " +
                  hotSpot.location.city +
                  ", " +
                  hotSpot.location.state
                : hotSpot.location.city && hotSpot.location.state
                ? hotSpot.location.city + ", " + hotSpot.location.state
                : ""}
            </span>
          </>
        ) : null}
        {hotSpot.radius ? <div>Radius: {hotSpot.radius} KM </div> : ""}
        {hotSpot.propertiesMap?.locationDetails ? (
          <span>Note: {hotSpot.propertiesMap.locationDetails}</span>
        ) : (
          ""
        )}
        {hotSpot.polygonGeoJSON?.coordinates?.length > 0 ? (
          <AreaText polygon={hotSpot.polygonGeoJSON} satView={satView} />
        ) : null}
        <Button
          color="primary"
          variant="contained"
          style={{ marginTop: "5px" }}
          onClick={(e: any) => {
            setEditArea({
              facility: hotSpot,
              showModal: true,
            });
          }}
        >
          Edit Area
        </Button>
      </Popup>
    ) : null;

  const renderRadii = facilities
    .map((facility: any, idx: number) => {
      if (
        facility.polygonGeoJSON?.coordinates &&
        facility.polygonGeoJSON?.coordinates?.length <= 0
      ) {
        return facility;
      } else if (!facility.polygonGeoJSON) {
        return facility;
      }

      return null;
    })
    .filter((facility: any) => facility !== null)
    .map((facility: any, idx: number) => {
      return (
        <Source
          key={`radius for ${facility.facilityId} - ${idx}`}
          type="geojson"
          data={{
            ...radiusCircle(
              facility.location.longitude,
              facility.location.latitude,
              facility.radius,
              {
                // optional geoJSON props, in case we want to do hover states / popups over the radius
                // ...props.selectedLocation,
              }
            ),
          }}
        >
          <Layer
            type="fill"
            paint={{
              "fill-color": satView ? "#3D5BA9" : "#A0C46D",
              "fill-opacity": 0.4,
            }}
          />
        </Source>
      );
    });

  const renderPolygons = facilities
    .map((facility: any, idx: number) => {
      if (facility && facility.polygonGeoJSON?.coordinates?.length > 0) {
        return facility;
      }
      return null;
    })
    .filter((facility: any) => facility !== null)
    .map((facility: any, idx: number) => {
      const { polygonGeoJSON = {} } = facility;

      return (
        <Source
          key={`polygon for ${facility.facilityId} - ${idx}`}
          type="geojson"
          data={polygonGeoJSON}
        >
          <Layer
            type="fill"
            paint={{
              "fill-color": satView ? "#FFC854" : "#587BF5",
              "fill-opacity": 0.4,
            }}
          />
          <Layer
            type="line"
            paint={{
              "line-color": satView ? "#FFC854" : "#587BF5",
              "line-width": 1,
            }}
          />
        </Source>
      );
    });

  const fullscreenControlStyle: any = {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "10px",
  };

  const navStyle: any = {
    position: "absolute",
    top: 36,
    left: 0,
    padding: "10px",
  };

  const scaleControlStyle: any = {
    position: "absolute",
    bottom: 36,
    left: 0,
    padding: "10px",
  };

  const satViewStyle: any = {
    position: "absolute",
    top: 10,
    right: 10,
    padding: "10px",
  };

  return (
    <>
      <ModalDialog
        handleClose={() => setEditArea({ facility: null, showModal: false })}
        open={editArea.showModal}
        title={`Edit Area ${
          editArea.facility?.name ? ` - ${editArea.facility.name}` : ""
        }`}
        content={
          <FacilityArea
            token={token}
            apiUrl={apiUrl}
            facilityId={editArea.facility?.facilityId}
            selectedLocation={editArea.facility}
            notificationModal={(text: string, error: boolean) =>
              dispatchGlobal(
                showAlert({ type: error ? "error" : "success", text: text })
              )
            }
            onHide={() =>
              setEditArea({
                facility: null,
                showModal: false,
              })
            }
            onSuccess={(res: any) => {
              const { facility = null } = res;
              if (facility) {
                dispatchGlobal(updateFacility(facility));
              }
            }}
            setFullScreenModal={(bool: boolean) => setFullScreen(bool)}
          />
        }
        fullScreen={fullScreen}
      />
      <ReactMapGL
        {...viewport}
        attributionControl={false}
        width={width || "100%"}
        height={height || "100%"}
        mapboxApiAccessToken={mapToken}
        mapStyle={
          satView
            ? "mapbox://styles/mapbox/satellite-v9"
            : "mapbox://styles/mapbox/light-v10"
        }
        style={{
          border: "rgba(50, 53, 93, 0.514) solid 2px",
          borderRadius: "4px",
        }}
        onViewportChange={(viewport: any) => setViewport(viewport)}
      >
        {renderMarkers}
        {renderPopups}
        {renderRadii}
        {renderPolygons}
        <div style={fullscreenControlStyle}>
          <FullscreenControl />
        </div>
        <div style={navStyle}>
          <NavigationControl />
        </div>
        <div style={scaleControlStyle}>
          <ScaleControl />
        </div>
        <div style={satViewStyle}>
          <i
            className={
              satView ? "fa fa-road darkmode-icon" : "fa fa-globe darkmode-icon"
            }
            style={satView ? { color: "#FFC854" } : { color: "#32355B" }}
            onClick={() => {
              setSatView(!satView);
            }}
          >
            {}
          </i>
        </div>
      </ReactMapGL>
    </>
  );
}
