import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { formatLatLon } from "../../../../../../utils/lat_lon_utils";

import PolygonMap from "../../../Maps/PolygonMap/PolygonMap";

const useStyles = makeStyles((theme: any) => ({
  root: {},
}));
function Polygon(props: any) {
  const classes = useStyles();

  const { polygonGeoJSON = {} } = props.facility;

  const polygon = {
    features: {
      type: "Feature",
      id: "initFeature",
      geometry: {
        coordinates: polygonGeoJSON?.coordinates || [],
        type: "Polygon",
      },
      properties: {
        renderType: "Polygon",
      },
    },
  };

  const initLat = props.facility?.location?.latitude;
  const initLong = props.facility?.location?.longitude;

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item xs={12}>
        {" "}
        <h3>Polygon</h3>
      </Grid>
      <Grid item xs={12}>
        {initLat &&
        initLong &&
        polygon.features.geometry.coordinates.length > 0 ? (
          <PolygonMap
            singlePolygon
            width="80%"
            height="42vh"
            latitude={initLat}
            longitude={initLong}
            readOnly
            polygon={polygon}
          />
        ) : (
          <p style={{ height: "42vh" }}>No polygon set.</p>
        )}
      </Grid>
      <Grid item xs={12}>
        {polygon?.features?.geometry?.coordinates[0]?.length ? (
          <h5>Points</h5>
        ) : null}
        {polygon?.features?.geometry?.coordinates[0] &&
        polygon?.features?.geometry?.coordinates[0].length
          ? polygon.features.geometry.coordinates[0].map(
              (point: any, idx: number) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ textAlign: "left" }}
                  key={`${point[0]} ${point[1]} ${idx}`}
                >
                  <p>
                    {" "}
                    Point {idx + 1}: <br />[{formatLatLon(point[1], point[0])}]
                  </p>
                </Grid>
              )
            )
          : null}
      </Grid>
    </Grid>
  );
}

export default Polygon;
