import { Zone } from "../interface";

export const retrieveFacility = async (props: any, facilityId: string) => {
  const { apiUrl, token } = props;
  const results = await fetch(`${apiUrl}facilities/${facilityId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return results;
};

export const retrieveAllZones = async (props: any) => {
  const { apiUrl, token } = props;
  const results = await fetch(`${apiUrl}zones`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return results;
};

export const retrieveZone = async (props: any, zoneId: string) => {
  const { apiUrl, token } = props;
  const results = await fetch(`${apiUrl}zones/${zoneId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return results;
};

export const createZone = async (props: any, zone: Zone) => {
  const { apiUrl, token } = props;

  const payload = zone;

  const results = await fetch(`${apiUrl}zones`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return results;
};

export const editZone = async (props: any, zoneId: string, zone: Zone) => {
  const { apiUrl, token } = props;

  const payload = zone;

  const results = await fetch(`${apiUrl}zones/${zoneId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return results;
};

export const addZoneToFacility = async (
  props: any,
  zoneId: string,
  facilityId: string
) => {
  const { apiUrl, token } = props;

  const payload = {
    facilityId: facilityId,
  };

  const results = await fetch(`${apiUrl}zones/${zoneId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return results;
};

export const deleteZone = async (props: any, zoneId: string) => {
  const { apiUrl, token } = props;

  const results = await fetch(`${apiUrl}zones/${zoneId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return results;
};

export const addChildZone = async (
  props: any,
  parentZoneId: string,
  childZone: Zone
) => {
  const { apiUrl, token } = props;

  const payload = {
    parentId: parentZoneId,
    ...childZone,
  };

  const results = await fetch(`${apiUrl}zones`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return results;
};

export const deleteAllChildZones = async (
  props: any,
  parentZoneId: string,
  zoneTree: any
) => {};
