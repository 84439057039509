export const getFixedNodesByZone = async (props: any, zoneId: string) => {
  const { apiUrl, token } = props;

  const results = await fetch(`${apiUrl}fixedNodes/zones/${zoneId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((response: any) => response.json())
    .then((response: any) => response)
    .catch((err: any) => console.log(err));
  return results;
};

export const getRadios = async (props: any, zoneId: string) => {
  const { apiUrl, token } = props;
  const body = {
    solrQuery: {
      fq: [`tl_zone_id:${zoneId}`],
    },
  };
  const results = await fetch(`${apiUrl}radios/search/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((response: any) => response.json())
    .then((response: any) => response)
    .catch((err: any) => console.log(err));
  return results;
};

export const updateRadio = async (
  props: any,
  slot: any,
  mountPointXYZ: any
) => {
  const { angle = null, radioId = "" } = slot;
  const { x = 0, y = 0, z = 0 } = mountPointXYZ;
  const { apiUrl, token } = props;
  const body = {
    mountPoint: {
      angle,
    },
    xyz: {
      x,
      y,
      z,
    },
  };

  const results = await fetch(`${apiUrl}radios/${radioId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((response: any) => response.json())
    .then((response: any) => response)
    .catch((err: any) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

export const updateRadios = async (
  props: any,
  slots: any,
  mountPointXYZ: any
) => {
  const results = slots.map(async (slot: any) => {
    if (slot.radioId) {
      return await updateRadio(props, slot, mountPointXYZ);
    }
  });

  return await Promise.all(results).then((completed: any) => {
    if (completed.some((item: any) => item && item.error)) {
      return completed.filter((item: any) => item.error)[0];
    }
    return completed;
  });
};

const createMountPoint = async (props: any, mountPoint: any) => {
  const { apiUrl, token } = props;

  const results = await fetch(`${apiUrl}mountPoints`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(mountPoint),
  })
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      return { error: err };
    });

  return results;
};

const updateMountPoint = async (props: any, mountPoint: any) => {
  const { apiUrl, token } = props;

  const results = await fetch(
    `${apiUrl}mountPoints/${mountPoint.mountPointId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(mountPoint),
    }
  )
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      return { error: err };
    });

  return results;
};

export const upsertMountPoints = async (props: any, mountPoints: any) => {
  const results = mountPoints.map(async (mountPoint: any) => {
    const radioUpdates = await updateRadios(
      props,
      mountPoint.slots,
      mountPoint.xyz
    );
    if (radioUpdates.error) {
      return { error: radioUpdates.error };
    }

    if (mountPoint.mountPointId) {
      return await updateMountPoint(props, mountPoint);
    } else {
      return await createMountPoint(props, mountPoint);
    }
  });

  return await Promise.all(results).then((completed) => {
    if (completed.some((item: any) => item.error)) {
      return completed.filter((item: any) => item.error)[0];
    }
    return completed;
  });
};

export const deleteMountPoint = async (props: any, mountPointId: string) => {
  const { apiUrl = "", token = "" } = props;

  const results = await fetch(`${apiUrl}mountPoints/${mountPointId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((response: any) => response.json())
    .then((response: any) => response)
    .catch((err: any) => console.log(err));
  return results;
};

export const getFixedNodeById = async (props: any, nodeId: string) => {
  const { apiUrl = "", token = "" } = props;

  const results = await fetch(`${apiUrl}fixedNodes/${nodeId}`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((response: any) => response.json())
    .then((response: any) => response)
    .catch((err: any) => console.log(err));
  return results;
};
