import LX from "../../../../assets/LX.png";
import { useHistory } from "react-router-dom";

import CollapsableListItem from "./CollapsableListItem";
import { useStyles } from "./styles";

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  // Divider,
} from "@material-ui/core";

export default function FixedDrawer(props: any) {
  const { navLinks, stickyNavLinks, sideNavWidth, appBarHeight } = props;

  const classes = useStyles({ sideNavWidth, appBarHeight });
  const history = useHistory();
  const isActive = (path: any) => {
    return history.location.pathname.includes(path);
  };

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <List>
        <ListItem className={classes.menuIcon} key={"logo-nav-item"}>
          <img alt="company logo" src={LX} height={"100%"} />
        </ListItem>
        {/* <Divider /> */}

        {navLinks.map((link: any, idx: number) => (
          <ListItem
            classes={{
              root: classes.listItem,
            }}
            className={
              isActive(link.name) ? classes.listItemActive : classes.listItem
            }
            button
            key={`${link.name} - ${idx}`}
            onClick={() => {
              if (link.hyperlink) {
                window.open(link.hyperlink, "_blank", "noopener,noreferrer");
              } else {
                history.push(`${"/"}${link.name}`);
              }
            }}
          >
            <div>
              <ListItemIcon
                classes={{ root: classes.listItemIcon }}
                className={`fas ${link.icon} fa-2x`}
                key={`${link.name} - ${idx} icon`}
              />
              <ListItemText
                classes={{
                  primary: classes.listItemText,
                }}
                primary={link.label}
                key={`${link.name} - ${idx} text`}
              />
            </div>
          </ListItem>
        ))}
        {/* For information and support menu items on the bottom of the nav */}
        <div className={classes.bottomPush}></div>
        {stickyNavLinks.map((link: any, idx: number) => {
          if (link && link.children && link.children.length) {
            return (
              <CollapsableListItem
                isActive={isActive}
                key={`${link.name} - ${idx} collapsible`}
                item={link}
                onClick={(link: any, isHyperLink: boolean) => {
                  if (isHyperLink) {
                    window.open(link, "_blank", "noopener,noreferrer");
                  } else {
                    history.push(`${"/"}${link}`);
                  }
                }}
              />
            );
          } else {
            return (
              <ListItem
                classes={{
                  root: classes.listItem,
                }}
                className={
                  isActive(link.name)
                    ? classes.listItemActive
                    : classes.listItem
                }
                button
                key={`${link.name} - ${idx}`}
                onClick={() => {
                  if (link.hyperlink) {
                    window.open(
                      link.hyperlink,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  } else {
                    history.push(`${"/"}${link.name}`);
                  }
                }}
              >
                <div>
                  <ListItemIcon
                    classes={{ root: classes.listItemIcon }}
                    className={`fas ${link.icon} fa-2x`}
                    key={`${link.name} - ${idx} icon`}
                  />
                  <ListItemText
                    classes={{
                      primary: classes.listItemText,
                    }}
                    primary={link.label}
                    key={`${link.name} - ${idx} text`}
                  />
                </div>
              </ListItem>
            );
          }
        })}
      </List>
    </Drawer>
  );
}
