import { Fragment, lazy, Suspense, useState } from "react";
import { Grid, makeStyles, Switch } from "@material-ui/core";
import { naturalSort } from "../../../utils/naturalSort";
import { NewBatch } from "./interface";
import { postBatch } from "./api";
import ConfirmPCA from "./ConfirmPCA/ConfirmPCA";
import Loading from "../../../components/Loading/Loading";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import moment from "moment";
import NotificationModal from "../../../components/Modals/NotificationModal";
import SimpleSelect from "../../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";

const ImportILDE = lazy(() => import("./ImportILDE/ImportILDE"));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

export default function CreateBatch(props: any) {
  const classes = useStyles();
  const {
    isLoading,
    itemLevelDataElements = [],
    pcaSettings = {},
    setState,
  } = props;
  const timeZone =
    props.timeZone && props.timeZone.value
      ? props.timeZone.value
      : moment.tz.guess();
  // get ILDE and PCA settings from org, if allBatches or default are set, then the batch is a PCA batch, otherwise show toggle for setting PCA
  // if ILDE exist, require import
  const isPCA = pcaSettings.default || pcaSettings.allBatches ? true : false;
  const isILDE = itemLevelDataElements.length ? true : false;
  const [confirmPCA, setConfirmPCA] = useState(false);
  const [importDialog, setImportDialog] = useState({
    dialogShow: false,
    uploadSuccess: false,
    uploadedElements: "",
  });
  const [confirm, setConfirm] = useState({
    modalShow: false,
    text: "",
    error: false,
  });

  const [newBatch, setNewBatch] = useState<NewBatch>({
    product: {
      id: props.selectedProduct.productId,
      name: props.selectedProduct.name,
    },
    timeCreated: moment().tz(timeZone).format("MM/DD/YYYY"),
    topologiesList: [
      {
        assetType: "",
        count: 1,
        productId: props.selectedProduct.productId,
        pcaKeyType: "distribution",
      },
    ],
    customDataFields: [],
    isPCA: isPCA,
    isILDE: isILDE,
  });

  return (
    <>
      <form
        onSubmit={(e: any) => {
          if (isILDE) {
            e.preventDefault();
            setImportDialog({
              ...importDialog,
              dialogShow: true,
            });
          } else {
            e.preventDefault();

            // Active the loading spinner
            setState((prevState: any) => ({ ...prevState, isLoading: true }));

            postBatch(props, newBatch).then((res) => {
              if (res?.error) {
                // Disable Loading Spinner
                setState((prevState: any) => ({
                  ...prevState,
                  isLoading: false,
                }));

                // Triggering error reporting
                props.notificationModal(`${res?.error}`, true, 5000);
              } else {
                // Disable Loading Spinner
                setState((prevState: any) => ({
                  ...prevState,
                  isLoading: false,
                }));

                // Trigger success reporting
                props.notificationModal(
                  `Batch successfully created.`,
                  false,
                  2000
                );
              }
            });
          }
        }}
      >
        <Grid container className={classes.root} spacing={2}>

          {/* Batch Id */}
          <Grid item xs={6}>
            <SimpleTextField
              disabled={isLoading}
              id="tag"
              label="Batch ID"
              onChange={(e: any) => {
                setNewBatch({
                  ...newBatch,
                  tag: e.target.value,
                });
              }}
              required
              value={newBatch.tag || ""}
            />
          </Grid>

          {/* Batch Name */}
          <Grid item xs={6}>
            <SimpleTextField
              disabled={isLoading}
              id="name"
              label="Batch Name - optional"
              onChange={(e: any) => {
                setNewBatch({
                  ...newBatch,
                  name: e.target.value,
                });
              }}
              value={newBatch.name || ""}
            />
          </Grid>

          {/* Product Name */}
          <Grid item xs={6}>
            <SimpleTextField
              disabled={isLoading}
              id="product"
              label="Product Name"
              readOnly
              required
              value={newBatch.product.name}
            />
          </Grid>

          {/* Date Created */}
          <Grid item xs={6}>
            <SimpleTextField
              disabled={isLoading}
              id="timeCreated"
              label="Date Created"
              readOnly
              required
              value={newBatch.timeCreated}
            />
          </Grid>

          {/* Batch Origin */}
          <Grid item xs={6}>
            <SimpleSelect
              disabled={isLoading}
              helperText="Required"
              id="originLocation"
              label="Batch Origin"
              onChange={(e: any) => {
                setNewBatch({
                  ...newBatch,
                  originLocation: e.target.value,
                });
              }}
              options={Object.keys(props.facilities)
                .map((key) => {
                  return {
                    label: props.facilities[key].name,
                    value: key,
                  };
                })
                .sort((a: any, b: any) => naturalSort(a.label, b.label))}
              required
              value={newBatch.originLocation || ""}
              variant="outlined"
            />
          </Grid>

          {/* Final Destination */}
          <Grid item xs={6}>
            <SimpleSelect
              disabled={isLoading}
              id="destinationLocation"
              label="Final Destination - optional"
              onChange={(e: any) => {
                setNewBatch({
                  ...newBatch,
                  destinationLocation: e.target.value,
                });
              }}
              options={Object.keys(props.facilities)
                .map((key) => {
                  return {
                    label: props.facilities[key].name,
                    value: key,
                  };
                })
                .sort((a: any, b: any) => naturalSort(a.label, b.label))}
                value={newBatch.destinationLocation || ""}
                variant="outlined"
            />
          </Grid>

          {/* Batch Description */}
          <Grid item xs={12}>
            <SimpleTextField
              disabled={isLoading}
              id="description"
              label="Batch Description - optional"
              multiline
              onChange={(e: any) => {
                setNewBatch({
                  ...newBatch,
                  description: e.target.value,
                });
              }}
              value={newBatch.description || ""}
            />
          </Grid>


          {/* Units of measure */}
          {!isILDE && newBatch.topologiesList && newBatch.topologiesList.length
            ? newBatch.topologiesList.map((top: any, idx: number) => (
                <Fragment key={`${idx}`}>
                  <Grid item xs={6}>
                    <SimpleSelect
                      disabled={isLoading}
                      helperText="Required"
                      id={`${idx} - assetType`}
                      label="Unit of Measure"
                      required
                      onChange={(e: any) => {
                        // const assetType = e.target.value;
                        let newArr = newBatch.topologiesList || [];
                        newArr[idx] = {
                          ...newArr[idx],
                          assetType: e.target.value,
                        };
                        setNewBatch({
                          ...newBatch,
                          topologiesList: newArr,
                        });
                      }}
                      options={props.assetTypes
                        .map((type: string) => {
                          return {
                            label: type,
                            value: type,
                          };
                        })
                        .sort((a: any, b: any) =>
                        naturalSort(a.label, b.label)
                        )}
                        value={newBatch.topologiesList[idx].assetType}
                        variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SimpleTextField
                      disabled={isLoading}
                      id={`${idx} - count`}
                      InputProps={{ inputProps: { min: 1, max: 100000 } }}
                      label={`Count`}
                      onChange={(e: any) => {
                        let newArr = newBatch.topologiesList || [];
                        newArr[idx] = { ...newArr[idx], count: e.target.value };
                        setNewBatch({
                          ...newBatch,
                          topologiesList: newArr,
                        });
                      }}
                      required
                      type="number"
                      value={newBatch.topologiesList[idx].count}
                    />
                  </Grid>
                </Fragment>
              ))
            : null}
          {/* Add / remove units of measure */}
          {!isILDE ? (
            <>
              {" "}
              <Grid item xs={6}>
                <MaterialUiButton
                  disabled={isLoading}
                  label="Add new unit of measure"
                  onClick={(e:any) => {
                    let newArr = newBatch.topologiesList;
                    newArr.push({
                      assetType: "",
                      count: 1,
                      productId: props.selectedProduct.productId,
                      pcaKeyType: "distribution",
                    });
                    setNewBatch({
                      ...newBatch,
                      topologiesList: newArr,
                    });
                  }}
                  variant="contained"
                />
              </Grid>
              <Grid item xs={6}>
                <MaterialUiButton
                  disabled={newBatch.topologiesList.length <= 1 || isLoading}
                  label="Remove unit of measure"
                  onClick={(e:any) => {
                    let newArr = newBatch.topologiesList;
                    newArr.pop();
                    setNewBatch({
                      ...newBatch,
                      topologiesList: newArr,
                    });
                  }}
                  variant="contained"
                />
              </Grid>
            </>
          ) : null}

          {!pcaSettings.allBatches ? (
            <Grid item xs={6}>
              Apply PCA to the batch
              <Switch
                checked={newBatch.isPCA}
                color="primary"
                disabled={isLoading}
                inputProps={{ "aria-label": "PCA setting" }}
                name="pca"
                onChange={(e:any) => {
                  setNewBatch({
                    ...newBatch,
                    isPCA: e.target.checked,
                  });
                  if (e.target.checked) {
                    setConfirmPCA(true);
                  }
                }}
              />
            </Grid>
          ) : null}

          {isILDE ? (
            <>
              <Grid item xs={12}>
                This batch includes products that require item-level data
                elements. Please upload these data elements.
              </Grid>
              <Grid item xs={12}>
                <MaterialUiButton
                  color="primary"
                  fullWidth
                  label="Next"
                  type="submit"
                  variant="contained"
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <MaterialUiButton
                color="primary"
                fullWidth
                label="Submit"
                type="submit"
                variant="contained"
              />
            </Grid>
          )}
        </Grid>
      </form>

      <ConfirmPCA
        confirmPCA={confirmPCA}
        setConfirmPCA={setConfirmPCA}
        cancel={() => {
          setNewBatch({
            ...newBatch,
            isPCA: false,
          });
        }}
      />
      <ModalDialog
        handleClose={() => {
          setImportDialog({
            ...importDialog,
            dialogShow: false,
          });
        }}
        open={importDialog.dialogShow}
        title={"Upload Item Level Data Elements"}
        content={
          <Suspense fallback={<Loading />}>
            <ImportILDE
              apiUrl={props.apiUrl}
              assetTypes={props.assetTypes}
              facilities={props.facilities}
              itemLevelDataElements={itemLevelDataElements}
              newBatch={newBatch}
              onSuccess={() => {
                setImportDialog({
                  ...importDialog,
                  dialogShow: false,
                  uploadSuccess: true,
                });
                setTimeout(() => {
                  props.onHide();
                }, 2000);
              }}
              pcaId={props.pcaId}
              selectedProduct={props.selectedProduct}
              setConfirm={setConfirm}
              token={props.token}
            />
          </Suspense>
        }
      />
      <NotificationModal
        confirmationModalShow={confirm.modalShow}
        modalClose={() => {
          setConfirm({ ...confirm, modalShow: false });
        }}
        color={confirm.error ? `bg-danger` : `bg-success`}
        confirmationText={confirm.text}
        icon={confirm.error ? "fas fa-times mr-2" : "fas fa-check-circle mr-2"}
      />
    </>
  );
}
