import escapeCharacters from "../../../utils/escapedCharacters";
import moment from "moment-timezone";
import request from "superagent";

const retrieveAssetsEvents = (props: any, body: any) => {
  const { apiUrl, token } = props;
  return new Promise((resolve) => {
    request
      .post(`${apiUrl}assetHistories/search`)
      .set("auth-token", token)
      .send(body)
      .then((response) => {
        resolve({
          values: response.body.assetHistories
            ? response.body.assetHistories
                .map((history: any) => {
                  return { tag: history.assetTag, history: history };
                })
                .reduce((x: any, y: any) => {
                  return {
                    ...x,
                    [y.tag]: x[y.tag]?.length
                      ? [...x[y.tag], y.history]
                      : [y.history],
                  };
                }, {})
            : null,
          cqrGeneratedCount: response.body.assetHistories.filter(
            (ass: any) => ass.event.toLowerCase() === "cqr generated"
          ).length,
          tagDestroyedCount: response.body.assetHistories.filter(
            (ass: any) => ass.event.toLowerCase() === "tag destroyed"
          ).length,
        });
      });
  });
};

export const checkSelectedAssets = async (props: any, state: any) => {
  const { organizationId = "" } = props;
  let results = { isError: false, faultyContent: [] };
  const idArray = Object.keys(state.selectedAssets)
    .map((assId) => `asset_id:${assId}`)
    .join(" OR ");
  let testAssets: any = await retrieveAssetsEvents(props, {
    solrQuery: {
      q: `organization_id:${organizationId}`,
      fq: [
        idArray,
        `event:${escapeCharacters("CQR Generated")} OR event:${escapeCharacters(
          "Tag Destroyed"
        )}`,
      ],
      start: 0,
      sort: `time_of_log desc`,
    },
    limit: 500,
  });

  Object.keys(testAssets.values).forEach((key: any) => {
    const assetTag = key;

    const cqrsGenerated = testAssets.values[key]
      .filter((history: any) => history.event === "CQR Generated")
      .map((el: any) => el.timeOfLog);
    const tagsDestroyed = testAssets.values[key]
      .filter((history: any) => history.event === "Tag Destroyed")
      .map((el: any) => el.timeOfLog);

    if (
      cqrsGenerated.some((cqr: any) =>
        tagsDestroyed.every((tagDestroyed: any) =>
          moment(cqr, "YYYYMMDDHHmmss").isAfter(
            moment(tagDestroyed, "YYYYMMDDHHmmss")
          )
        )
      ) ||
      (cqrsGenerated.length > 0 && tagsDestroyed.length === 0)
    ) {
      results.isError = true;
      (results.faultyContent as any).push(assetTag ? assetTag : "");
    }
  });

  return results;
};

export const parseUserInfo = (row: any, firstLast: boolean = false) => {
  if (firstLast && row.appUserId && (row.firstName || row.lastName)) {
    return `${row.firstName || ""}${row.firstName && row.lastName ? " " : ""}${
      row.lastName || ""
    }`;
  }
  if (row.appUserId && (row.firstName || row.lastName)) {
    return `${row.lastName || ""}${row.firstName && row.lastName ? ", " : ""}${
      row.firstName || ""
    }`;
  } else {
    return "";
  }

  // else if (row.appUserId === "53ef1d1f-3e47-46e7-b444-18170051486f") {
  //   return `iOS User`;
  // } else if (row.appUserId === "aabbab69-10c3-4c7e-9011-6f1c05e7b0a7") {
  //   return `Android User`;
  // } else {
  //   return ``;
  // }
};
