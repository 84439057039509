import React from "react";
import { makeStyles, Grid } from "@material-ui/core";

import CentroidRadiusMap from "../../../Maps/CentroidRadiusMap/CentroidRadiusMap";
import { formatLatLon } from "../../../../../../utils/lat_lon_utils";

const useStyles = makeStyles((theme: any) => ({
  root: {},
}));
function CentroidRadius(props: any) {
  const classes = useStyles();
  const { radius = "", centroidJSON = {} } = props.facility;
  const longitude = centroidJSON?.x || props.facility.location?.longitude || "";
  const latitude = centroidJSON?.y || props.facility.location?.latitude || "";

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item xs={12}>
        {" "}
        <h3>Centroid and Radius</h3>
      </Grid>
      <Grid item xs={12}>
        {latitude && longitude ? (
          <CentroidRadiusMap
            width="80%"
            height="42vh"
            readOnly
            radius={radius}
            latitude={latitude}
            longitude={longitude}
          />
        ) : null}
      </Grid>
      <Grid item xs={12} sm={6}>
        <strong>Latitude & Longitude</strong> <br />
        {latitude && longitude
          ? formatLatLon(latitude, longitude)
          : "None Provided"}
      </Grid>
      <Grid item xs={12} sm={6}>
        <strong>Radius</strong> <br />
        {`${radius} km` || "None provided"}
      </Grid>
    </Grid>
  );
}

export default CentroidRadius;
