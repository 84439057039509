import { CircularProgress } from "@material-ui/core";

export default function Loading(props) {
  const { color = "", margin, opaque } = props;
  const opaqueStyle = {
    position: "fixed",
    width: "100%",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    backgroundColor: "rgba(255,255,255,0.7)",
  };
  return (
    <div
      className="h-100 w-100 position-absolute d-flex align-items-center justify-content-center $zindex-tooltip visible"
      style={
        opaque
          ? {
              zIndex: 1,
              margin: margin,
              color: color,
              ...opaqueStyle,
            }
          : {
              zIndex: 1,
              margin: margin,
              color: color,
            }
      }
    >
      <CircularProgress />
      <span className="sr-only">Loading...</span>
    </div>
  );
}
