import { useEffect } from "react";

import { makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  root: {},
}));

function Information(props: any) {
  const classes = useStyles();

  const { zonesMap = {}, selectedZone = {}, facility = {} } = props;
  const {
    name,
    abbreviation,
    floor,
    category,
    zoneType,
    parentId,
    propertiesMap = {},
  } = selectedZone;
  const { description = "" } = propertiesMap;

  useEffect(() => {}, [selectedZone]);

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12}>
        {" "}
        <h3>
          {name}
          {facility?.name ? ` | ${facility.name}` : ""}
        </h3>
      </Grid>
      <Grid item xs={12} sm={4}>
        <strong>Abbreviation</strong> <br />
        {abbreviation || "None provided"}
      </Grid>
      <Grid item xs={12} sm={4}>
        <strong>Description</strong> <br />
        {description || "None provided"}
      </Grid>
      <Grid item xs={12} sm={4}>
        <strong>Floor</strong> <br />
        {floor || "None provided"}
      </Grid>
      <Grid item xs={12} sm={4}>
        <strong>Category</strong> <br />
        {category || "None provided"}
      </Grid>
      <Grid item xs={12} sm={4}>
        <strong>Zone Type</strong> <br />
        {zoneType || "None provided"}
      </Grid>

      <Grid item xs={12} sm={4}>
        <strong>Parent Zone</strong> <br />
        {zonesMap[parentId]?.name || "None provided"}
      </Grid>
    </Grid>
  );
}

export default Information;
