import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { Button, makeStyles } from "@material-ui/core";
import { easeCubic } from "d3-ease";
import { showLastEvents } from "../api";
import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import ReactMapGL, {
  FullscreenControl,
  LinearInterpolator,
  Marker,
  NavigationControl,
  Popup,
  ScaleControl,
} from "react-map-gl";

// Component is super heavy, which is why we are using lazy/Suspense
const DynamicIconRender = lazy(
  () => import("../../../../components/IconPicker/DynamicIconRender")
);

const useStyles = makeStyles((theme) => ({
  dataButton: {
    marginTop: "5px",
  },
}));

export default function Map(props: any) {
  const classes = useStyles();

  const mapToken =
    "pk.eyJ1IjoiZGFuaWVsZG91YW5na2Vzb25lIiwiYSI6ImNqeGYxMTF5YTA5NjQzeW1mMmM4eXIxdWIifQ.jqF1at0nWobKgC3t1OY4kw";

  const {
    eventTypesMap = {},
    timeZone,
    facilities,
    state,
    setState,
    setConfirm,
    setLoading,
    apiUrl,
    token,
    organizationId,
    classifications,
  } = props;

  const assets = useMemo(() => state.assets || [], [state.assets]);

  // hotspot prop for testing purposes only
  const [hotSpot, setHotSpot] = useState<any>(props.hotSpot || null);

  const [viewport, setViewport] = useState<any>({});

  const [satView, setSatView] = useState<boolean>(false);

  useEffect(() => {
    const validateLat =
      /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    const validateLong =
      /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    if (
      assets &&
      !assets[0]?.latitude &&
      !assets[0]?.longitude &&
      assets[0]?.failityId
    ) {
      const currentFacility = facilities[assets[0]?.facilityId] || {};
      const { location = {} } = currentFacility;
      const { latitude: currentLat, longitude: currentLon } = location;
      setViewport({
        zoom: 4,
        latitude: currentLat,
        longitude: currentLon,
        bearing: 0,
        pitch: 0,
        transitionDuration: 1800,
        transitionInterpolator: new LinearInterpolator(),
        transitionEasing: easeCubic,
      });
    } else if (
      assets &&
      validateLat.test(assets[0]?.latitude) &&
      validateLong.test(assets[0]?.longitude)
    ) {
      setViewport({
        zoom: 4,
        latitude: assets[0].latitude,
        longitude: assets[0].longitude,
        bearing: 0,
        pitch: 0,
        transitionDuration: 1800,
        transitionInterpolator: new LinearInterpolator(),
        transitionEasing: easeCubic,
      });
    }
  }, [assets, facilities]);

  const _closePopup = () => {
    setHotSpot(null);
  };
  const validateLat =
    /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
  const validateLong =
    /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

  const renderMarkers = assets
    .map((asset: any, idx: number) => {
      if (
        asset &&
        (!asset.latitude || !asset.longitude) &&
        asset.facility &&
        asset.facilityId
      ) {
        const currentFacility = facilities[asset.facilityId] || {};
        const { location = {} } = currentFacility;
        const { latitude: currentLat, longitude: currentLon } = location;
        return { ...asset, latitude: currentLat, longitude: currentLon };
      } else if (
        asset &&
        (asset.latitude !== 0 || asset.longitude !== 0) &&
        validateLat.test(asset.latitude) &&
        validateLong.test(asset.longitude)
      ) {
        return asset;
      }
      return null;
    })
    .filter((asset: any) => asset !== null)
    .sort((a: any, b: any) => a.timeOfLog - b.timeOfLog)
    .map((asset: any, idx: number) => {
      return (
        <Marker
          key={`${asset.assetId} - ${idx} - ${asset.timeOfLog}`}
          latitude={asset.latitude}
          longitude={asset.longitude}
          offsetLeft={-20}
          offsetTop={-10}
        >
          {eventTypesMap[asset.lastEvent] &&
          eventTypesMap[asset.lastEvent].icon ? (
            <Suspense fallback={<div />}>
              <DynamicIconRender
                iconName={eventTypesMap[asset.lastEvent].icon || "LocationOn"}
                style={{
                  cursor: "pointer",
                  fill:
                    eventTypesMap[asset.lastEvent] &&
                    eventTypesMap[asset.lastEvent].color
                      ? eventTypesMap[asset.lastEvent].color
                      : satView
                      ? "#FFC854"
                      : "#32355C",
                }}
                onMouseEnter={() => setHotSpot(asset)}
                onClick={() => setHotSpot(asset)}
              />
            </Suspense>
          ) : (
            <i
              className="fas fa-map-marker"
              // marker colors are rendered by checking the eventTypes object for the event action color
              style={{
                cursor: "pointer",
                color:
                  eventTypesMap[asset.lastEvent] &&
                  eventTypesMap[asset.lastEvent].color
                    ? eventTypesMap[asset.lastEvent].color
                    : satView
                    ? "#FFC854"
                    : "#32355C",
              }}
              onMouseEnter={() => setHotSpot(asset)}
              onClick={() => setHotSpot(asset)}
            ></i>
          )}
        </Marker>
      );
    });

  const renderPopups =
    hotSpot !== null ? (
      <Popup
        latitude={parseFloat(hotSpot.latitude)}
        longitude={parseFloat(hotSpot.longitude)}
        onClose={_closePopup}
        className={"popup"}
        closeOnClick={false}
      >
        {hotSpot.tag ? <h5 style={{ marginTop: "5px" }}>{hotSpot.tag}</h5> : ""}
        {hotSpot.lastEvent ? <h5 style={{}}>{hotSpot.lastEvent}</h5> : ""}{" "}
        {hotSpot.timeOfLogLong ? (
          <>
            <span>
              Date:{" "}
              {unix_utc_to_local_12_hour_clock(hotSpot.timeOfLogLong, timeZone)}
            </span>
            <br />
          </>
        ) : null}
        {hotSpot.facilityId &&
        facilities[hotSpot.facilityId] &&
        hotSpot.latitude !== 0 &&
        hotSpot.longitude !== 0 ? (
          <span>Facility: {facilities[hotSpot.facilityId].name}</span>
        ) : (
          ""
        )}{" "}
        {hotSpot.propertiesMap && hotSpot.propertiesMap.note ? (
          <span>Note: {hotSpot.propertiesMap.note}</span>
        ) : (
          ""
        )}
        <br />
        <Button
          className={classes.dataButton}
          color="primary"
          variant="contained"
          onClick={(e: any) => {
            // get initial count from this facility... if greater than 1,000, throw a window confirm.
            showLastEvents(
              { apiUrl, token, organizationId, classifications },
              {
                ...state.filters,
                locations: [hotSpot.facilityId],
                start: 0,
                limit: 1,
              }
            ).then((res) => {
              if (res.error) {
                setConfirm({
                  modalShow: true,
                  text: res.error,
                  error: true,
                });
                setLoading(false);
                return null;
              }
              if (res.count >= 1000) {
                if (
                  !window.confirm(
                    `This facility has more than 1,000 assets. The map table will display only the first 1,000 assets at this facility. To narrow your results for this facility, please change your filters.`
                  )
                ) {
                  setLoading(false);
                  return null;
                }
              }
              showLastEvents(
                props,
                { ...state.filters, locations: [hotSpot.facilityId] },
                true
              ).then((res) => {
                if (res.error) {
                  setConfirm({
                    modalShow: true,
                    text: res.error,
                    error: true,
                  });
                  setLoading(false);
                  return null;
                }
                setState((s: any) => {
                  return {
                    ...s,
                    mapTableData: res.assets.filter(
                      (asset: any) => asset.facilityId === hotSpot.facilityId
                    ),
                  };
                });
                setLoading(false);
              });
            });
          }}
        >
          See Data
        </Button>
      </Popup>
    ) : null;

  const fullscreenControlStyle: any = {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "10px",
  };

  const navStyle: any = {
    position: "absolute",
    top: 36,
    left: 0,
    padding: "10px",
  };

  const scaleControlStyle: any = {
    position: "absolute",
    bottom: 36,
    left: 0,
    padding: "10px",
  };

  const satViewStyle: any = {
    position: "absolute",
    top: 10,
    right: 10,
    padding: "10px",
  };

  return (
    <ReactMapGL
      {...viewport}
      width="100%"
      height="100%"
      mapboxApiAccessToken={mapToken}
      mapStyle={
        satView
          ? "mapbox://styles/mapbox/satellite-v9"
          : "mapbox://styles/mapbox/light-v10"
      }
      style={{
        border: "rgba(50, 53, 93, 0.514) solid 2px",
        borderRadius: "4px",
      }}
      onViewportChange={(viewport: any) => setViewport(viewport)}
    >
      {renderMarkers}
      {renderPopups}
      <div style={fullscreenControlStyle}>
        <FullscreenControl />
      </div>
      <div style={navStyle}>
        <NavigationControl />
      </div>
      <div style={scaleControlStyle}>
        <ScaleControl />
      </div>
      <div style={satViewStyle}>
        <i
          className={
            satView ? "fa fa-road darkmode-icon" : "fa fa-globe darkmode-icon"
          }
          style={satView ? { color: "#FFC854" } : { color: "#32355B" }}
          onClick={() => {
            setSatView(!satView);
          }}
        >
          {}
        </i>
      </div>
    </ReactMapGL>
  );
}
