import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  label: {
    fontWeight: "500",
    marginBottom: ".2rem",
  },
  select: {
    width: "100%",
  },
}));

export default function SelectWithExternalLabel(props) {
  const classes = useStyles();
  const {
    disabled = false,
    error,
    helperText,
    id,
    inputProps,
    label,
    labelStyles,
    margin,

    // menuItemOnClick was added in the case we needed to grab the label of the selected Menu Item
    // Should look something like this: (e)=>console.log(e.currentTarget.dataset.optionLabel)
    menuItemOnClick = () => {},

    onChange,
    options,
    readOnly,
    required,
    size,
    style,
    type,
    value,
    variant,
  } = props;

  return (
    <>
      {label ? (
        <div className={`${classes.label} ${labelStyles ? labelStyles : ""}`}>
          {label ? label : "Select"}
        </div>
      ) : null}
      <FormControl
        className={
          props.classes?.select ? props.classes.select : classes.select
        }
        error={error}
        size={size}
        variant={variant ? variant : "standard"}
      >
        <Select
          disabled={disabled}
          id={id}
          inputProps={{ readOnly: readOnly, ...inputProps }}
          margin={margin}
          onChange={(e) => onChange(e)}
          required={required}
          style={style}
          type={type}
          value={value || ""}
        >
          {options && options.length ? (
            options.map((option, idx) => (
              <MenuItem
                data-option-label={option.label}
                onClick={(e) => menuItemOnClick(e)}
                value={option.value}
                key={`${idx} - ${option.value}`}
              >
                {option.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem>None</MenuItem>
          )}
        </Select>
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </>
  );
}
