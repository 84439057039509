import Devices from "./Tabs/Devices";
import Information from "./Tabs/Information";

import CloseIcon from "@material-ui/icons/Close";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";

import HorizontalTabs from "../../../../components/Tabs/HorizontalTabs";

const useStyles = makeStyles((theme: any) => ({
  content: {
    minHeight: "75vh",
    maxHeight: "75vh",
    overflow: "auto",
  },
  root: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
export default function ZoneDetail(props: any) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby={props.title}
      open={props.open || false}
      fullWidth
      maxWidth="md"
      className={classes.root}
    >
      <DialogTitle>
        {" "}
        {props.handleClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <HorizontalTabs noAppBarColor noElevation>
          <Information label="Zone Information" {...props} />
          <Devices label="Active Devices" {...props} />
        </HorizontalTabs>
      </DialogContent>
    </Dialog>
  );
}
