import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export default function SimpleSwitch(props) {
  const {
    checked,
    color = "primary",
    disabled = false,
    formControlLabelStyles,
    inputProps = {},
    label = "",
    labelPlacement,
    labelStyles,
    onChange,
    required,
    size,
  } = props;
  return (
    <FormGroup row>
      <FormControlLabel
        className={formControlLabelStyles}
        control={
          <Switch
            checked={checked}
            color={color}
            disabled={disabled}
            inputProps={inputProps}
            onChange={onChange}
            required={required}
            size={size}
          />
        }
        label={
          <span className={labelStyles}>
            {label}
          </span>}
        labelPlacement={labelPlacement}
      />
    </FormGroup>
  );
}
