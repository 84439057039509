import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { getFixedNodeById } from "../api";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    marginLeft: ".5rem",
  },
  accordionSummary: {
    flexDirection: "row-reverse",
    padding: 0,
  },
}));

export default function ViewNodeInformation(props: any) {
  const classes = useStyles();

  const { apiUrl, token } = props;
  const { selectedNode = {} } = props.state;

  const [mountPoints, setMountPoints]:any = useState([]);

  useEffect(() => {
    getFixedNodeById({ apiUrl, token }, selectedNode.fixedNodeId).then(
      (res) => {
        setMountPoints(res.fixedNode.mountPoints || []);
      }
    );
  }, [selectedNode.fixedNodeId, apiUrl, token]);

  return (
    <div className={classes.root}>
      {mountPoints?.length > 0 ? (
        mountPoints
          // Sorts the mountpoints alphabetically by their names
          .sort((a: any = {}, b: any = {}) => {
            const nameA = a.name ? a.name.toUpperCase() : a.name;
            const nameB = b.name ? b.name.toUpperCase() : b.name;
            if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1;
            }
            return null;
          })
          .map((mountPoint: any, idx: number) => (
            <Accordion key={`${mountPoint.name}-${idx}`}>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {mountPoint.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Typography>
                      <strong>X (meters):</strong> {mountPoint.xyz.x}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <strong>Y (meters):</strong> {mountPoint.xyz.y}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <strong>Z (meters):</strong> {mountPoint.xyz.z}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {mountPoint.slots?.length > 0
                      ? mountPoint.slots.map((slot: any) => (
                          <Typography>
                            <strong>{slot.angle}° Angle: </strong>
                            {slot.name}
                          </Typography>
                        ))
                      : null}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
      ) : (
        <div>
          No mount points configured for {selectedNode.node}. Please configure
          mount points in the edit node window (
          <i className={`fas fa-edit`}></i>)
        </div>
      )}
    </div>
  );
}
