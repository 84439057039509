import { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { Dropzone } from "../../../components/Dropzone/Dropzone";
import { makeStyles } from "@material-ui/core";
import { uploadPDFImage } from "./api";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  dropZone: {
    padding: "0 5rem",
    textAlign:'center'
  },
}));

export default function FacilityImageUpload(props: any) {
  const { handleSnackbar, setState } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  return (
    <Grid className={classes.dropZone} container>
      <Grid item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Dropzone
            // accept=".pdf"
            accept="image/jpeg, image/png, image/gif"
            acceptText="Drag 'n' drop or click to select file (only image files will be accepted)"
            callback={(acceptedFiles: any, accepted: Function) => {
              setLoading(true);
              accepted(true);
              uploadPDFImage({...props, file:acceptedFiles, handleSnackbar, setState})
            }}
            clearFile={() => {
              console.log("clear files");
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
