import request from "superagent";
import * as DefaultBackgrounds from "../../assets/qrBackgrounds/DefaultQrBackground";

export function downloadCQR(value, props) {
  const {
    apiUrl,
    batchAsset,
    batchId,
    assetType,
    token,
    isLoading,
    onSuccess,
    selectCQRLayout,
    selectedAssets,
    state,
  } = props;

  const { eccLevel, format, magnification, version } = state;
  let body = {
    eccLevel,
    format,
    magnification,
    qrBackground: selectCQRLayout.value ? selectCQRLayout.label : null,
    propertiesMap: {
      width: 1000,
      height: 1000,
    },
    showTag: false,
    target: "lxproductscan",
    urlBase: "https://qr.locatorx.com",
    version,
  };

  if (!batchAsset) {
    body.assetIds = Object.keys(selectedAssets);
  }

  const req = batchAsset
    ? request.post(`${apiUrl}batches/${batchId}/qrCodes?assetType=${assetType}`)
    : request.post(`${apiUrl}assets/qrCodes`);

  req
    .set("auth-token", token)
    .send(body)
    .responseType("blob")
    .then((res) => {
      if (res) {
        // on successful download, update event log with CQR Generated Event for each asset
        if (!batchAsset) {
          const selectedAssetsArray = [];

          // We form the selectedAssetsArray so we can send this to the assets/assetsAction endpoint
          Object.keys(selectedAssets).forEach((asset) => {
            selectedAssetsArray.push(asset);
          });

          request
            .post(`${apiUrl}assets/assetsAction`)
            .set("auth-token", token)
            .set("accept", "application/json")
            .send({ assetIds: selectedAssetsArray, action: "CQR Generated" })
            .then((res) => {
              return null
            });
        }
        let url = URL.createObjectURL(res.body);
        let a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        a.href = url;
        a.download = `QRCodes${batchId ? `_${batchId}` : ""}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
        isLoading(false);
        onSuccess("CQR Successfully Created");
      } else alert("Error: " + res.body.error);
    });
}

function formTemplateBody(props) {
  const {
    aboveData,
    aboveImages,
    belowData,
    belowImages,
    consoleRole,
    cqrLayoutName,
    dataElementAbove,
    dataElementBelow,
    font,
    fontSize,
    templateSize,
    textFormats,
  } = props;
  const body = {
    images: [],
    name: cqrLayoutName.value,
    propertiesMap: {
      fields: [],
      height: templateSize.value + parseInt(fontSize) * 3,
      images: [],
      templateValues: {
        aboveData,
        aboveImages,
        belowData,
        belowImages,
        consoleRole,
        cqrLayoutName: cqrLayoutName.value,
        dataElementAbove: dataElementAbove,
        dataElementBelow: dataElementBelow,
        font: font,
        fontSize: fontSize,
        templateSize,
        textFormats: textFormats,
        userType: "",
      },
      width: templateSize.value + parseInt(fontSize) * 3,
    },
    qrHeight: templateSize.value + parseInt(fontSize) * 3,
    qrWidth: templateSize.value + parseInt(fontSize) * 3,
    xOffset: 0,
    yOffset: 0,
  };

  const fieldsSwitch = (element, position = "above") => {
    let item = {
      fontName: font.value,
      fontSize,
      location: position,
    };

    if (textFormats && textFormats.length > 0) {
      item.fontStyle = textFormats;
    }

    switch (element.value) {
      case "assetDetails":
        item.data = "assetDetails";
        item.source = "asset";
        return item;
      case "assetTag":
        item.data = "tag";
        item.source = "asset.";
        return item;
      case "batch":
        item.data = position === "above" ? aboveData.value : belowData.value;
        item.source = "batch.";
        return item;
      case "category":
        item.data = "category";
        item.source = "asset";
        return item;
      case "Current Value":
        item.data = "Current Value";
        item.source = "asset";
        return item;
      case "description":
        item.data = "description";
        item.source = "asset";
        return item;
      case "Date of Manufacture":
        item.data = "Date of Manufacture";
        item.source = "asset";
        return item;
      case "product":
        item.data = position === "above" ? aboveData.value : belowData.value;
        item.source = "product.";
        return item;
      case "item":
        item.data = position === "above" ? aboveData.value : belowData.value;
        item.source = "asset.";
        return item;
      case "Serial No":
        item.data = "Serial No";
        item.source = "asset";
        return item;
      case "userDefinedText":
        item.data = position === "above" ? aboveData.value : belowData.value;
        item.source = "text";
        return item;
      default:
        return null;
    }
  };

  if (dataElementAbove.value) {
    body.propertiesMap.fields.push({
      field: fieldsSwitch(dataElementAbove, "above"),
    });
  }

  if (dataElementBelow.value) {
    body.propertiesMap.fields.push({
      field: fieldsSwitch(dataElementBelow, "below"),
    });
  }

  if (aboveImages.length > 0) {
    aboveImages.forEach((item) => {
      body.propertiesMap.images.push({
        name: item.name,
        location: item.position,
      });
    });
  }

  if (belowImages.length > 0) {
    belowImages.forEach((item) => {
      body.propertiesMap.images.push({
        name: item.name,
        location: item.position,
      });
    });
  }

  return body;
}

export const createTemplate = async (props) => {
  const { apiUrl, token, aboveImages, belowImages } = props;
  const body = formTemplateBody(props);

  const template = await request
    .post(`${apiUrl}qrBackgrounds`)
    .set("auth-token", token)
    .send(body)
    .then((response) => {
      if (aboveImages.length > 0) {
        uploadTemplateImages(
          {
            ...props,
            qrBackgroundId: response.body.qrBackground.qrBackgroundId,
          },
          aboveImages
        );
      } else if (belowImages.length > 0) {
        uploadTemplateImages(
          {
            ...props,
            qrBackgroundId: response.body.qrBackground.qrBackgroundId,
          },
          belowImages
        );
      }
      return response.body.qrBackground;
    });

  return template;
};

export function uploadTemplateImages(props, images) {
  const { apiUrl, token, qrBackgroundId } = props;

  images.forEach((imageItem) => {
    const { image, name } = imageItem;
    const newBlob = new Blob([image]);
    request
      .post(`${apiUrl}qrBackgrounds/${qrBackgroundId}/images/name/${name}`)
      .set("auth-token", token)
      .attach("pngimagedata", newBlob)
      .then((response) => {
        return null;
      });
  });
}

export const deleteTemplate = async (props, templateToDelete) => {
  const { apiUrl, token, cqrTemplates } = props;
  const qrBackgroundId = cqrTemplates.find(
    (element) => element.qrBackgroundId === templateToDelete
  ).qrBackgroundId;

  const deletion = await request
    .delete(`${apiUrl}qrBackgrounds/${qrBackgroundId}`)
    .set("auth-token", token)
    .then((response) => {
      return response;
    });

  return deletion;
};

export const createQrBackgroundSessions = async (props) => {
  const { apiUrl, token } = props;
  const newBlob = new Blob([DefaultBackgrounds.Medium]);
  const results = await request
    .post(`${apiUrl}qrBackgrounds/session`)
    .set("auth-token", token)
    .attach("pngimagedata", newBlob)
    .then((response) => response)
    .catch((err) =>
      console.log(`There was an Error creating the session template:${err}`)
    );
  return results;
};

export const deleteQrBackgroundSessions = async (props, templateToDelete) => {
  const { apiUrl, token } = props;
  const results = await request
    .delete(`${apiUrl}qrBackgrounds/${templateToDelete}`)
    .set("auth-token", token)
    .then((response) => {
      return response;
    });

  return results;
};

export const updateSessionImage = async (props, templateId) => {
  const { apiUrl, assetId, eccLevel, magnification, token, version } = props;
  let body = formTemplateBody(props);
  body.qrBackgroundId = templateId;

  // Here we form the body to match what the backend is expecting
  delete body.name;
  // delete body.images;
  delete body.propertiesMap.templateValues;

  body.magnification = magnification;
  body.eccLevel = eccLevel;
  body.version = version;

  const sessionImage = request
    .post(`${apiUrl}assets/${assetId}/qrCode`)
    .send(body)
    .set("auth-token", token)
    .responseType("blob")
    .then((response) => {
      let url = URL.createObjectURL(response.body);

      return url;
    });
  return sessionImage;
};
