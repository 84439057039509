import Grid from "@material-ui/core/Grid";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { deleteFacilityImage } from "./api";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem 0",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "2rem 1rem",
  },
  header: {
    textAlign: "center",
    paddingBottom: "1rem",
  },
}));

export default function ResetFacilityImage(props: any) {
  const { facilityId, handleSnackbar, setModalState, state, setState } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.container} container>
      <Grid className={classes.header} item xs={12}>
        <Typography variant="h6">
          Are you sure you want to remove the facility image?
        </Typography>
      </Grid>
      {/* Buttons */}
      <Grid className={classes.buttonContainer} item xs={12}>
        <Grid item xs={4}>
          <MaterialUiButton
            color="cancel"
            fullWidth
            label="CANCEL"
            onClick={() => {
              setModalState((prevState: any) => ({
                ...prevState,
                modalShow: false,
              }));
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <MaterialUiButton
            color="primary"
            fullWidth
            label="SUBMIT"
            onClick={() => {
              deleteFacilityImage({
                ...props,
                facilityId,
                facilityImageId: state.facilityImageId,
              }).then((res: any = {}) => {
                if (res.success) {
                  // Close the Modal
                  setModalState((prevState: any) => ({
                    ...prevState,
                    modalShow: false,
                  }));

                  // Handles the snackbar
                  handleSnackbar('Facility Image Removed', false)

                  // Sets the state
                  setState((prevState: any) => ({
                    ...prevState,
                    facilityImage: null,
                    facilityImageId: null,
                    farPoint: { x: 0, y: 0, disablePlunger: false },
                    zeroPoint: { x: 0, y: 0, disablePlunger: false },
                  }));
                }
              });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
