import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import request from "superagent";

export const handleExport = (state, props, handleConfirmationModal) => {
  const { timeZone = {} } = props;
  const browserTz = moment.tz.guess();
  const currentTime = moment()
    .tz(timeZone && timeZone.value ? timeZone.value : browserTz)
    .format("YYYY-MM-DD");
  // select products from component mounted state
  const { selectedProducts = [] } = state;
  // break into array of value objects
  const raw = Object.values(selectedProducts);

  // function to pull only the fields we want for our CSV file
  const filterProducts = (products) => {
    const allowed = ["name", "productType", "tag"];

    // return only allowed fields
    let filtered = products.map((el) =>
      Object.keys(el)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = el[key];
          return obj;
        }, {})
    );

    // the propertiesMap is a child object that has valuable data. We'll need to traverse the object again to pull these fields
    let tableArray = [];

    for (let i = 0; i < filtered.length; i++) {
      // if (filtered[i].propertiesMap) {
      //   // the labels for the product origin lives in the facilities state object. There is also a location field in the state object that may be useful as well...
      //   filtered[i].productOrigin = state.facilities[
      //     filtered[i].propertiesMap.productOrigin
      //   ]
      //     ? state.facilities[filtered[i].propertiesMap.productOrigin].name
      //     : "undefined";
      // }

      // constructing a new obj for our CSV that coordinates with the headers defined in the options object... each obj here is a new row for our CSV
      let obj = {
        name: filtered[i].name ? filtered[i].name : "",
        productType: filtered[i].productType ? filtered[i].productType : "",
        productCode: filtered[i].tag ? filtered[i].tag : "",
      };

      // pushing to table array
      tableArray.push(obj);
    }

    return tableArray;
  };

  const tableArray = filterProducts(raw);

  if (tableArray.length === 0) {
    return handleConfirmationModal(
      `No products selected for export.`,
      true,
      3000
    );
  }

  const options = {
    filename: `Export_${currentTime}`,
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: `Products`,
    useTextFile: false,
    useBom: true,
    // useKeysAsHeaders: true,
    // instead of useKeysAsHeaders, you can specify headers instead:
    headers: ["Product Name", "Product Category", "Product Code"],
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(tableArray);
};

export const uploadImage = (props, callback) => {
  const { apiUrl, token, productId, image, name } = props;
  const jsonData = { name };
  const newBlob = new Blob([image]);
  request
    .post(`${apiUrl}products/${productId}/images`)
    .set("auth-token", token)
    .attach("pngimagedata", newBlob)
    .field("jsondata", JSON.stringify(jsonData))
    .then((response) => {
      return null;
    });
};

export const editImage = (props) => {
  const { apiUrl, token, productId, imageId, name } = props;
  const jsonData = { name };
  request
    .put(`${apiUrl}products/${productId}/images/${imageId}`)
    .set("auth-token", token)
    .field("jsondata", JSON.stringify(jsonData))
    .then((response) => {
      return null;
    });
};

export const deleteImage = (props) => {
  const { apiUrl, token, productId, imageId } = props;
  request
    .delete(`${apiUrl}products/${productId}/images/${imageId}`)
    .set("auth-token", token)
    .then((response) => {
      return null;
    });
};
