import request from "superagent";
import "moment-timezone";
import moment from "moment-timezone/builds/moment-timezone-with-data";

export function RetrieveNotifications(props, callback) {
  const { apiUrl, token, userId } = props;
  request
    .get(`${apiUrl}userNotifications/appUsers/${userId}`)
    .set("auth-token", token)
    .then((response) => {
      callback(response.body.userNotifications);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveAssetHistorysByEvent(props, callback) {
  const { apiUrl, currentDate, event, rangeOfMonths, token } = props;
  // Grabs the first month from the range specified
  const firstMonth = moment(currentDate)
    .subtract(rangeOfMonths, "month")
    .format("YYYYMM");

  // Grabs the last month from the range specified
  const lastMonth = moment(currentDate).subtract(1, "month").format("YYYYMM");

  // Grabs the last day of specified month in the range specified
  const lastMonthsDaysCount = moment(lastMonth, "MMM YYYY").daysInMonth();
  // If ranges are not provided, the /api/assetHistories/events/{event} endpoint is called

  const req =
    currentDate !== undefined && rangeOfMonths !== undefined
      ? request.get(
          `${apiUrl}assetHistories/events/${event}?startDate=${firstMonth}01&endDate=${lastMonth}${lastMonthsDaysCount}`
        )
      : request.get(`${apiUrl}assetHistories/events/${event}`);
  req
    .set("auth-token", token)
    .then((response) => {
      callback(response.body.recentScans);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function EditExistingUser(props, body, callback) {
  const { apiUrl, token, userId } = props;
  request
    .put(`${apiUrl}appUsers/${userId}`)
    .send(body)
    .set("auth-token", token)
    .then((response) => {
      callback(response.body.appUser);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveFacilities(props, callback) {
  const { apiUrl, token } = props;
  request
    .get(`${apiUrl}facilities`)
    .set("auth-token", token)
    .then((response) => {
      let facilities = {};

      response.body.facilities.forEach((element) => {
        const { facilityId } = element;
        facilities[facilityId] = {
          ...element,
        };
      });
      callback(facilities);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveBatches(props, callback) {
  const { apiUrl, token } = props;
  request
    .get(`${apiUrl}batches`)
    .set("auth-token", token)
    .then((response) => {
      callback(response.body.batches);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveBatch(props, callback) {
  const { apiUrl, token, batchId } = props;
  request
    .get(`${apiUrl}batches/${batchId}`)
    .set("auth-token", token)
    .set("accept", "application/json")
    .then((response) => {
      callback(response.body.batch);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveActions(props, callback) {
  const { apiUrl, token, organizationId } = props;
  request
    .get(`${apiUrl}organizations/${organizationId}`)
    .set("auth-token", token)
    .then((response) => {
      callback(response.body.organization.eventTypesMap);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveAppUser(props, callback) {
  const { apiUrl, token, userId } = props;
  request
    .get(`${apiUrl}appUsers/${userId}`)
    .set("auth-token", token)
    .set("accept", "application/json")
    .then((response) => {
      callback(response.body.appUser);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveAppUsers(props, callback) {
  const { apiUrl, token } = props;
  request
    .get(`${apiUrl}appUsers/`)
    .set("auth-token", token)
    .set("accept", "application/json")
    .then((response) => {
      callback(response.body.appUsers);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveProducts(props, callback) {
  const { apiUrl, token } = props;
  request
    .get(`${apiUrl}products`)
    .set("auth-token", token)
    .then((response) => {
      callback(response.body.products);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveProduct(props, callback) {
  const { apiUrl, token, productId } = props;
  request
    .get(`${apiUrl}products/${productId}`)
    .set("auth-token", token)
    .set("accept", "application/json")
    .then((response) => {
      callback(response.body.product);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveAssetHistory(props, callback) {
  const { apiUrl, token, assetId } = props;
  request
    .get(`${apiUrl}assets/${assetId}/scans`)
    .set("auth-token", token)
    .set("accept", "application/json")
    .then((response) => {
      callback(response.body.recentScans);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveBatchAssets(props, callback) {
  const { apiUrl, token, batchId } = props;
  request
    .get(`${apiUrl}batches/${batchId}/assets`)
    .set("auth-token", token)
    .set("accept", "application/json")
    .then((response) => {
      const { assets = [] } = response.body;
      callback(assets);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function GenerateAssetHistoriesCSV(props, callback) {
  const { apiUrl, token, assetId } = props;
  request
    .get(`${apiUrl}assetHistories/${assetId}`)
    .set("auth-token", token)
    .set("Accept", "text/csv")
    .responseType("blob")
    .then((response) => {
      callback(response.body);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveOrganization(props, callback) {
  const { apiUrl, token, organizationId } = props;
  request
    .get(`${apiUrl}organizations/${organizationId}`)
    .set("auth-token", token)
    .then((response) => {
      callback(response.body.organization);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveOrganizations(props, callback) {
  const { apiUrl, token } = props;
  request
    .get(`${apiUrl}organizations/`)
    .set("auth-token", token)
    .then((response) => {
      callback(response.body.organization);
    });
}

export function RetrieveOrganizationChildren(props, callback) {
  const { apiUrl, token } = props;
  request
    .get(`${apiUrl}organizations/children`)
    .set("auth-token", token)
    .then((response) => {
      callback(response.body.organizations);
    });
}

export function RetrieveQrBackgrounds(props, callback) {
  const { apiUrl, token } = props;
  request
    .get(`${apiUrl}qrBackgrounds`)
    .set("auth-token", token)
    .then((response) => {
      callback(response.body.qrBackgrounds);
    });
}
export const getDevices = async (props) => {
  const { apiUrl, organizationId, token } = props;
  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: ["{!tuple}device.status:*"],
      sort: "",
      start: 0,
    },
    limit: 1000,
  };
  const devices = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch Device, please contact system administrator.",
      };
    });

  return devices;
};
