import { formatClassifications } from "../../utils/helpers";

export const reducers = {
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  updateOrganization: (state, action) => {
    state.mobileRoles = action.payload.rolesMap?.mobile || state.mobileRoles;
    state.organization = action.payload;
  },
  updateChildOrganizations: (state, action) => {
    state.childOrganizations = action.payload;
  },
  updateFacilities: (state, action) => {
    state.facilities = action.payload;
  },
  updateProducts: (state, action) => {
    state.products = action.payload;
  },
  updateFacility: (state, action) => {
    state.facilities[action.payload.facilityId] = action.payload;
  },
  updateBatches: (state, action) => {
    state.batches = action.payload;
  },
  updateUsersMap: (state, action) => {
    state.usersMap = action.payload;
  },
  addZone: (state, action) => {
    state.zones[action.payload.zoneId] = action.payload;
  },
  editZone: (state, action) => {
    state.zones[action.payload.zoneId] = action.payload;
  },
  removeZone: (state, action) => {
    delete state.zones[action.payload.zoneId];
  },
  updateClassifications: (state, action) => {
    state.classifications = formatClassifications(action.payload);
  },
};
