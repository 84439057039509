import TextField from "@material-ui/core/TextField";

export default function SimpleTextField(props) {
  const {
    autoComplete,
    autoFocus,
    classes,
    color,
    defaultValue,
    disabled = false,
    error = false,
    errorText = "Required Field",
    FormHelperTextProps,
    fullWidth = true,
    helperText,
    id,
    InputLabelProps = {},
    InputProps = {},
    inputProps,
    label = "",
    margin,
    // maxRows,
    // minRows,
    multiline = false,
    name,
    onChange,
    onClick = () => {},
    readOnly = false,
    required,
    size = "medium",
    type = "",
    value,
    variant = "outlined",
  } = props;

  return (
    <TextField
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      classes={classes}
      color={color}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      FormHelperTextProps={FormHelperTextProps}
      fullWidth={fullWidth}
      helperText={error ? errorText : helperText}
      id={id}
      InputLabelProps={{ ...InputLabelProps }}
      inputProps={inputProps}
      InputProps={{
        ...InputProps,
        readOnly,
      }}
      label={label}
      margin={margin}
      // maxRows={maxRows}
      // minRows={minRows}
      multiline={multiline}
      name={name}
      onChange={(event) => {
        onChange(event);
      }}
      onClick={() => onClick()}
      required={required}
      type={type}
      size={size}
      value={value}
      variant={variant}
    />
  );
}
