import { useState, useRef } from "react";
import MapGL, {
  Marker,
  NavigationControl,
  Layer,
  Source,
  FullscreenControl,
  ScaleControl,
} from "react-map-gl";
import Pin from "./pin";
import * as MapStyles from "./styles";
import { radiusCircle, validateLat, validateLong } from "./utils";

const mapToken =
  "pk.eyJ1IjoiZGFuaWVsZG91YW5na2Vzb25lIiwiYSI6ImNqeGYxMTF5YTA5NjQzeW1mMmM4eXIxdWIifQ.jqF1at0nWobKgC3t1OY4kw";

function CentroidRadiusMap(props: any) {
  const [state, setState] = useState({
    viewport: {
      latitude: props.latitude || 33.78695,
      longitude: props.longitude || -84.382744,
      zoom: 12,
      bearing: 0,
      pitch: 0,
    },
    events: {},
    darkMode: false,
  });

  const _updateViewport = (viewport: any) => {
    setState({
      ...state,
      viewport,
    });
  };

  const _logDragEvent = (name: any, event: any) => {
    setState({
      ...state,
      events: {
        ...state.events,
        [name]: event.lngLat,
      },
    });
  };

  const _onMarkerDragStart = (event: any) => {
    _logDragEvent("onDragStart", event);
  };

  const _onMarkerDrag = (event: any) => {
    _logDragEvent("onDrag", event);
  };

  const _onMarkerDragEnd = (event: any) => {
    _logDragEvent("onDragEnd", event);

    props.setCentroid((c: any) => {
      locationRef.current = {
        ...locationRef.current,
        longitude: event.lngLat[0],
        latitude: event.lngLat[1],
      };
      return {
        ...c,
        longitude: {
          ...c.longitude,
          value: event.lngLat[0],
        },
        latitude: {
          ...c.latitude,
          value: event.lngLat[1],
        },
        changesMade: true,
      };
    });
  };

  const { viewport } = state;

  const locationRef = useRef({
    latitude: props.latitude,
    longitude: props.longitude,
    radius: {
      ...radiusCircle(props.longitude, props.latitude, props.radius, {
        // optional geoJSON props, in case we want to do hover states / popups over the radius
        ...props.selectedLocation,
      }),
    },
  });

  return (
    <MapGL
      {...viewport}
      width={props.width || "100%"}
      height={props.height || "100%"}
      style={{
        // border: "rgba(50, 53, 93, 0.514) solid 1px",
        // borderRadius: "4px",
        margin: props.margin || "",
      }}
      mapStyle={
        state.darkMode
          ? "mapbox://styles/mapbox/dark-v10"
          : "mapbox://styles/mapbox/light-v10"
      }
      onViewportChange={_updateViewport}
      mapboxApiAccessToken={mapToken}
      attributionControl={false}
    >
      <Marker
        longitude={
          validateLong.test(props.longitude)
            ? props.longitude
            : locationRef.current.longitude
        }
        latitude={
          validateLat.test(props.latitude)
            ? props.latitude
            : locationRef.current.latitude
        }
        offsetTop={-20}
        offsetLeft={-10}
        draggable={props.readOnly ? false : true}
        onDragStart={_onMarkerDragStart}
        onDrag={_onMarkerDrag}
        onDragEnd={_onMarkerDragEnd}
      >
        <Pin size={15} fill={state.darkMode ? "#A0C46D" : "#32355C"} />
      </Marker>
      <Source
        id="oregons"
        type="geojson"
        data={{
          ...radiusCircle(props.longitude, props.latitude, props.radius, {
            // optional geoJSON props, in case we want to do hover states / popups over the radius
            ...props.selectedLocation,
          }),
        }}
      >
        <Layer
          id="anything"
          type="fill"
          source="oregons"
          paint={{
            "fill-color": state.darkMode ? "#3D5BA9" : "#A0C46D",
            "fill-opacity": 0.4,
          }}
        />
      </Source>
      <div style={MapStyles.fullscreenControlStyle}>
        <FullscreenControl />
      </div>
      <div style={MapStyles.navStyle}>
        <NavigationControl />
      </div>
      <div style={MapStyles.scaleControlStyle}>
        <ScaleControl />
      </div>
      <div style={MapStyles.darkModeStyle}>
        <i
          className={
            state.darkMode
              ? "fa fa-sun darkmode-icon"
              : "fa fa-moon darkmode-icon"
          }
          style={state.darkMode ? { color: "#FFC854" } : { color: "#32355B" }}
          onClick={() => {
            setState({ ...state, darkMode: !state.darkMode });
          }}
        ></i>
      </div>
    </MapGL>
  );
}

export default CentroidRadiusMap;
