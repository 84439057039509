import escapeCharacters from "../../utils/escapedCharacters";
import moment from "moment-timezone";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const showLastEvents = async (props, filters, noLimit = false) => {
  const { token, organizationId, classifications } = props;

  let {
    assetCategories,
    assetIds,
    assetType,
    binLocation,
    endDate,
    lastEvents,
    limit,
    locals,
    locations,
    parents,
    pca,
    sorted,
    start,
    startDate,
    users,
    zones,
  } = filters;

  const classificationFilters = Object.keys(filters || {})
    .filter(
      (filterKey) =>
        classifications.active[filterKey] && filters[filterKey] !== null
    )
    .map((filterKey) => filters[filterKey]);

  const classificationFiltersStrings = classificationFilters?.length
    ? classificationFilters
        ?.map((classArray) => {
          return (
            classArray
              ?.map((childClass) => {
                return `classificationset:${childClass.value}`;
              })
              .flat()
              .join(" OR ") || null
          );
        })
        .join(" AND ")
        .split(" AND ")
    : "";

  let parsedSorted =
    sorted && sorted.length
      ? (() => {
          let obj = {
            id: "",
            order: sorted[0].desc ? "desc" : "asc",
          };
          switch (sorted[0].id) {
            case "timeOfLog":
              obj.id = "time_of_log";
              break;
            case "category":
              obj.id = "category";
              break;
            case "tag":
              obj.id = "tag";
              break;
            case "lastEvent":
              obj.id = "last_event";
              break;
            case "state":
              obj.id = "state";
              break;
            case "facility":
              obj.id = "facility";
              break;
            case "latLong":
              if (sorted[0].desc) {
                obj.id = "latitude desc, longitude asc";
              } else {
                obj.id = "latitude asc, longitude desc";
              }
              obj.latLong = true;
              break;
            case "user":
              obj.id = "last_name";
              break;
            default:
              obj.id = "time_of_log";
              break;
          }
          return obj;
        })()
      : "";

  let sortedString =
    parsedSorted && parsedSorted.id
      ? (() => {
          if (parsedSorted.latLong) {
            return `${parsedSorted.id}, `;
          } else {
            return `${parsedSorted.id} ${parsedSorted.order}, `;
          }
        })()
      : "";

  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  startDate = moment(startDate).isValid()
    ? moment(startDate).startOf("day").utc().format()
    : null;
  endDate = moment(endDate).isValid()
    ? moment(endDate).endOf("day").utc().format()
    : null;

  // escape special characters from string inputs
  let escapedAssetType = assetType ? escapeCharacters(assetType) : null;

  let assetIdString = "";
  if (assetIds && assetIds.length) {
    let assetIdSet = assetIds.map((e) => {
      return `asset_id:${escapeCharacters(e.value)}`;
    });
    assetIdString = assetIdSet.join(" OR ");
  }

  const binLocationsString = binLocation?.length
    ? "(" +
      binLocation
        .map((bin) => {
          return `{!tuple}zone.bin_location:${escapeCharacters(
            bin.value,
            true
          )}`;
        })
        .join(" OR ") +
      ")"
    : "";

  let lastEventsString = "";
  if (lastEvents && lastEvents.length) {
    let lastEventsSet = lastEvents.map((e) => {
      return `last_event:${escapeCharacters(e.value)}`;
    });
    lastEventsString = lastEventsSet.join(" OR ");
  }

  let localsString = "";
  if (locals && locals.length) {
    let localsSet = locals.map((e) => {
      return `state:${escapeCharacters(e.value)}`;
    });
    localsString = localsSet.join(" OR ");
  }

  let locationsString = "";
  if (locations && locations.length) {
    let locationsSet = locations.map((e) => {
      return `facility_id:${e.value?.facilityId || e}`;
    });
    locationsString = locationsSet.join(" OR ");
  }

  const usersString = users?.length
    ? users
        .map((user) => {
          return `app_user_id:${user.value}`;
        })
        .join(" OR ")
    : "";

  let parentsString = "";
  if (parents && parents.length) {
    let parentsSet = parents.map((e) => {
      return `parent_id:${e.value}`;
    });
    locationsString = parentsSet.join(" OR ");
  }

  let zonesString = "";
  if (zones && zones.length) {
    let zonesSet = zones.map((zone) => {
      const { internalZoneType = null, value = "" } = zone;

      // if {zoneId}.internalZoneId === 'processing' then we know the zone is a
      // pZone. If that value is === 'target' then we know the zone is a tZone.

      if (internalZoneType === "processing") {
        return `{!tuple}zone.p_zone_id:${value}`;
      } else if (internalZoneType === "target") {
        return `{!tuple}zone.t_zone_id:${value}`;
      } else {
        return `{!tuple}zone.zone_id:${value}`;
      }
    });
    zonesString = zonesSet.join(" OR ");
    zonesString = "(" + zonesString + ")";
  }

  const assetCategoriesString = assetCategories?.length
    ? assetCategories
        .map((cat) => {
          return `category:${escapeCharacters(cat.value)}`;
        })
        .join(" OR ")
    : "";

  // "-{!tuple}device.status:*" is a negated filter that filters out assets that are devices. The minus sign makes it a negative query.
  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: [
        "-{!tuple}device.status:*",
        `${assetIdString ? assetIdString : ``}`,
        escapedAssetType ? `asset_type:${escapedAssetType}` : ``,
        `${lastEventsString ? lastEventsString : ``}`,
        startDate || endDate
          ? `time_of_log:[${startDate || `*`} TO ${endDate || `*`}]`
          : ``,
        `${parentsString ? parentsString : ``}`,
        `${locationsString ? locationsString : ``}`,
        binLocationsString ? `(${binLocationsString})` : ``,
        `${localsString ? localsString : ``}`,
        usersString,
        assetCategoriesString,
        `${zonesString ? zonesString : ``}`,
        pca ? "flagged:true" : "",
        ...classificationFiltersStrings,
      ],
      sort: `${sortedString}time_of_log desc`,
      start: start && !noLimit ? start : 0,
    },
    limit: noLimit ? 1000 : limit || 25,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });
  return results;
};

export const saveFilterSettings = async (props, filters) => {
  const { token, appUserId } = props;
  const payload = {
    propertiesMap: {
      assetStatusTableSettings: filters,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${appUserId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const retrieveParentIds = async (props) => {
  const { token } = props;
  const results = await fetch(`${apiUrl}assets/assetParents`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((results) => results.json())
    .then((results) => {
      return results;
    });

  return results;
};

export const assets = {
  retrieveParentIds,
  saveFilterSettings,
  showLastEvents,
};
