import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import _ from "lodash";
import {
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  barChildren: {
    textAlign: "end",
  },
  barChildrenContainer: {
    justifyContent: "flex-end",
    padding: ".5rem !important",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  openMenuIcon: {
    paddingLeft: ".5rem",
  },
  paddingTop: {
    padding: ".5rem !important",
  },
}));

type option = {
  id: string;
  header: string;
  checked: boolean;
};

type optionObj = {
  [id: string]: option;
};

type props = {
  options: optionObj;
  setOptions: (options: any) => any;
  handleSave: (options: any, columName?: any) => any;
  limit?: any;
  fixedColumns?: string[];
};

export default function DisplayColumnsDropdown(props: props) {
  const [anchorEl, setAnchorEl] = useState(null);

  // options set on props
  const { options, setOptions, handleSave, limit, fixedColumns } = props;
  const classes = useStyles();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const limitReached =
    limit &&
    Object.keys(options).filter((k: any) => options[k].checked).length >= limit;

  const [limitError, setLimitError] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setLimitError(false);
  };

  return (
    <Grid className={`${classes.barChildren} pointer`} item xs={6} xl={4}>
      <div className="exportIcon"></div>
      <Typography
        variant="subtitle1"
        aria-controls="display-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <div className="exportIcon">
          Display Columns
          <i className={`fas fa-chevron-down ${classes.openMenuIcon}`}></i>
        </div>
      </Typography>
      <Menu
        anchorEl={anchorEl}
        id="display-columns"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {limit ? (
          <MenuItem
            dense
            divider
            disabled
            key="instructions"
            style={{ opacity: 1 }}
          >
            <FormControl error={limitError}>
              <FormLabel component="legend">
                Select up to{" "}
                {fixedColumns && fixedColumns.length
                  ? `${limit - fixedColumns.length} additional columns.`
                  : `${limit} columns.`}
              </FormLabel>
            </FormControl>
          </MenuItem>
        ) : null}
        {!_.isEmpty(options)
          ? Object.keys(options).map((id: string, index: number) => {
              return (
                <MenuItem key={`${id}-${index}`}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <div
                          onClick={() => {
                            if (limitReached && !options[id].checked) {
                              setLimitError(true);
                            } else {
                              setLimitError(false);
                            }
                          }}
                        >
                          <Checkbox
                            checked={options[id].checked || false}
                            color="primary"
                            name={id}
                            disabled={
                              (limitReached && !options[id].checked) ||
                              (fixedColumns && fixedColumns.includes(id))
                            }
                            onChange={(event) => {
                              const name: string = event.target.name;
                              const checked = options[name].checked || false;

                              setOptions((prevState: optionObj) => {
                                //update displayColumnOptions in users properties map and return optional name string
                                handleSave(
                                  {
                                    ...prevState,
                                    [name]: {
                                      ...prevState[name],
                                      checked: !checked,
                                    },
                                  },
                                  name
                                );

                                return {
                                  ...prevState,
                                  [name]: {
                                    ...prevState[name],
                                    checked: !checked,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                      }
                      label={options[id].header}
                    />
                  </FormGroup>
                </MenuItem>
              );
            })
          : null}
      </Menu>
    </Grid>
  );
}
