import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Draggable from "react-draggable";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  image: {
    position: "absolute",
    pointerEvents: "none",
  },
  overViewContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "1rem 0",
  },
  radioText: {
    position: "absolute",
    textAlign: "center",
    userSelect: "none",
  },
}));

export default function FacilityImage(props: any) {
  const classes = useStyles();
  const { facilityImage, setModalState, setState, state = {} } = props;
  const { farPoint = {}, fixedNodes, grid = {}, zeroPoint = {} } = state;
  const [img, setImg] = useState({ height: 0, width: 0 });
  const [facilityImageState, setFacilityImageState] = useState([]);
  const [points, setPoints] = useState({
    zeroPoint: { disablePlunger: false, hasLoaded: false, x: 0, y: 0 },
    farPoint: { disablePlunger: false, hasLoaded: false, x: 0, y: 0 },
  });

  // These values grab the ratio between pixels and the user provided (m) of their facility
  const lengthValue = Math.abs(zeroPoint.x - farPoint.x) / grid.x;
  const heightValue = Math.abs(zeroPoint.y - farPoint.y) / grid.y;

  const handleImageLoad = (event: any) => {
    const height = event.target.clientHeight;
    const width = event.target.clientWidth;
    setImg({ height, width });
  };

  function getY(y: any) {
    // If the start point is in the lower left we hit the if statement, else its in the upper left
    if (zeroPoint.y > farPoint.y) {
      // return (img.height - (img.height - zeroPoint.y)) - y * heightValue + zeroPoint.y ;
      return img.height - (img.height - zeroPoint.y) - y * heightValue;
    } else {
      return y * heightValue + zeroPoint.y;
    }
  }

  useEffect(() => {
    // We are settings fixedNodes as a stateful item in this component.
    // That way the pins will move around in the case a user saves a new value
    setFacilityImageState(fixedNodes);
    setPoints((prevState: any) => ({
      ...prevState,
      farPoint: { ...farPoint, hasLoaded: true },
      zeroPoint: { ...zeroPoint, hasLoaded: true },
    }));
  }, [farPoint, fixedNodes, zeroPoint]);

  return (
    <Grid className={classes.overViewContainer} item xs={12}>
      <div
        style={{
          border: "1px solid red",
          position: "relative",
          height: img.height,
          width: img.width,
        }}
      >
        <img
          alt="facility-layout"
          className={classes.image}
          draggable={false}
          onLoad={handleImageLoad}
          src={facilityImage}
        />

        {/* Im not sure why. But if we move the children below the parent points the Y-axis gets all Foobar */}

        {/* Chilren */}

        {/* Draggable was taking the default height value before the image size could be
            determined. Which is why we have this !==0 catch. We also do want want to 
            display any of the radios if either the zeroPoint or Far/Endpoint are not set
        */}

        {img.height !== 0 &&
        points.zeroPoint.disablePlunger &&
        points.farPoint.disablePlunger
          ? facilityImageState.map((item: any, index: any) => {
              const { mountPoints = [] } = item;
              return mountPoints.map((point: any, index: any) => {
                const { mountPointId, xyz = {} } = point;
                const { x = 0, y = 0 } = xyz;
                return (
                  <Draggable
                    bounds="parent"
                    defaultPosition={{
                      x: x * lengthValue + zeroPoint.x,
                      // y: y * heightValue + zeroPoint.y,
                      y: getY(y),
                    }}
                    disabled
                    key={mountPointId}
                  >
                    <div
                      className="pointer"
                      onClick={() => {
                        setState((prevState: any) => ({
                          ...prevState,
                          selectedMountPointId: point.mountPointId,
                          selectedNode: item,
                        }));
                        setModalState({
                          modalShow: true,
                          title: "Mount Point Information",
                        });
                      }}
                      style={{
                        color: "red",
                        width: "1px",
                        position: "absolute",
                      }}
                    >
                      <i className={`fas fa-broadcast-tower ${classes.radioText}`} />
                    </div>
                  </Draggable>
                );
              });
            })
          : null}

        {/* Start Point */}
        {points.zeroPoint.hasLoaded ? (
          <Draggable
            bounds="parent"
            defaultPosition={{ x: points.zeroPoint.x, y: points.zeroPoint.y }}
            disabled={points.zeroPoint.disablePlunger}
            onStop={(other: any, data: any) => {
              setState((prevState: any) => ({
                ...prevState,
                zeroPoint: { ...prevState.zeroPoint, x: data.x, y: data.y },
              }));
            }}
          >
            <div
              className="box pointer"
              style={{ color: "green", width: "1px" }}
            >
              <i className="fas fa-bullseye" />
              <span className={classes.radioText}>START POINT</span>
            </div>
          </Draggable>
        ) : null}

        {/* End Point */}
        {points.farPoint.hasLoaded ? (
          <Draggable
            bounds="parent"
            defaultPosition={{ x: points.farPoint.x, y: points.farPoint.y }}
            disabled={points.farPoint.disablePlunger}
            onStop={(other: any, data: any) => {
              setState((prevState: any) => ({
                ...prevState,
                farPoint: { ...prevState.farPoint, x: data.x, y: data.y },
              }));
            }}
          >
            <div className="box pointer" style={{ color: "red", width: "1px" }}>
              <i className="fas fa-bullseye" />
              <span className={classes.radioText}>FAR POINT</span>
            </div>
          </Draggable>
        ) : null}
      </div>
    </Grid>
  );
}
