import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

import {
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
} from "@material-ui/core";

import { getAssetByTags } from "./Helpers/NotificationProfileHelpers";
import _ from "lodash";
import { AsyncPaginate } from "react-select-async-paginate";
import { naturalSort } from "../../../utils/naturalSort";

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: "#E6E7E8",
  },
}));
function NotificationProfile(props) {
  const classes = useStyles();
  const [newItemshow, setNewItemShow] = useState(false);
  const {
    actions,
    apiUrl,
    batches = [],
    batchHash,
    category = "",
    events = [],
    eventTrigerIdsToRemove,
    handleModal,
    index,
    label = "",
    newAction = "",
    organization,
    organizationId,
    productHash,
    products = [],
    setEventTrigerIdsToRemove,
    setModalOpen,
    setSelectedItemIndex,
    setTrackedEvents,
    token,
    trackedEvents = [],
    type,
  } = props;
  const { assetCategoriesList = [] } = organization;

  useEffect(() => {
    if (events.length === 0) {
      setNewItemShow(true);
    }
  }, [category, events.length]);

  const assetCategoryOptions = () => {
    let categoryValues = [];
    assetCategoriesList.forEach((category) => {
      categoryValues.push({ label: category.label, value: category.id });
    });
    return categoryValues;
  };

  // Renders out the available actions
  const organizationActions = (actions) =>
    Object.keys(actions)
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .map((element, index) => {
        return <option key={`${element}${index}`}>{element}</option>;
      });

  // Grabs all the batch names
  const batchNames = (batches) => {
    const batchValues = [];
    batches
      .sort((a, b) => {
        const aValue = a.tag ? a.tag : a.name;
        const bValue = b.tag ? b.tag : b.name;
        if (aValue && bValue) {
          return aValue.localeCompare(bValue);
        }
        return null;
      })
      .forEach((batch) => {
        batchValues.push({
          value: batch.tag ? batch.tag : batch.name,
          label: batch.tag ? batch.tag : batch.name,
        });
      });

    return batchValues;
  };

  // Grabs all the product names
  const productNames = (products) => {
    const productValues = [];
    products
      .sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return null;
      })
      .forEach((product) => {
        productValues.push({ label: product.name, value: product.name });
      });

    return productValues;
  };

  const asyncAssetIdSearchLoadOptions = async (
    inputValue = "",
    loadedOptions
  ) => {
    const results = await getAssetByTags(
      { apiUrl, token, organizationId },
      inputValue,
      loadedOptions.length > 0 ? loadedOptions.length : 0
    ).then((results) => {
      if (results.error) {
        return handleModal(true);
      }

      return {
        options:
          results.assets && results.assets.length
            ? results.assets.map((asset) => {
                return {
                  label: asset.tag,
                  value: asset.assetId,
                  batchId: asset.batchId,
                  assetType: asset.assetType,
                };
              })
            : null,
        hasMore: results.count > loadedOptions.length,
      };
    });
    return results;
  };

  const assetSelector =
    type === "asset category" ? (
      <Select
        classNamePrefix="bg-white"
        // defaultInputValue={label}
        onChange={(event) => {
          let newArray = [...trackedEvents];
          if (event !== null) {
            const { label, value } = event;
            newArray[index].label = label;
            newArray[index].id = value;
            setTrackedEvents(newArray);
          }
        }}
        options={assetCategoryOptions().sort((a, b) =>
          naturalSort(a.label, b.label)
        )}
        value={{ value: label, label }}
      />
    ) : (
      <AsyncPaginate
        isSearchable
        debounceTimeout={750}
        defaultOptions
        isClearable
        styles={{
          menuPortal: (styles) => ({ ...styles, zIndex: 99999 }),
        }}
        placeholder={<div>Type to search</div>}
        loadOptions={asyncAssetIdSearchLoadOptions}
        onChange={(event) => {
          let newArray = [...trackedEvents];
          if (event !== null) {
            const { label, value } = event;
            newArray[index].label = label;
            newArray[index].id = value;
            setTrackedEvents(newArray);
          } else {
            newArray[index].label = "";
            newArray[index].id = "";
            setTrackedEvents(newArray);
          }
        }}
        value={{ value: label, label }}
      />
    );

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <Typography>
          {label.length === 0 ? "New Notification" : label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Form.Row className="w-75">
          <Form.Group className="w-100">
            <Form.Label className="w-100">
              {`Select ${_.startCase(type)}`}
            </Form.Label>

            {/* Item Selector */}
            {type === "asset tag" || type === "asset category" ? (
              assetSelector
            ) : (
              <Select
                classNamePrefix="bg-white"
                defaultInputValue={label}
                onChange={(event) => {
                  const newArray = [...trackedEvents];
                  const { value } = event;
                  newArray[index].label = value;
                  newArray[index].id =
                    type === "batch"
                      ? batchHash[value].batchId
                      : productHash[value].productId;
                  setTrackedEvents(newArray);
                }}
                options={
                  type === "batch"
                    ? batchNames(Object.values(batches)).sort((a, b) =>
                        naturalSort(a.label, b.label)
                      )
                    : productNames(Object.values(products)).sort((a, b) =>
                        naturalSort(a.label, b.label)
                      )
                }
                value={{ value: label, label }}
              />
            )}

            {/* Radios */}
            <div className="mt-1 ml-3 mb-3">
              {type === "asset tag" || type === "asset category" ? (
                <>
                  <Form.Check
                    checked={type === "asset category"}
                    id={`assetCategory${index}`}
                    inline
                    label="Asset Category"
                    name={`track-type${index}`}
                    onChange={() => {
                      const newArray = [...trackedEvents];
                      newArray[index].type = "asset category";
                      newArray[index].label = "";
                      setTrackedEvents(newArray);
                    }}
                    type="radio"
                  />
                  <Form.Check
                    checked={type === "asset tag"}
                    id={`assetTag${index}`}
                    inline
                    label="Asset Tag"
                    name={`track-type${index}`}
                    onChange={() => {
                      const newArray = [...trackedEvents];
                      newArray[index].type = "asset tag";
                      newArray[index].label = "";
                      setTrackedEvents(newArray);
                    }}
                    type="radio"
                  />
                </>
              ) : (
                <>
                  <Form.Check
                    checked={type === "batch"}
                    id={`batches${index}`}
                    inline
                    label="Batches"
                    name={`track-type${index}`}
                    onChange={() => {
                      const newArray = [...trackedEvents];
                      newArray[index].type = "batch";
                      newArray[index].label = "";
                      setTrackedEvents(newArray);
                    }}
                    type="radio"
                  />
                  <Form.Check
                    checked={type === "product"}
                    id={`products${index}`}
                    inline
                    label="Products"
                    name={`track-type${index}`}
                    onChange={() => {
                      const newArray = [...trackedEvents];
                      newArray[index].type = "product";
                      newArray[index].label = "";
                      setTrackedEvents(newArray);
                    }}
                    type="radio"
                  />
                </>
              )}
            </div>
            <div className="w-100 pl-4">
              {/* Users saved actions */}
              {events.map((element, eventsIndex) => {
                return (
                  <Form.Group
                    className="d-flex"
                    key={`${element}${eventsIndex}`}
                  >
                    <Form.Control
                      as="select"
                      className="bg-white custom-select w-75"
                      onChange={(event) => {
                        const newArray = [...trackedEvents];
                        const item = newArray[index].events[eventsIndex];
                        item.event = event.target.value;
                        item.eventTriggerId = "";

                        setTrackedEvents(newArray);
                      }}
                      required
                      value={element.event}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {organizationActions(actions)}
                    </Form.Control>

                    {/* Plus button only shows up to allow user to add a new item
                              Also only shows up on last item in the array*/}

                    {!newItemshow && events.length - 1 === eventsIndex ? (
                      <div
                        className="ml-2 my-auto pointer"
                        onClick={() => {
                          setNewItemShow(true);
                        }}
                      >
                        <i className="fas fa-plus" />
                      </div>
                    ) : null}

                    {/* Minus will not show if it is the last item in the array */}
                    {events.length === 1 ? null : (
                      <div
                        className="ml-2 my-auto pointer"
                        onClick={() => {
                          const newActions = [...trackedEvents];
                          const removedAction =
                            newActions[index].events[eventsIndex];
                          const removedActionsEventTriggerId =
                            removedAction.eventTriggerId;
                          const newEventTrigerIdsToRemove = [
                            ...eventTrigerIdsToRemove,
                          ];

                          // This handles deleting the eventTargetId associated with removing
                          // an action from a notification. This will store the eventTriggerId
                          // and remove the id's when the onSubmit fires.
                          newEventTrigerIdsToRemove.push(
                            removedActionsEventTriggerId
                          );
                          setEventTrigerIdsToRemove(newEventTrigerIdsToRemove);

                          // This handles the user removes an action from a notifcation
                          newActions[index].events.splice(eventsIndex, 1);
                          setTrackedEvents(newActions);
                        }}
                      >
                        <i className="fas fa-minus" />
                      </div>
                    )}
                  </Form.Group>
                );
              })}

              {/* Section for adding a new action */}
              {newItemshow ? (
                <Form.Group className="d-flex">
                  <Form.Control
                    as="select"
                    className="bg-white custom-select shadow w-75"
                    onChange={(event) => {
                      const newArray = [...trackedEvents];
                      newArray[index].newAction = event.target.value;
                      setTrackedEvents(newArray);
                    }}
                    value={newAction}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {organizationActions(actions)}
                  </Form.Control>

                  {/* Plus Button */}
                  <div
                    className="ml-2 my-auto pointer"
                    onClick={(event) => {
                      const newArray = [...trackedEvents];
                      const { newAction = "" } = newArray[index];
                      if (newAction.length !== 0) {
                        // Pushes the selected action to the events array
                        newArray[index].events.push({
                          event: newAction,
                          eventTriggerId: "",
                        });

                        // Clears out the newAction field
                        newArray[index].newAction = "";

                        setTrackedEvents(newArray);
                      }
                    }}
                  >
                    {/* Plus button is muted if no action is selected */}
                    <i
                      className={`fas fa-plus ${
                        newAction.length !== 0 ? "" : "text-muted"
                      }`}
                    />
                  </div>

                  {/* Minus button will not show up if there are no events available for this notification profile */}
                  {trackedEvents[index].events.length !== 0 ? (
                    <div
                      className="ml-2 my-auto pointer"
                      onClick={() => {
                        if (trackedEvents[index].events.length !== 0) {
                          setNewItemShow(false);
                        }
                      }}
                    >
                      <i
                        className={`fas fa-minus ${
                          trackedEvents[index].events.length !== 0
                            ? ""
                            : "text-muted"
                        }`}
                      />
                    </div>
                  ) : null}
                </Form.Group>
              ) : null}
              <Button
                color="secondary"
                onClick={() => {
                  setModalOpen({ open: true, type: type });
                  setSelectedItemIndex(index);
                }}
              >
                Delete
              </Button>
            </div>
          </Form.Group>
        </Form.Row>
      </AccordionDetails>
    </Accordion>
  );
}

export default NotificationProfile;
