import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep, isEqual } from "lodash";
import {
  updateWidgetSettings,
  updateUserPermissions,
} from "../../../globalStore/slices/user/userSlice";
import { showAlert } from "../../../globalStore/slices/alert/alertSlice";

import Notifications from "./Notifications";
import NotificationSettings from "./NotificationSettings";
import { RetrieveNotifications } from "../../../utils/apiCalls";
import {
  CreateEventTrigger,
  DeleteEventTrigger,
  EditUserNotification,
} from "../api";
import { EditExistingUser } from "../../../utils/apiCalls";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import Badge from "@material-ui/core/Badge";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";

import "./NotificationStyling.css";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#A0C46D",
    border: "2px solid #A0C46D",
    padding: "0 4px",
  },
}))(Badge);

function NotificationsByEventType(props) {
  const [eventTrigerIdsToRemove, setEventTrigerIdsToRemove] = useState([]);
  const [page, setPage] = useState("notifications");
  const [selectedItemIndex, setSelectedItemIndex] = useState("");

  const [usersNotifications, setUsersNotifications] = useState([]);
  const [newNotificationCount, setNewNotificationCount] = useState([]);

  const {
    apiUrl,
    batches,
    appUserType,
    organization,
    organizationId,
    products,
    timeZone,
    token,
    useWidget = false,
    userId,
  } = props;

  const userPropertiesMap = cloneDeep(
    useSelector((state) => state.user.userPropertiesMap),
    isEqual
  );

  const userPermissions = useSelector(
    (state) => state.user.userPermissions,
    isEqual
  );

  const [emailOptOut, setEmailOptOut] = useState(
    userPermissions.includes("emailOptOut")
  );

  const dispatchGlobal = useDispatch();

  const usersNotificationsSettings =
    userPropertiesMap.propertiesMap?.widgetSettings?.notifications?.trackedItems?.filter(
      (profile) => {
        if (appUserType === "Asset") {
          return profile.type.includes("asset");
        } else if (appUserType === "Product") {
          return !profile.type.includes("asset");
        } else {
          return profile;
        }
      }
    ) || [];

  const { eventTypesMap = {} } = organization;

  const [trackedEvents, setTrackedEvents] = useState(
    usersNotificationsSettings
  );

  const userTimeZone = timeZone.value ? timeZone.value : moment.tz.guess();

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    RetrieveNotifications({ apiUrl, token, userId }, (notifications) => {
      const productNotifications = [];
      const assetNotifications = [];

      // This sorts out what notifications are from a user thats either Asset or Product
      notifications.forEach((notification) => {
        const { propertiesMap = {} } = notification;
        const { batchId } = propertiesMap;
        if (batchId) {
          productNotifications.push(notification);
        } else {
          assetNotifications.push(notification);
        }
      });

      let assetsToBeSorted = [];

      if (appUserType === "Asset") {
        assetsToBeSorted = assetNotifications;
      } else if (appUserType === "Product") {
        assetsToBeSorted = productNotifications;
      } else assetsToBeSorted = assetNotifications.concat(productNotifications);

      sortNotifications(assetsToBeSorted);
    });
  }, [userId, apiUrl, appUserType, token]);

  function sortNotifications(notifications) {
    const newArray = [];
    let newNotificationCount = 0;

    notifications.forEach((notification) => {
      const { status = "" } = notification;

      if (status !== "dismissed") {
        if (status === "sent") {
          newNotificationCount++;
        }
        newArray.push(notification);
      }
      return null;
    });

    setNewNotificationCount(newNotificationCount);
    setUsersNotifications(newArray);
  }

  function handleSubmit() {
    // New Array holds newly added events
    const newArray = trackedEvents
      ? [...trackedEvents, ...usersNotificationsSettings]
          .filter((event) => event.isNew)
          .map((event) => {
            return {
              ...event,
              isNew: null,
            };
          })
      : [];

    const newPropertiesMap = { ...userPropertiesMap.propertiesMap };

    const widgetSettings = newPropertiesMap.widgetSettings
      ? newPropertiesMap.widgetSettings
      : {};

    let notifications = widgetSettings.notifications
      ? widgetSettings.notifications
      : {};

    const concatArrays = notifications.trackedItems?.length
      ? notifications.trackedItems.concat(newArray)
      : newArray;
    notifications.trackedItems = concatArrays;

    // Parses through trackedEvents and checks if newAction is present.
    // If so, it pushes any newAction's into the events array.
    concatArrays.forEach((trackedEvent, index) => {
      let { events = [], newAction = "" } = trackedEvent;

      if (newAction.length !== 0) {
        concatArrays[index].events.push({
          event: newAction,
          eventTriggerId: "",
        });
        concatArrays[index].newAction = "";
      }

      // This will iterate through each action and verify that there is no
      // eventTriggerId associated with the action. If not, it will create a eventTrigger.
      // And then append the eventTriggerId to the action object to show that the notification has been
      // dealt with.

      events.forEach((item, eventsIndex) => {
        const { eventTriggerId: itemEventTriggerId = "" } = item;
        if (itemEventTriggerId.length === 0) {
          CreateEventTrigger({ ...props }, trackedEvent, item.event).then(
            (response) => {
              const { eventTriggerId } = response;
              concatArrays[index].events[eventsIndex].eventTriggerId =
                eventTriggerId;
              // This is for users who do not have widgetSettings yet
              if (newPropertiesMap.widgetSettings === undefined) {
                newPropertiesMap.widgetSettings = {
                  notifications: { trackedItems: concatArrays },
                };
              }
              EditExistingUser(
                { ...props },
                { propertiesMap: newPropertiesMap },
                (response) => {
                  dispatchGlobal(
                    updateWidgetSettings({
                      widget: "notifications",
                      settings: { trackedItems: concatArrays },
                    })
                  );
                  return null;
                }
              );
            }
          );
        }
      });
    });

    // This is for users who do not have widgetSettings yet
    if (
      newPropertiesMap.widgetSettings === undefined ||
      newPropertiesMap.widgetSettings.notifcations === undefined
    ) {
      newPropertiesMap.widgetSettings = {
        notifications: { trackedItems: concatArrays },
      };
    }

    // This checks for any removed actions from existing notification profiles.
    // If present, this will delete the eventTriggerId's so the user wont be pinged
    // when the trigger fires off.
    if (eventTrigerIdsToRemove.lengh !== 0) {
      eventTrigerIdsToRemove.forEach((itemToBeDeleted) => {
        DeleteEventTrigger({ ...props }, itemToBeDeleted).then((response) => {
          return null;
        });
      });
    }

    const body = {
      permissions: Array.from(userPermissions),
    };

    // This handles the userPermissions.
    if (!emailOptOut) {
      body.permissions = body.permissions.filter(
        (permission) => permission !== "emailOptOut"
      );
    } else {
      body.permissions.push("emailOptOut");
    }

    EditExistingUser({ ...props }, body, (res) => {
      dispatchGlobal(updateUserPermissions(body.permissions));

      return null;
    });
    setTrackedEvents(concatArrays);
    setPage("notifications");
    dispatchGlobal(
      showAlert({ type: "success", text: "Notifcations settings updated." })
    );
  }

  function handleModal(error = false) {
    dispatchGlobal(
      showAlert({
        type: error ? "error" : "success",
        text: error ? "There was an error" : "Settings Saved",
      })
    );
  }

  function handleNotificationStatus(userNotificationId, status) {
    EditUserNotification({ ...props }, userNotificationId, status).then(() => {
      RetrieveNotifications({ ...props }, (notifications) => {
        const productNotifications = [];
        const assetNotifications = [];

        // This sorts out what notifications are from a user thats either Asset or Product
        notifications.forEach((notification) => {
          const { propertiesMap = {} } = notification;
          const { batchId } = propertiesMap;
          if (batchId) {
            productNotifications.push(notification);
          } else {
            assetNotifications.push(notification);
          }
        });

        let assetsToBeSorted = [];

        if (appUserType === "Asset") {
          assetsToBeSorted = assetNotifications;
        } else if (appUserType === "Product") {
          assetsToBeSorted = productNotifications;
        } else
          assetsToBeSorted = assetNotifications.concat(productNotifications);
        setPage("notifications");
        sortNotifications(assetsToBeSorted);
      });
    });
  }

  function handleDelete() {
    const newArray = [...trackedEvents];
    const newPropertiesMap = { ...userPropertiesMap.propertiesMap };
    const { widgetSettings = {} } = newPropertiesMap;
    const { notifications = {} } = widgetSettings;
    const selectedItemEvents = newArray[selectedItemIndex].events || [];

    // We first parse through each event and delete the eventTriggers
    selectedItemEvents.forEach((event) => {
      const { eventTriggerId } = event;
      DeleteEventTrigger({ ...props }, eventTriggerId).then((response) => {
        return null;
      });
    });

    // We then remove the item from the users widgetSettings/notifications/trackedItems
    newArray.splice(selectedItemIndex, 1);

    // Then set the modified array to state
    setTrackedEvents(newArray);
    notifications.trackedItems = newArray;

    // Then update the user
    EditExistingUser({ ...props }, { propertiesMap: newPropertiesMap }, () => {
      dispatchGlobal(
        updateWidgetSettings({
          widget: "notifications",
          settings: { trackedItems: newArray },
        })
      );
      return null;
    });
  }

  function handleClickOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  const body = (
    <div className={`notification-widget ${useWidget ? "min-width" : ""}`}>
      <div className="container">
        <div className="row">
          <div
            className="col-md-12"
            style={{
              display: "flex",
              flexDirection: useWidget ? 'row-reverse' : "inherit",
              justifyContent: "space-between",
            }}
          >
            {/* This handles whether the icon in the upper right is a gear or a "x" */}
            {page === "notifications" ? (
              <i
                className="fa fa-cog fa-lg pointer widget-cog"
                onClick={() => {
                  setPage("settings");
                }}
                style={{ cursor: "pointer", padding: "10px" }}
              />
            ) : (
              <i
                className="fa fa-times fa-lg pointer widget-cog"
                onClick={() => {
                  setPage("notifications");
                }}
                style={{ cursor: "pointer", padding: "10px" }}
              />
            )}
            {/* {page === "settings" ? 
         : null} */}
            <div className="d-flex pointer widget-header">
              {page === "notifications" ? (
                <h5 style={{ fontWeight: 700 }}>Notifications</h5>
              ) : (
                <h5 style={{ fontWeight: 700 }}>Customize Notifications</h5>
              )}
              {page === "notifications" ? (
                <div className="ml-2 text-white mb-2 rounded-circle text-center asset-count font-weight-bold">
                  <span>{newNotificationCount}</span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="h-100 pt-3 col-md-12">
            <div className="notification-alerts overflow-auto">
              {page === "notifications" ? (
                <Notifications
                  events={usersNotifications}
                  dismissNotification={handleNotificationStatus}
                  timeZone={userTimeZone}
                />
              ) : (
                <NotificationSettings
                  actions={eventTypesMap}
                  apiUrl={apiUrl}
                  batches={batches}
                  emailOptOut={emailOptOut}
                  eventTrigerIdsToRemove={eventTrigerIdsToRemove}
                  handleDelete={handleDelete}
                  handleModal={handleModal}
                  onSubmit={handleSubmit}
                  organization={organization}
                  organizationId={organizationId}
                  products={products}
                  setEmailOptOut={setEmailOptOut}
                  setEventTrigerIdsToRemove={setEventTrigerIdsToRemove}
                  setSelectedItemIndex={setSelectedItemIndex}
                  setTrackedEvents={setTrackedEvents}
                  token={token}
                  trackedEvents={trackedEvents}
                  appUserType={appUserType}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {useWidget ? (
        <>
          <StyledBadge
            aria-controls="simple-menu"
            aria-haspopup="true"
            badgeContent={newNotificationCount}
            // color="secondary"
          >
            <span style={{ cursor: "pointer" }} onClick={handleClickOpen}>
              <i className="fas fa-bell fa-lg" style={{ color: "#414348" }} />
            </span>
          </StyledBadge>
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            PaperProps={{
              style: {
                backgroundColor: "white",
              },
            }}
            id="simple-menu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            onClose={() => {
              setAnchorEl(null);
              // Timeout is to give the close modal animation time to finish up
              setTimeout(function () {
                setPage("notifications");
              }, 500);
            }}
            open={Boolean(anchorEl)}
            aria-labelledby="filters"
          >
            {body}
          </Menu>
        </>
      ) : (
        body
      )}
    </>
  );
}

export default NotificationsByEventType;
