import escapeCharacters from "../../../../utils/escapedCharacters";

export const searchAssets = async (
  props: any,
  input: string,
  start: number = 0,
  limit: number = 300
) => {
  const { apiUrl, token = "", organizationId = "" } = props;
  let escapedInput = escapeCharacters(input);

  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: [
        "-{!tuple}device.status:*",
        escapedInput ? `tag:${escapedInput}*` : ``,
      ],
      sort: `tag asc`,
      start: start,
    },
    limit: limit,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {
        error:
          "Failed to fetch asset data, please contact system administrator.",
      };
    });

  return results;
};
