import Button from "@material-ui/core/Button";

export default function ConfirmationModalContent(props) {
  const { handleSubmit, handleCancel, content } = props;

  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col-md-12 mb-4 text-center">{content}</div>
        <div className="col-md-6 text-center">
          <Button onClick={() => handleSubmit()}>
            Yes
            <i className="far fa-check-circle pl-2 text-success" />
          </Button>
        </div>
        <div className="col-md-6 text-center">
          <Button onClick={() => handleCancel()} type="button">
            No
            <i className="far fa-times-circle pl-2 text-danger" />
          </Button>
        </div>
      </div>
    </div>
  );
}