import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CQRImagePreview from "./CQRImagePreview";
import CQRInformationCard from "./CQRInformation";
import CQRTemplate from "./CQRTemplate";
import Grid from "@material-ui/core/Grid";
import RadioList from "../../components/Forms/FieldTypes/RadioList";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: ".5rem 1rem",
  },
  textField: {
    width: "100%",
  },
}));

export default function BuildCQR(props) {
  const { changePage, consoleRole, state, setState } = props;
  const {
    eccLevel = "L",
    format = "png",
    magnification,
    selectCQRLayout,
    version = "10",
  } = state;
  const classes = useStyles();
  const [modalShow, setModalShow] = useState(false);

  return (
    <Grid container>
      {/* In this section you build your CQR */}
      <Grid className={classes.root} item xs={7}>
        <Card>
          <CardContent>
            {/* Basic CQR Options */}
            <Grid container>
              <Grid className={classes.root} item xs={4}>
                {/* ECC Level */}
                <RadioList
                  label="ECC Level "
                  name="eccLevel"
                  options={[
                    { label: "Level L - 7%", value: "L" },
                    { label: "Level M - 15%", value: "M" },
                    { label: "Level Q - 25%", value: "Q" },
                    { label: "Level H - 30%", value: "H" },
                  ]}
                  onChange={(event) => {
                    setState((prevState) => ({
                      ...prevState,
                      eccLevel: event.target.value,
                    }));
                  }}
                  toolTip="Error Correction Code Level: Level of damage can be corrected for CQR codes. "
                  value={eccLevel}
                />
              </Grid>

              <Grid className={classes.root} item xs={8}>
                <Grid container>
                  {/* Size/Magnification */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      QR Size
                    </Typography>
                    <Slider
                      aria-labelledby="Size Magnification"
                      defaultValue={5}
                      id="magnification"
                      marks
                      max={10}
                      min={1}
                      onChange={(event, newValue) => {
                        setState((prevState) => ({
                          ...prevState,
                          magnification: newValue,
                        }));
                      }}
                      step={1}
                      value={magnification}
                      valueLabelDisplay="auto"
                    />
                  </Grid>

                  {/* Version */}
                  <Grid className={classes.root} item xs={6}>
                    <Typography variant="subtitle1">QR Version</Typography>
                    <SimpleTextField
                      className={classes.textField}
                      InputProps={{
                        inputProps: {
                          max: 40,
                          min: 1,
                        },
                      }}
                      name="version"
                      onChange={(event) => {
                        setState((prevState) => ({
                          ...prevState,
                          version: event.target.value,
                        }));
                      }}
                      type="number"
                      value={version}
                      variant="outlined"
                    />
                    {/* <RequiredField label="Requires Number (1 - 40)" /> */}
                  </Grid>

                  {/* Image Format */}
                  <Grid className={classes.root} item xs={6}>
                    <RadioList
                      label="Image Format"
                      name="format"
                      options={[
                        { value: "png", label: "PNG" },
                        { value: "jpg", label: "JPG" },
                      ]}
                      onChange={(event) => {
                        setState((prevState) => ({
                          ...prevState,
                          format: event.target.value,
                        }));
                      }}
                      value={format}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* CQR Templates are dealt with here */}
            <CQRTemplate
              {...props}
              apiUrl={props.apiUrl}
              consoleRole={consoleRole}
              modalShow={modalShow}
              setModalShow={setModalShow}
              setState={setState}
              token={props.token}
            />
          </CardContent>
        </Card>
      </Grid>

      {/* In this section you have the information and image preview */}
      <Grid item xs={5}>
        {/* Information */}
        <CQRInformationCard {...props} />

        {/* Image Preview */}
        <CQRImagePreview {...props} />
      </Grid>

      <div className="col-md-12 text-center d-flex flex-row-reverse my-3">
        <button
          className="cqrnext border-0"
          style={{ marginBottom: 75 }}
          onClick={() => {
            selectCQRLayout.value === "addNew"
              ? setModalShow(true)
              : changePage();
          }}
          type="submit"
        >
          <i className="fas fa-arrow-circle-right fa-2x"></i>
        </button>
      </div>
    </Grid>
  );
}
