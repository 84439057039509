import NotificationBar from "./NotificationBar";

function Notifications(props) {
  const { events = [], dismissNotification, timeZone } = props;

  return events.map((element, index) => {
    return (
      <NotificationBar
        {...element}
        dismissNotification={dismissNotification}
        key={index}
        timeZone={timeZone}
      />
    );
  });
}

export default Notifications;
