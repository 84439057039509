import { Device } from "../interface";

export async function searchDeviceAssets(
  props: any,
  joined_device_asset_ids: string = "",
  start: number = 0,
  limit: number = 300
) {
  const { apiUrl, token, organizationId } = props;
  const payload = {
    solrQuery: {
      q: `current_owner_id:${organizationId}`,
      fq: joined_device_asset_ids,
      sort: `time_of_log asc, tag desc`,
      start: start,
    },
    limit: limit,
  };
  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
}

export async function buildDeviceAssetsMap(props: any, deviceList: [Device]) {
  const deviceAssetIds =
    deviceList.length > 0
      ? deviceList
          .map((d: any) => {
            if (d.assetId) {
              return `parent_id:${d.assetId}`;
            }
            return null;
          })
          .filter((el: any) => el !== null)
          .join(" OR ")
      : null;

  if (!deviceAssetIds) {
    return {};
  }

  const deviceAssetsMap = await searchDeviceAssets(props, deviceAssetIds).then(
    (res) => {
      if (res.error) {
        return { error: "Error fetching device assets." };
      }
      let deviceAssetsMap: any = {};
      res.assets.forEach((ass: any) => {
        if (!deviceAssetsMap[ass.parentId]) {
          deviceAssetsMap[ass.parentId] = [];
        }
        deviceAssetsMap[ass.parentId].push(ass);
      }, {});

      return {
        ...deviceAssetsMap,
      };
    }
  );
  return deviceAssetsMap;
}

export const unassignDevices = async (
  props: any,
  selectedDevices: any = {},
  device_asset_map: any = {}
) => {
  const { apiUrl, token } = props;
  const deviceAssets: any = Object.keys(device_asset_map)
    .filter((d) => selectedDevices[d])
    .map((d) => {
      return {
        deviceId: d,
        associatedAssets: device_asset_map[d],
      };
    });

  const iterateAndFetch = deviceAssets.map(async (d: any) => {
    const unassignAssets = d.associatedAssets.map(async (ass: any) => {
      const unassign = await fetch(`${apiUrl}assets/${ass.assetId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify({ parentId: null }),
      })
        .then((res) => res.json())
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return {
            error: err,
          };
        });
      return unassign;
    });

    const results = await Promise.all(unassignAssets).then(
      async (completed) => {
        // if any failed, then return error message from backend
        if (completed.some((res: any) => res.error)) {
          return completed.find((res: any) => res.error);
        }
        const setStatus = await fetch(`${apiUrl}assets/${d.deviceId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "auth-token": token,
          },
          body: JSON.stringify({ device: { status: "Unassigned" } }),
        })
          .then((res) => res.json())
          .then((res) => {
            return res;
          })
          .catch((err) => {
            return {
              error: err,
            };
          });
        return setStatus;
      }
    );
    return results;
  });

  return await Promise.all(iterateAndFetch).then((completed) => {
    // if any failures, return error message from backend
    if (completed.some((res: any) => res.error)) {
      return completed.find((res: any) => res.error);
    }
    return completed;
  });
};
