import circle from "@turf/circle";

export const radiusCircle = (longitude, latitude, radius, properties) => {
  var center = [longitude, latitude];
  var options = {
    steps: 64,
    units: "kilometers",
    properties: properties || {},
  };
  // tslint:disable-next-line
  return circle(center, radius, options);
};
