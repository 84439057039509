import { useEffect, useState } from "react";
import ConfigureZoneTable from "./ConfigureZoneTable/ConfigureZoneTable";
import { getRadios, getFixedNodesByZone } from "./api";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import EditNode from "./EditNode/EditNode";
import ViewNodeInformation from "./ViewNodeInformation/ViewNodeInformation";

export default function ConfigureZone(props: any) {
  const { handleAlertModal, setView, view } = props;
  const { zoneInfo = {} } = view;
  const { name = "", zoneId = "" } = zoneInfo;
  const [state, setState] = useState({
    nodes: [],
    selectedMountPointId:null,
    selectedNode: {},
  });

  useEffect(() => {
    getFixedNodesByZone(props, zoneId).then((res: any) => {
      const { fixedNodes = [] } = res;
      let fixedNodeHashMap: any = {};

      // This lets us know what fixedNodes are available for this
      // particular zone.
      fixedNodes.forEach((item: any) => {
        fixedNodeHashMap[item.name] = { ...item, children: {} };
      });

      getRadios(props, zoneId).then((res) => {
        const { radios = [] } = res;
        const nodesArray: any = [];

        // Divvy's up the radios to the appropiate fixedNode
        radios.forEach((radio: any) => {
          if (fixedNodeHashMap[radio.node]) {
            fixedNodeHashMap[radio.node].children[radio.radioId] = radio;
          }
        });

        // Then turn it into an array for the table
        Object.keys(fixedNodeHashMap)
          .sort()
          .forEach((item: any) => {
            nodesArray.push({
              node: item,
              children: fixedNodeHashMap[item].children,
              ...fixedNodeHashMap[item],
            });
          });
        setState((prevState: any) => ({
          ...prevState,
          nodes: nodesArray,
        }));
      });
    });
  }, [props, zoneId]);

  const [dialog, setDialog] = useState<any>({
    dialogTitle: "",
    dialogType: "",
    dialogShow: false,
  });
  const { dialogTitle, dialogShow, dialogType } = dialog;

  const dialogClose = () =>
    setDialog({
      ...dialog,
      dialogShow: false,
      dialogTitle: "",
    });

  const switchDialog = (item: any) => {
    switch (item) {
      case "Edit":
        return (
          <EditNode
            {...props}
            dialog={dialog}
            dialogClose={dialogClose}
            handleAlertModal={handleAlertModal}
            setState={setState}
            showTitle={false}
            state={state}
          />
        );
      case "View":
        return <ViewNodeInformation {...props} state={state} />;
      default:
        return;
    }
  };

  return (
    <div className="container-fluid mt-3">
      <ModalDialog
        content={switchDialog(dialogType)}
        handleClose={dialogClose}
        maxWidth="md"
        open={dialogShow}
        title={dialogTitle}
      />
      <div className="row">
        <div className="col-12 col-md-6 text-md-left text-center">
          <h3>{name}</h3>
        </div>
        <div className="col-12 col-md-6 text-md-right text-center">
          <span
            className="exportIcon mr-2"
            style={{ cursor: "pointer" }}
            onClick={() =>
              setView((prevState: any) => ({
                ...prevState,
                viewConfigureZone: false,
                zoneInfo: {},
              }))
            }
          >
            <i className="fas fa-arrow-left mr-2"> </i>
            Back to Zones
          </span>
        </div>
        <div className="col-md-12">
          <ConfigureZoneTable
            setDialog={setDialog}
            setState={setState}
            state={state}
          />
        </div>
      </div>
    </div>
  );
}
