import { useState } from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import AssetContent from "./AssetContent";
import GenerateCQRContent from "./GenerateCQRContent";

const useStyles = makeStyles((theme) => {
  return {
    header: {
      margin: ".5rem 0",
      borderBottom: `1px solid ${theme.palette.typography.light}`,
    },
  };
});

function AddAssetModal(props) {
  const { handleAlertModal, handleClose, modalOpen, onSubmit } = props;
  const [generateCQR, setGenerateCQR] = useState(false);
  const [newAsset, setNewAsset] = useState({});
  const classes = useStyles();

  function closeModal() {
    handleClose();
    setTimeout(() => {
      setGenerateCQR(false);
    }, 300);
  }

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      className="material-modal"
      fullWidth={true}
      maxWidth="md"
      onClose={() => {
        closeModal();
      }}
      open={modalOpen}
      onExit={() => {
        closeModal();
      }}
    >
      <DialogTitle className={classes.header} id="alert-dialog-title">
        {generateCQR ? "Generate CQR" : "Add Asset"}
      </DialogTitle>
      {generateCQR ? (
        <GenerateCQRContent
          asset={newAsset}
          setGenerateCQR={setGenerateCQR}
          closeModal={closeModal}
        />
      ) : (
        <AssetContent
          {...props}
          closeModal={closeModal}
          handleAlertModal={handleAlertModal}
          parentProps={props}
          onSubmit={onSubmit}
          setGenerateCQR={setGenerateCQR}
          setNewAsset={setNewAsset}
        />
      )}
    </Dialog>
  );
}

export default AddAssetModal;
