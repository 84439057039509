import { useMemo } from "react";

import EnhancedTable from "./components/EnhancedTable";
import { ControlledTableInterface } from "./interface";
const ControlledTable = (props: ControlledTableInterface) => {
  const columns = useMemo(() => props.columns, [props.columns]);
  const data = useMemo(() => props.data, [props.data]);
  return (
    <EnhancedTable
      // data, columns, state, setState, saveFilters function
      columns={columns}
      data={data}
      setState={props.setState}
      state={props.state}
      saveFilterSettings={props.saveFilterSettings}
      // controlled props
      defaultSort={props.defaultSort}
      controlledPageCount={props.controlledPageCount || 0}
      controlledPageSize={props.controlledPageSize || 0}
      controlledCount={props.controlledCount || 0}
      controlledPageIndex={props.controlledPageIndex || 0}
      // select checkboxes
      isSelect={props.isSelect}
      selectAll={props.selectAll}
      onSelect={props.onSelect}
      clearSelected={props.clearSelected}
      // other
      tableHeader={props.tableHeader}
      stickyHeader={props.stickyHeader}
      toolbarChildren={props.toolbarChildren}
      language={props.language || "en"}
      globalFilterLabel={props.globalFilterLabel}
      defaultColumnOrder={props.defaultColumnOrder}
      onColumnDrop={props.onColumnDrop}
      totalLabel={props.totalLabel}
      loading={props.loading}
    />
  );
};

export default ControlledTable;
