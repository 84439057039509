import { Modal } from "react-bootstrap";
import Loading from "../Loading/Loading";

export default function CenteredModal(props) {
  const { content, isLoading, label } = props;
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-primary text-white" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center w-100"
          >
            <div className="">{label}</div>
          </Modal.Title>
        </Modal.Header>
        {isLoading ? <Loading /> : null}
        <Modal.Body>{content}</Modal.Body>
      </Modal>
    </>
  );
}
