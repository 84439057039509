import React, { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import { Form } from "react-bootstrap";
import NotificationProfile from "./NotificationProfile";
import Button from "@material-ui/core/Button";
import { Dialog } from "@material-ui/core";

function NotificationSettings(props) {
  const {
    actions = {},
    apiUrl = "",
    batches = [],
    emailOptOut,
    eventTrigerIdsToRemove,
    handleDelete,
    handleModal,
    onSubmit,
    organization,
    organizationId,
    products = [],
    token = "",
    trackedEvents = [],
    setEmailOptOut,
    setEventTrigerIdsToRemove,
    setSelectedItemIndex,
    setTrackedEvents,
    appUserType,
  } = props;
  const [batchHash, setBatchHash] = useState([]);
  const [productHash, setProductHash] = useState([]);
  const [userCanAdd, setUserCanAdd] = useState(false);
  const [modalOpen, setModalOpen] = useState({
    open: false,
    type: "",
  });
  const trackedEventsLength = trackedEvents.length;
  const lastItemInTrackEvents = trackedEvents[trackedEventsLength - 1] || {
    events: "",
    id: "",
    newAction: "",
  };

  const { events, id, newAction = "" } = lastItemInTrackEvents;

  useEffect(() => {
    const batchObject = {};
    const productsObject = {};

    // Creates a batch hash map
    Object.values(batches).forEach((batch) => {
      const { name, tag } = batch;
      batchObject[tag ? tag : name] = batch;
    });

    // Creates a product hash map
    Object.values(products).forEach((product) => {
      const { name } = product;
      productsObject[name] = product;
    });

    // This checks to see if the last item in the array has an valid values attached to it
    // It makes sure an action is selectd. Also verifies the user has chosen a batch or product
    if ((events.length !== 0 || newAction.length !== 0) && id.length !== 0) {
      setUserCanAdd(true);
    } else if (trackedEventsLength === 0) {
      // In the case that user has no trackedEvents
      setUserCanAdd(true);
    } else {
      setUserCanAdd(false);
    }
    setBatchHash((current) => batchObject);
    setProductHash((current) => productsObject);
  }, [
    batches,
    events.length,
    id.length,
    newAction.length,
    products,
    trackedEventsLength,
  ]);

  const modalContent = (
    <Dialog
      disablePortal
      fullWidth
      onClose={() => setModalOpen({ open: false, type: "" })}
      open={modalOpen.open}
      style={{ position: "absolute" }}
    >
      <div className="bg-primary text-white modal-header">
        <div
          className="text-center w-100 modal-title h4"
          id="contained-modal-title-vcenter"
        >
          Remove Notification
        </div>
        <button
          className="close"
          onClick={() => {
            setModalOpen({ open: false, type: "" });
          }}
          type="button"
        >
          <span aria-hidden="true">x</span>
          <span className="sr-only">Close</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="container positive-relative">
          <div className="row">
            <div className="col-md-12">
              {`Are you sure you want to remove the notification? You will no
              longer recieve notifications on this ${modalOpen.type} .`}
            </div>
            <div className="col-md-12 d-flex">
              <div className="w-50 text-center">
                <Button
                  onClick={() => {
                    handleDelete();
                    setModalOpen({ open: false, type: "" });
                  }}
                  type="button"
                >
                  Yes
                  <i className="far fa-check-circle pl-2 text-success" />
                </Button>
              </div>
              <div className="w-50 text-center">
                <Button
                  onClick={() => {
                    setModalOpen({ open: false, type: "" });
                    setSelectedItemIndex("");
                  }}
                  type="button"
                >
                  No
                  <i className="far fa-times-circle pl-2 text-danger" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );

  return (
    <>
      {modalContent}

      <div className="container">
        <div className="col-md-12">
          <Form>
            {trackedEvents.map((element, index) => {
              return (
                <NotificationProfile
                  {...element}
                  actions={actions}
                  apiUrl={apiUrl}
                  batches={batches}
                  batchHash={batchHash}
                  eventTrigerIdsToRemove={eventTrigerIdsToRemove}
                  handleModal={handleModal}
                  index={index}
                  key={index}
                  organization={organization}
                  organizationId={organizationId}
                  productHash={productHash}
                  products={products}
                  setEventTrigerIdsToRemove={setEventTrigerIdsToRemove}
                  setModalOpen={setModalOpen}
                  setSelectedItemIndex={setSelectedItemIndex}
                  setTrackedEvents={setTrackedEvents}
                  token={token}
                  trackedEvents={trackedEvents}
                />
              );
            })}

            {/* Email Notifications Toggle */}
            <div className="w-100 mt-3">
              <div>
                <span> Enable Email Notifications </span>
                <Switch
                  checked={!emailOptOut}
                  color="primary"
                  inputProps={{ "aria-label": "Enable email notifications" }}
                  name="checkedA"
                  onChange={() => {
                    setEmailOptOut((prevState) => !prevState);
                  }}
                />
              </div>
            </div>

            {/* Add New button */}
            {appUserType === "Asset" || appUserType === "Asset-Operations" || appUserType === "Warehouse-Operations" ? (
              <div className="w-100 mb-3">
                <button
                  className="btn btn-block btn-primary btn-block"
                  disabled={!userCanAdd}
                  onClick={() => {
                    const newArray = [...trackedEvents];
                    const type = "asset category";
                    newArray.push({
                      events: [],
                      id: "",
                      newAction: "",
                      type,
                      isNew: true,
                    });
                    setUserCanAdd(false);
                    setTrackedEvents(newArray);
                  }}
                  type="button"
                >
                  Set Notification by Asset Category or Asset Tag
                </button>
              </div>
            ) : appUserType === "Product" ? (
              <div className="w-100 mb-3">
                <button
                  className="btn btn-block btn-primary btn-block"
                  disabled={!userCanAdd}
                  onClick={() => {
                    const newArray = [...trackedEvents];
                    const type = "product";
                    newArray.push({
                      events: [],
                      id: "",
                      newAction: "",
                      type,
                      isNew: true,
                    });
                    setUserCanAdd(false);
                    setTrackedEvents(newArray);
                  }}
                  type="button"
                >
                  Set Notification by Product or Batch ID
                </button>
              </div>
            ) : appUserType === "Asset/Product" ? (
              <>
                <div className="w-100 mb-3">
                  <button
                    className="btn btn-block btn-primary btn-block"
                    disabled={!userCanAdd}
                    onClick={() => {
                      const newArray = [...trackedEvents];
                      const type = "asset category";
                      newArray.push({
                        events: [],
                        id: "",
                        newAction: "",
                        type,
                        isNew: true,
                      });
                      setUserCanAdd(false);
                      setTrackedEvents(newArray);
                    }}
                    type="button"
                  >
                    Set Notification by Asset Category or Asset Tag
                  </button>
                </div>
                <div className="w-100 mb-3">
                  <button
                    className="btn btn-block btn-primary btn-block"
                    disabled={!userCanAdd}
                    onClick={() => {
                      const newArray = [...trackedEvents];
                      const type = "product";
                      newArray.push({
                        events: [],
                        id: "",
                        newAction: "",
                        type,
                        isNew: true,
                      });
                      setUserCanAdd(false);
                      setTrackedEvents(newArray);
                    }}
                    type="button"
                  >
                    Set Notification by Product or Batch ID
                  </button>
                </div>
              </>
            ) : null}

            {/* Submit Button */}
            <div className="w-100 my-3">
              <button
                className="btn btn-block btn-primary btn-block"
                onClick={() => {
                  onSubmit();
                }}
                type="button"
              >
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default NotificationSettings;
