import { useMemo } from "react";

import EnhancedTable from "./components/EnhancedTable";
import { SimpleTableInterface } from "./interface";
const SimpleTable = (props: SimpleTableInterface) => {
  const columns = useMemo(() => props.columns, [props.columns]);
  const data = useMemo(() => props.data, [props.data]);

  return <EnhancedTable columns={columns} data={data} />;
};

export default SimpleTable;
