import { useState } from "react";
import { useHistory } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { makeStyles } from "@material-ui/core/styles";
import { searchAssets } from "./api";

import { useSelector } from "react-redux";
import { userSelectors } from "../../../../globalStore/slices/user/userSlice";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    width: "33%",
  },
  disabledButton: {
    margin: theme.spacing(1),
    color: "white!important",
    opacity: "50%",
    width: "33%",
  },
  reactSelect: {
    marginLeft: "auto",
    marginRight: "16px",
    width: "40%",
    fontFamily: "Lato",
    fontSize: "1rem",
  },
}));

interface AssetSelectState {
  label: string;
  value: string;
  batchId: string;
  assetType: string;
}
function AssetSelect() {
  const classes = useStyles();
  const history = useHistory();

  const organizationId = useSelector(userSelectors.organizationIdSelector);
  const token = useSelector(userSelectors.tokenSelector);
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const [assetSelect, setAssetSelect] = useState<AssetSelectState>({
    label: "",
    value: "",
    batchId: "",
    assetType: "",
  });

  const asyncAssetIdSearchLoadOptions = async (
    inputValue: any = "",
    loadedOptions: any
  ) => {
    // const { setError } = props;
    const results = await searchAssets(
      { apiUrl, token, organizationId },
      inputValue,
      loadedOptions.length > 0 ? loadedOptions.length : 0
    ).then((results) => {
      if (results.error) {
        // return setError({
        //   alertModalContent: results.error.toString(),
        //   alertModalSeverity: "error",
        //   alertModalTitle: "Error",
        // });
      }
      return {
        options:
          results.assets && results.assets.length
            ? results.assets.map((asset: any) => {
                return {
                  label: asset.tag,
                  value: asset.assetId,
                  batchId: asset.batchId,
                  assetType: asset.assetType,
                };
              })
            : [],
        hasMore: results.count > loadedOptions.length,
      };
    });
    return results;
  };

  return (
    <AsyncPaginate
      className={classes.reactSelect}
      // defaultOptions
      debounceTimeout={750}
      isSearchable
      isClearable
      menuPortalTarget={document.querySelector("body")}
      styles={{
        menuPortal: (styles) => ({ ...styles, zIndex: 99999 }),
      }}
      placeholder={<div>Tag Look Up</div>}
      loadOptions={asyncAssetIdSearchLoadOptions}
      onChange={(e: any = {}) => {
        setAssetSelect({
          label: e.label,
          value: e.value,
          batchId: e.batchId,
          assetType: e.assetType,
        });
        if (e) {
          if (e.batchId) {
            history.push(
              `/batches/${e.batchId}/history/${e.assetType ? e.assetType : ""}`
            );
          } else {
            history.push(`/assetSnapshot/${e.value}`);
          }
        }
      }}
      value={assetSelect.value}
    />
  );
}

export default AssetSelect;
