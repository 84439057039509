import React from "react";

import VerticalTabs from "../../../../../components/Tabs/VerticalTabs";

import CentroidRadius from "./AreaVerticalTabs/CentroidRadius";
import Polygon from "./AreaVerticalTabs/Polygon";

function FacilityArea(props: any) {
  return (
    <VerticalTabs
      alignLeft
      panelStyle={{ minWidth: "80%" }}
      containerStyle={{ backgroundColor: "#FAFAFA" }}
    >
      <CentroidRadius
        {...props}
        label={
          <span style={{ textAlign: "left" }}>
            Centroid &<br />
            Radius
          </span>
        }
      />
      <Polygon
        {...props}
        label={<span style={{ textAlign: "left" }}>Polygon</span>}
      />
    </VerticalTabs>
  );
}

export default FacilityArea;
