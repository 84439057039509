import { lazy, Suspense, useEffect, useState } from "react";
import { Button, Tooltip } from "@material-ui/core";
import { deleteZone, retrieveFacility } from "./API/api";
import { isEqual, omit } from "lodash";
import { State } from "./interface";
import { useSelector } from "react-redux";
import ConfirmationModalContent from "../../../components/Modals/ConfirmationModalContent";
import CreateZone from "./CreateZone/CreateZone";
import EditZone from "./EditZone/EditZone";
import Loading from "../../../components/Loading/Loading";
import MaterialConfirmationModal from "../../../components/Modals/MaterialConfirmationModal";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import ZoneDetail from "./ZoneDetail/ZoneDetail";

// Component uses xlxs which is very heavy, which is why we are using lazy/Suspense
const ZonesTable = lazy(() => import("./ZonesTable/ZonesTable"));

export default function Zones(props: any) {
  const { apiUrl, token, permissions, view, setView } = props;

  const zones = useSelector((state: any) => state.organization.zones, isEqual);
  const organization = useSelector(
    (state: any) => state.organization.organization,
    isEqual
  );
  const { zoneCategories = [] } = organization?.propertiesMap || {};

  const { createLocation } = permissions;
  const { facility = {} } = view;
  const [state, setState] = useState<State>({
    facility: facility,
    zones: [],
    selectedZones: {},
  });

  const [dialog, setDialog] = useState<any>({
    dialogTitle: "",
    dialogShow: false,
    selectedZone: {},
  });

  const [confirm, setConfirm] = useState<any>({
    confirmShow: false,
    text: "",
    color: "",
    icon: "",
  });

  const handleDelete = (id: string) => {
    deleteZone({ apiUrl, token }, id)
      .then((res: any) => {
        if (res.success) {
          retrieveFacility({ apiUrl, token }, state.facility.facilityId).then(
            (res) => {
              setState((s) => {
                return {
                  ...s,
                  selectedZones: omit(s.selectedZones, [id]),
                  facility: res.facility,
                  zones: res.facility.zones,
                };
              });
            }
          );
          handleConfirmationModal("Zone Successfully Deleted");
          dialogClose();
        }
      })
      .catch((err: any) => {
        handleConfirmationModal(
          `There was a problem deleting the selected zone: ${err}`,
          true,
          "fas fa-times-circle mr-2",
          3000
        );
      });
  };

  const handleConfirmationModal = (
    confirmationText: string,
    color?: boolean,
    icon?: string,
    delay: number = 750
  ) => {
    setConfirm({
      ...confirm,
      confirmShow: true,
      text: confirmationText,
      color: color,
      icon: icon,
    });
    setTimeout(function () {
      setConfirm({
        ...confirm,
        confirmShow: false,
        text: "",
        color: "",
        icon: "",
      });
    }, delay);
  };

  const dialogClose = () =>
    setDialog({
      ...dialog,
      dialogShow: false,
      dialogTitle: "",
      selectedZone: {},
    });

  const notificationModalClose = () => {
    setConfirm({ ...confirm, confirmShow: false });
  };

  const switchDialog = (dialog: any) => {
    switch (dialog) {
      case "Zone Detail":
        return (
          <ZoneDetail
            handleClose={() => {
              dialogClose();
            }}
            open={true}
            token={token}
            apiUrl={apiUrl}
            selectedZone={selectedZone}
            notificationModal={handleConfirmationModal}
            zonesMap={zones || {}}
            facility={facility}
          />
        );
      case "Create Zone":
        return (
          <CreateZone
            onHide={dialogClose}
            onSuccess={(addZoneResponse: any) =>
              retrieveFacility(
                { apiUrl, token },
                state.facility.facilityId
              ).then((res) => {
                setState((s) => {
                  return {
                    ...s,
                    facility: res.facility,
                    zones: res.facility.zones,
                  };
                });
              })
            }
            zones={state.zones}
            zoneCategories={zoneCategories}
            token={token}
            apiUrl={apiUrl}
            notificationModal={handleConfirmationModal}
            facility={state.facility}
          />
        );
      case "Edit Zone":
        return (
          <EditZone
            onHide={dialogClose}
            onSuccess={(id: string, editResponse: any) =>
              retrieveFacility(
                { apiUrl, token },
                state.facility.facilityId
              ).then((res) => {
                setState((s) => {
                  return {
                    ...s,
                    selectedZones: omit(s.selectedZones, [id]),
                    facility: res.facility,
                    zones: res.facility.zones,
                  };
                });
              })
            }
            zones={state.zones}
            zoneCategories={zoneCategories}
            token={token}
            apiUrl={apiUrl}
            selectedZone={selectedZone}
            notificationModal={handleConfirmationModal}
          />
        );
      case "Delete Zone":
        return (
          <ConfirmationModalContent
            content={`Are you sure you want to delete ${selectedZone.name}? This action cannot be
            undone.`}
            handleSubmit={() => {
              handleDelete(selectedZone.zoneId);
            }}
            handleCancel={() => {
              dialogClose();
            }}
          />
        );
      default:
        return;
    }
  };

  const { dialogTitle, dialogShow, selectedZone } = dialog;

  useEffect(() => {
    retrieveFacility({ apiUrl, token }, view.facility.facilityId).then(
      (res) => {
        setState((s: any) => {
          return {
            ...s,
            facility: res.facility,
            zones: res.facility.zones,
          };
        });
      }
    );
  }, [view, apiUrl, token]);

  return (
    <div className="container-fluid mt-3">
      <ModalDialog
        handleClose={dialogClose}
        open={dialogShow}
        title={dialogTitle}
        content={switchDialog(dialogTitle)}
      />
      <MaterialConfirmationModal
        content={confirm.text}
        closeModal={notificationModalClose}
        modalOpen={confirm.confirmShow}
        severity={confirm.color ? "error" : "success"}
        variant="filled"
      />

      <div className="row mt-4 mb-3">
        <div className="col-12 col-md-6 text-md-left text-center">
          <h3>{state.facility.name} Zones</h3>
        </div>
        <div className="col-12 col-md-6 text-md-right text-center">
          <span
            className="exportIcon"
            style={{ cursor: "pointer" }}
            onClick={() =>
              setView({
                facility: {},
                viewZones: false,
              })
            }
          >
            <i className="fas fa-arrow-left mr-2"> </i>
            Back to Facilities
          </span>

          {createLocation ? (
            <Tooltip title="Create a new zone for the selected facility.">
              <Button
                onClick={() =>
                  setDialog({
                    ...dialog,
                    dialogShow: true,
                    dialogTitle: "Create Zone",
                  })
                }
                variant="contained"
                color="primary"
                className="ml-3 mb-1"
              >
                Create Zone
              </Button>
            </Tooltip>
          ) : null}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Suspense fallback={<Loading />}>
            <ZonesTable
              diaog={dialog}
              handleConfirmationModal={handleConfirmationModal}
              permissions={permissions}
              setDialog={setDialog}
              setState={setState}
              setView={setView}
              state={state}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
